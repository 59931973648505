/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import { InputRegular } from '@copa/design-system-factory.input-regular'
import { Typography } from '@copa/design-system-factory.typography'
import { SelectRegular } from '@copa/design-system-factory.select-regular'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Box, Checkbox } from '@mui/material'
import DocumentUploader from '../DocumentUploader'
import Hyperlink from '../../../../components/Hyperlink'
import VALIDATIONS from '../../../../utils/validations'
import constants from '../../../../utils/constants'
import BpIcon from '../../../../assets/Icons/BpIcon'
import useStyles from './styles'
import { createErrorsMessages } from '../../../../utils/errorsMessages'
import BpCheckedIcon from '../../../../assets/Icons/BpCheckedIcon'
import getRefundReasonOptions from './getRefundReasonOptions'

export default function ReasonsToRefund({
  control,
  formErrors,
  handleDocs,
  optionReason,
  cleanOptionReasonDocs,
  isNonRefundableFare,
}) {
  const { formatMessage } = useIntl()
  const { classes: styles } = useStyles()
  const errorsMessages = createErrorsMessages(formatMessage)
  const [optionReasonText, setOptionReasonText] = useState('')
  const [optionDescriptionText, setOptionDescriptionText] = useState('')

  const {
    VOLUNTARY_CHANGE,
    INVOLUNTARY_CHANGE,
    DEATH,
    IMMIGRATIONISSUES,
    TERMINAL_ILLNESS,
    OTHER,
    NATURAL_DISASTER,
  } = constants.REASON_OPTIONS

  const setReasonTexts = () => {
    switch (optionReason) {
      case VOLUNTARY_CHANGE:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonVoluntaryChange',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionFareConditions',
            })
          )
        )
      case INVOLUNTARY_CHANGE:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonInvoluntaryChange',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionCopaAffectedMyTrip',
            })
          )
        )
      case NATURAL_DISASTER:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonNaturalDisaster',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.description Natural Disaster',
            })
          )
        )
      case DEATH:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonDeath',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionDeath',
            })
          )
        )
      case TERMINAL_ILLNESS:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonTerminalillness',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionTerminalDisease',
            })
          )
        )
      case OTHER:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonOther',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionOther',
            })
          )
        )
      case IMMIGRATIONISSUES:
        return (
          setOptionReasonText(
            formatMessage({
              id: 'additionalInfoPage.refundReasonImmigrationIssues',
            })
          ),
          setOptionDescriptionText(
            formatMessage({
              id: 'additionalInfoPage.descriptionMigratoryIssues',
            })
          )
        )
      default:
        return setOptionReasonText('')
    }
  }

  useEffect(() => {
    setReasonTexts()
    cleanOptionReasonDocs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionReason])

  return (
    <section
      aria-label={formatMessage({
        id: 'additionalInfoPageWCAG.reasonOfTheRefund',
      })}
      className={styles.reasonOfTheRefund}
    >
      <Typography variant="h3">
        {formatMessage({
          id: 'additionalInfoPage.titleRefundReason',
        })}
      </Typography>
      <Typography style={{ marginTop: '16px', marginBottom: '36px' }}>
        {formatMessage({
          id: 'additionalInfoPage.refundSubtitle',
        })}
      </Typography>
      <Controller
        control={control}
        name="reasonToRefund"
        rules={{ required: true }}
        defaultValue=""
        render={({ field: { onChange, onBlur, value } }) => (
          <SelectRegular
            id="reasonToRefund"
            label={formatMessage({
              id: 'additionalInfoPage.inputRefund',
            })}
            placeholder={formatMessage({
              id: 'additionalInfoPage.helperTextRefund',
            })}
            onChange={onChange}
            value={value}
            required
            style={{ marinTop: '36px' }}
            onBlur={onBlur}
            hasErrors={!!formErrors.reasonToRefund}
            helperText={
              formErrors.reasonToRefund
                ? formatMessage({
                    id: 'generalLayoutPage.required',
                  })
                : ''
            }
            data-testid="reasonsToRefundSelectInput"
          >
            {isNonRefundableFare
              ? getRefundReasonOptions(
                  [INVOLUNTARY_CHANGE, DEATH],
                  formatMessage
                )
              : getRefundReasonOptions(
                  [
                    VOLUNTARY_CHANGE,
                    INVOLUNTARY_CHANGE,
                    DEATH,
                    TERMINAL_ILLNESS,
                    IMMIGRATIONISSUES,
                  ],
                  formatMessage
                )}
          </SelectRegular>
        )}
      />
      {optionReason && (
        <>
          <Typography variant="h4" sx={{ marginTop: '36px' }} tabIndex="0">
            {formatMessage({
              id: 'additionalInfoPage.titleSelectedReason',
            })}{' '}
            {optionReasonText}
          </Typography>
          <Typography tabIndex="0">
            {optionDescriptionText}
            {optionReason === VOLUNTARY_CHANGE && (
              <Hyperlink
                id="Link_Fare_Conditions_URL"
                linkUrl={formatMessage({ id: 'createRequestPage.fateInfo' })}
                target="_blank"
                linkText={formatMessage({
                  id: 'additionalInfoPage.descriptionFareConditionsURL',
                })}
              />
            )}
          </Typography>
          {optionReason === DEATH && (
            <DocumentUploader
              label={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsTitle',
              })}
              required
              id="deathDocuments"
              maxFilesCount={15}
              maxFileSize={4000000}
              enabledFormats={['.jpg', '.jpeg', '.pdf', '.png']}
              handleDocumentsChange={(file, actionType) =>
                handleDocs(
                  file,
                  actionType,
                  constants.DOCUMENT_TYPES.DEATH_DOCUMENTS
                )
              }
            />
          )}

          {optionReason === TERMINAL_ILLNESS && (
            <DocumentUploader
              label={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsTitle',
              })}
              required
              id="terminalIllnessDocuments"
              maxFilesCount={5}
              maxFileSize={4000000}
              enabledFormats={['.jpg', '.jpeg', '.pdf', '.png']}
              handleDocumentsChange={(file, actionType) =>
                handleDocs(
                  file,
                  actionType,
                  constants.DOCUMENT_TYPES.TERMINAL_ILLNESS
                )
              }
            />
          )}

          {optionReason === OTHER && (
            <Box sx={{ marginTop: '36px' }}>
              <Controller
                control={control}
                id="otherTextarea"
                name="otherTextarea"
                rules={VALIDATIONS.OPEN_FIELD}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    label={formatMessage({
                      id: 'reasonSectionPage.descriptionLabel',
                    })}
                    placeholder={formatMessage({
                      id: 'reasonSectionPage.descriptionPlaceholder',
                    })}
                    inputProps={{
                      'aria-label': formatMessage({
                        id: 'reasonSectionPageWCAG.textArea',
                      }),
                      maxLength: 250,
                    }}
                    multiline
                    rows={4}
                    required
                    value={value}
                    handleChange={onChange}
                    hasErrors={formErrors.otherTextarea}
                    helperText={
                      formErrors.otherTextarea &&
                      errorsMessages[formErrors.otherTextarea?.type]
                    }
                    onBlur={onBlur}
                  />
                )}
              />
            </Box>
          )}

          <section className={styles.refundFormChecks}>
            <section
              data-cy="Checkbox-legal1"
              className={styles.refundFormChecksItem}
            >
              <Controller
                control={control}
                id="checkbox1"
                name="checkbox1"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    aria-label={formatMessage({
                      id: 'additionalInfoPageWCAG.legalCheckBox1',
                    })}
                    sx={{
                      padding: '0 8px 0 0',
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Typography variant="body2">
                {formatMessage({
                  id: 'additionalInfoPage.checkbox1',
                })}
              </Typography>
            </section>
            <section
              data-cy="Checkbox-legal2"
              className={styles.refundFormChecksItem}
            >
              <Controller
                control={control}
                id="checkbox2"
                name="checkbox2"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    aria-label={formatMessage({
                      id: 'additionalInfoPageWCAG.legalCheckBox2',
                    })}
                    sx={{
                      padding: '0 8px 0 0',
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Typography variant="body2">
                {formatMessage({
                  id: 'additionalInfoPage.checkbox2',
                })}
              </Typography>
            </section>
          </section>
        </>
      )}
    </section>
  )
}

ReasonsToRefund.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  handleDocs: PropTypes.func.isRequired,
  optionReason: PropTypes.string,
  cleanOptionReasonDocs: PropTypes.func.isRequired,
  isNonRefundableFare: PropTypes.bool.isRequired,
}

ReasonsToRefund.defaultProps = {
  optionReason: undefined,
}
