import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { Typography } from '@copa/design-system-factory.typography'
import { Button } from '@copa/design-system-factory.buttons.button'
import styles from './styles'

function RefundOption({ icon, title, description, onCtaClick, ctaText, wcag }) {
  return (
    <Box component="div" sx={styles.container}>
      <Box component="div" sx={styles.icon}>
        {icon}
      </Box>
      <Box component="div" sx={styles.content}>
        <Typography variant="h2" sx={styles.title}>
          {title}
        </Typography>

        <Typography variant="body1" sx={{ marginTop: '24px' }}>
          {description}
        </Typography>
      </Box>
      <Box component="div">
        <Button sx={styles.ctaBtn} aria-label={wcag} onClick={onCtaClick}>
          {ctaText}
        </Button>
      </Box>
    </Box>
  )
}

RefundOption.propTypes = {
  icon: PropTypes.func.isRequired,
  onCtaClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  wcag: PropTypes.string,
}

RefundOption.defaultProps = {
  onCtaClick: () => {},
  wcag: '',
}

export default RefundOption
