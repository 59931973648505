import React from "react"
import PropTypes from "prop-types"
import { theme } from "@copa/design-system-factory.theme"

import useStyles from "./styles"

function Spinner({ width, heigth, color }) {
  const classes = useStyles({ width, heigth, color })
  return <div className={classes.spinner} />
}

Spinner.propTypes = {
  width: PropTypes.number,
  heigth: PropTypes.number,
  color: PropTypes.string,
}

Spinner.defaultProps = {
  width: 20,
  heigth: 20,
  color: theme.palette.grey["200"],
}

export default Spinner
