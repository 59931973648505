const modalStyles = {
  root: {
    "&  > div:nth-of-type(3) > div:nth-of-type(2)": {
      marginRight: {
        sm: "20px",
        xs: "18px",
      },
      padding: "0",
      paddingRight: {
        sm: "36px",
        xs: "18px",
      },
      paddingLeft: {
        xs: "36px",
        sm: "0px",
      },
    },
  },
  modalWrapper: {
    paddingTop: {
      sm: "16px",
      xs: "1px",
    },
    overflowY: "scroll",
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
  },
  header: {
    paddingLeft: { lg: "3px", sm: "0px", xs: "6px" },
    lineHeight: {
      lg: "40px !important",
      md: "32px !important",
    },
    fontSize: {
      lg: "32x !important",
      md: "28px !important",
    },
    color: "primary.dark",
  },
  headerDisclaimer: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: { lg: "2px", xs: "0px" },
    "& p": {
      fontWeight: "500",
      lineHeight: "20px",
      fontSize: "14px",
    },
    marginTop: {
      lg: "26px",
      sm: "42px",
      xs: "60px",
    },
  },
  contentWrapper: {
    marginTop: {
      sm: "48px",
      xs: "43px",
    },
  },
  requesterInfoWrapper: {
    display: "grid",
    rowGap: { lg: "24px", sm: "22px", xs: "16px" },
    columnGap: { lg: "48px", sm: "24px" },
    gridTemplateColumns: {
      lg: "repeat(3, 25%)",
      sm: "repeat(3, 1fr)",
      xs: "1fr",
    },
    gridTemplateRows: "auto",
    "& div:last-child": {
      gridColumn: { sm: "1/4", xs: "1/2" },
    },
  },
  requesterField: {
    width: "100%",
  },
  requesterFieldTitle: {
    fontSize: "12px",
    lineHeight: "16px ",
    fontWeight: { sm: "500", xs: "700" },
  },
  requesterFieldContent: {
    overflowWrap: "anywhere",
  },
  passengersWrapper: {
    marginTop: "40px",
    "& h4:first-child": {
      maxWidth: "200px",
      marginBottom: "24px",
    },
  },
  passengersTable: {
    backgroundColor: "transparent",
    marginTop: "8px",
    marginBottom: "20px",
    // All rows style
    "& .MuiTableRow-root": {
      display: "flex",
    },
    // All cells style
    "& .MuiTableCell-root": {
      flex: 1,
      textAlign: "center",
    },
    // Header style
    "& .MuiTableCell-head": {
      borderBottomColor: "primary.main",
      padding: "16px 0",
    },
    "& .MuiTableCell-head > p": {
      color: "primary.main",
      fontFamily: "SuisseIntl",
      fontWeight: 500,
    },
    // BODY STYLES
    "& .MuiTableCell-body": {
      padding: "32px 0",
    },
    "& .MuiTableRow-root td:first-child": {
      fontWeight: 500,
      borderBottomColor: "grey.600",
    },
  },
}

export default modalStyles
