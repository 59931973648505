import REGEX from "./regex"

export default {
  NAME_LASTNAME: {
    required: true,
    minLength: 2,
    maxLength: 30,
    pattern: REGEX.ONLY_LETTERS_ACCENTS,
  },
  PNR: {
    required: true,
    minLength: 6,
    maxLength: 14,
    validate: (pnrCode) => {
      if (pnrCode.length === 13) {
        return (
          REGEX.E_TICKET.test(pnrCode) || REGEX.VALID_CASE_NUMBER.test(pnrCode)
        )
      }
      if (pnrCode.length === 6) {
        const regExp = REGEX.ALPHANUMERIC
        return regExp.test(pnrCode)
      }
      if (pnrCode.length === 14) {
        const regExp = REGEX.VALID_CASE_NUMBER
        return regExp.test(pnrCode)
      }
      return false
    },
  },
  E_TICKET: {
    required: true,
    minLength: 13,
    maxLength: 13,
    pattern: REGEX.IS_NUMERIC,
  },
  EMAIL: {
    required: true,
    pattern: REGEX.ISVALIDEMAIL,
  },
  PHONE_CODE: {
    required: true,
    minLength: 1,
    maxLength: 4,
    pattern: REGEX.PHONE_CODE,
  },
  PHONE: {
    required: true,
    minLength: 7,
    maxLength: 16,
    pattern: REGEX.ONLY_NUMBERS,
  },
  PHONE_CODE_NOT_REQUIRED: {
    minLength: 1,
    maxLength: 4,
    pattern: REGEX.PHONE_CODE,
  },
  PHONE_NOT_REQUIRED: {
    minLength: 7,
    maxLength: 16,
    pattern: REGEX.ONLY_NUMBERS,
  },
  OPEN_FIELD: {
    required: true,
    maxLength: 250,
    pattern: REGEX.OPEN_FIELD,
  },
  RESERVATION_CODE: {
    maxLength: 6,
    pattern: REGEX.ALPHANUMERIC,
  },
  OTHER_RELATIONSHIP: {
    required: true,
    maxLength: 30,
    minLength: 3,
    pattern: REGEX.ONLY_LETTERS_ACCENTS,
  },
}
