export default {
  'inputErrorMessage.pnrNotFound': 'No logramos encontrar tu reserva',
  'createRequestPage.fateInfo':
    'https://www.copaair.com/es-gs/informacion-al-viajar/familias-tarifarias/',

  //  CREATE REQUEST PAGE
  'createRequestPage.title': '¿Qué deseas reembolsar?',
  'createRequestPage.refundTicket.title': 'Reembolso de boleto',
  'createRequestPage.refundTicket.description':
    'Si tus planes de viaje cambiaron y necesitas solicitar el reembolso de tu boleto, aquí puedes solicitar la cancelación de tu itinerario y obtener el reembolso de tu boleto.',
  'createRequestPage.refundTicket.cta': 'Solicitar un reembolso',
  'createRequestPage.refundPaidServices.title':
    'Reembolso de servicios adicionales',
  'createRequestPage.refundPaidServices.description':
    'Cargo de equipaje, ascensos de clase y los asientos comprados no' +
    ' son reembolsables. Si el servicio no fue honrado por responsabilidad de la aerolínea, puedes solicitar un reembolso.',
  'createRequestPage.refundPaidServices.cta': 'Solicitar un reembolso',
  'createRequestPage.otherOptions.title': 'Otras opciones',
  'createRequestPage.otherOptions.subtitle':
    '¿Te han cobrado dos veces por la misma transacción?',
  'createRequestPage.otherOptions.description':
    'Cargos duplicados o incorrectos por boletos de avión y otros servicios relacionados con tu viaje.',
  'createRequestPage.refundPaidServices.notHonoredURL':
    'https://help.copaair.com/hc/es-419/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=___dc.servicios_adicionales_pagados_no_honrados__&ticket_form_id=360004175854',
  'createRequestPage.otherOptions.doubleChargeURL':
    'https://help.copaair.com/hc/es-419/requests/new?ticket_form_id=360004175854&tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=__dc.cobro_duplicado_o_incorrecto__',

  //  CREATE REQUEST PAGE WCAG
  'createRequestPage.wcag.pageDescription':
    'A través de esta página podrás seleccionar que tipo de reembolso deseas realizar. ',
  'createRequestPage.wcag.refundRequestBtn':
    'Presiona para solicitar un reembolso de tu boleto',
  'createRequestPage.wcag.refundPaidServiceBtn':
    'Presiona para solicitar un reembolso de servicios adicionales. Se abrirá una nueva página para solicitar este reembolso.',
  'createRequestPage.wcag.refundDuplicateService':
    'Presiona para solicitar un reembolso por cargos duplicados o incorrectos por boletos de avión y otros servicios relacionados con tu viaje. Se abrirá una nueva página para solicitar este reembolso.',

  //  CHECK STATUS PAGE WCAG
  'checkStatusPageWCAG.container':
    'Para buscar tu solicitud de reembolso necesitarás tu codigo de reserva, numero de tiquiete o tu numero de caso.' +
    ' Introducelos en los campos a continuación y luego apreta Buscar Solicitud',

  //  CHECK STATUS PAGE
  'checkStatusPage.header': 'Revisar estado de la solicitud',
  'checkStatusPage.title': 'Consultar solicitud de reembolso',
  'checkStatusPage.subtitle': 'Ingresa el número de caso de tu solicitud. ',
  'checkStatusPage.ticketLabel':
    'Número de caso, código de reserva o número de e-ticket',
  'checkStatusPage.ticketPlaceholder': 'RFD-WEB1234567',
  'checkStatusPage.ticketHelperText':
    'El número de caso debe tener 13 o 14 dígitos y empezar por RFD.',
  'checkStatusPage.lastnameLabel': 'Apellido',
  'checkStatusPage.lastnamePlaceholder': 'Ingresa el apellido',
  'checkStatusPage.cta': 'Buscar solicitud',
  'checkStatusPage.footerTitle': '¿Dónde encuentro mi número de caso?',
  'checkStatusPage.footerBullet':
    'Al finalizar tu solicitud de reembolso, te enviamos un correo electrónico; en este correo puedes encontrar tu número de caso, está conformado por 13 o 14 dígitos y comienza por RFD (ej. RFD-WEB1234567).',
  'checkStatusPage.alert':
    'Esta funcionalidad solo está disponible para solicitudes realizadas a partir del 25 de Agosto de 2022.',
  'pageHeader.description':
    'Página de opciones de reembolso y cancelación para los pasajeros de Copa Airlines',
  'errorAgency.title':
    'Para solicitar un reembolso, por favor, contacta a tu agencia de viajes o compañía con la que hiciste esta reserva.',
  'errorAgency.subTitle':
    'Esta reserva fue comprada a través de un intermediario (ej. agencia de viaje, página web de viajes). Para solicitar el reembolso de la misma, debes hacerlo a través de ellos. Si la compañía tercera ya confirmó que no es posible procesar el reembolso a través de ellos, por favor, contácta a nuestro ',
  'errorAgency.link': 'Centro de Reservaciones ',
  'errorAgency.continue': 'para mayor atención.',
  'errorAgency.button': 'Regresar a Copa.com',
  'errorAgency.WCAGbutton': 'Regresar a Copa.com',
  'errorAgency.WCAGContainer':
    'No podemos Procesar tu reembolso, ya que esta reserva fue comprada a través de un intermediario (Ejemplo: Agencia de Viaje, Pagina de Viajes). Debes contacar a la empresa con la que realizaste la compra para solciitar el reembolso.',
  'additionalInfoPage.header': 'Solicitar un reembolso',
  'formOfPayment.title': 'Información Bancaria para realizar reembolso',
  'formOfPayment.bankName': 'Nombre del banco',
  'formOfPayment.placeholderBankName': 'Ingresa nombre del banco',
  'formOfPayment.accountHolder': 'Titular de la cuenta',
  'formOfPayment.placeholderAccountHolder': 'Ingresa titular de la cuenta',
  'formOfPayment.accountType': 'Tipo de cuenta',
  'formOfPayment.placeholderAccountType': 'Selecciona tipo de cuenta',
  'formOfPayment.savingsAccount': 'Ahorro',
  'formOfPayment.checkingAccount': 'Corriente',
  'formOfPayment.accountNumber': 'Número de cuenta',
  'formOfPayment.placeholderAccountNumber': 'Ingresa número de cuenta',
  'formOfPayment.cpfCpnj': 'CPF / CPNJ',
  'formOfPayment.placeholderCpfCpnj': 'Ingresa el nombre del banco',
  'formOfPayment.bankCode': 'Código bancario',
  'formOfPayment.placeholderBankCode': 'Ingresa el código bancario',
  'formOfPayment.agency': 'Agencia',
  'formOfPayment.placeholderAgency': 'Ingresa el número de agencia',
  'formOfPayment.interbankKey': 'Clave Interbancaria',
  'formOfPayment.placeholderInterbankKey': 'Ingresa clave interbancaria',
  'formOfPayment.swiftcode': 'Número de routing (ABA/Swiftcode)',
  'formOfPayment.placeholderSwiftcode': 'Ingresa número de routing',
  'formOfPayment.rfc': 'RFC',
  'formOfPayment.placeholderRfc': 'Ingresa RFC',
  'formOfPayment.cuitCuil': 'CUIT / CUIL',
  'formOfPayment.placeholderCuitCuil': 'Ingresa CUIT/ CUIL',
  'formOfPayment.cbu': 'CBU',
  'formOfPayment.placeholderCbu': 'Ingresa CBU',
  'formOfPayment.bankingCertification': 'Certificación bancaria',
  'formOfPayment.placeholderBankingCertification': 'Ingresa Certificación',
  'formOfPayment.IDNumber': 'Número de Cédula o Identificación',
  'formOfPayment.placeholderIDNumber': 'Ingresa Identificación',
  'formOfPayment.CCI': 'Código interbancario o CCI',
  'formOfPayment.placeholderCCI': 'Ingresa código',
  'formOfPayment.interbankAccountNumber': 'Número de cuenta Interbancaria',
  'formOfPayment.placeholderInterbankAccountNumber': 'Ingresa número de cuenta',
  'formOfPayment.NIT': 'Número de cédula o NIT',
  'formOfPayment.placeholderNIT': 'Número de identificación',
  'formOfPayment.phoneNumber': 'Telefono',
  'formOfPayment.placeholderPhoneCode': 'Código de País',
  'formOfPayment.placeholderPhoneNumber': 'Ingresa número',
  'formOfPayment.address': 'Dirección',
  'formOfPayment.placeholderAddress': 'Ingresa dirección',
  'formOfPayment.RUC': 'Número de cédula o RUC',
  'formOfPayment.placeholderRUC': 'Número de identificación',
  'formOfPayment.transit': 'Número de tránsito',
  'formOfPayment.placeholderTransit': 'Ingresa número de tránsito',
  'formOfPayment.iban': 'IBAN',
  'formOfPayment.placeholderIban': 'Ingresa IBAN',
  'formOfPayment.branch': 'Sucursal',
  'formOfPayment.placeholderBranch': 'Ingresa Sucursal',
  'formOfPayment.passport': 'Número de cédula o pasaporte',
  'formOfPayment.placeholderPassport': 'Número de identificación',
  'refundConfirmation.header': 'Estatus del reembolso',
  'refundConfirmation.heading': 'Codigo de Reserva',
  'refundConfirmation.passengerDisclaimer':
    'Comenzaremos a evaluar tu solicitud dentro de los próximos 21 días. De ser aprobada, es posible que tu solicitud demore hasta dos estados de cuenta en ser procesada.',
  'refundConfirmation.title':
    'Solicitud de reembolso envíada con éxito (Pendiente Aprobación)',
  'refundConfirmation.caseDateHeader': 'Fecha de solicitud:',
  'refundConfirmation.caseDate': '15 de febrero,  2022',
  'refundConfirmation.caseStatus': 'Estatus actual:',
  'refundConfirmation.case': 'Caso: ',
  'refundConfirmation.new': 'Recibido',
  'refundConfirmation.evaluation': 'En revisión',
  'refundConfirmation.escalated': 'Pendiente',
  'refundConfirmation.approved': 'Aprobado',
  'refundConfirmation.closed': 'Cerrado',
  'refundConfirmation.button': 'Solicitar reembolso de otra reserva',
  'refundConfirmation.title2': '¿Qué sigue?',
  'refundConfirmation.subtitle2':
    'Puedes consultar el estado de tu solicitud a través de <cta>copa.com</cta>, en la sección de reembolsos, seleccionando “Consultar estado”.',
  'refundConfirmation.subtitle3':
    'Tu solicitud puede tener alguno de los siguientes estados:',
  'refundConfirmation.paragraph1':
    '<b>"Recibido"</b>: Estado inicial asignado al crear la solicitud en el formulario.',
  'refundConfirmation.paragraph2':
    '<b>"En revisión"</b>: La solicitud está siendo revisada por nuestro equipo de reembolsos.',

  'refundConfirmation.paragraph3':
    '<b>"Pendiente"</b>: La solicitud necesita información adicional del pasajero/solicitante para ser validada por nuestro equipo de reembolsos.',
  'refundConfirmation.paragraph4':
    '<b>"Cerrado"</b>: Hemos dado respuesta a la solicitud por medio de un correo electrónico.',
  'refundConfirmation.buttonURL':
    'https://www.copaair.com/es-pa/atencion-al-cliente/cambios-y-reembolsos/',
  'refundStatus.title': 'Estatus de la solicitud',
  'refundStatus.description':
    'Esta reserva ya tiene uno o más solicitudes de reembolso abiertas. Revisa el estatus de las solicitudes en esta página.',
  'generalLayoutPage.title1': 'No logramos encontrar tu reserva',
  'generalLayoutPage.bullet1':
    'Asegúrate de estar utilizando un código de reserva de Copa Airlines,' +
    ' que es un código alfanumérico de 6 dígitos (por ejemplo, ABC123).',
  'generalLayoutPage.bullet2':
    'Recomendamos ubicar tu reserva utilizando el número de tiquete de alguno de los pasajeros.' +
    ' (por ejemplo, 2301234567890).',
  'generalLayoutPage.bullet3':
    'Si compraste tu reserva a través de una agencia de viajes, comunícate con ellos para solicitar un reembolso.',
  'generalLayoutPage.bullet4':
    'Si tu reserva tiene mas de 1 año, debes seleccionar la opción "Abrir un caso con la información de los pasajeros" al final de la página.',
  'generalLayoutPage.subtitle1': 'Ingresa la información de tu reserva',
  'generalLayoutPage.field1': 'Código de reserva o número de e-ticket',
  'generalLayoutPage.helperText1': 'Ingresa código de reserva',
  'generalLayoutPage.field2': 'Apellido',
  'generalLayoutPage.helperText2': 'Ingresa el apellido',
  'generalLayoutPage.errorAlertMessage': 'No conseguimos esta reserva',
  'generalLayoutPage.button': 'Buscar Reserva',
  'generalLayoutPage.title2': '¿Qué más puedo hacer?',
  'generalLayoutPage.subtitle2':
    'Abrir un caso con la información de los pasajeros',
  'generalLayoutPage.passengerDisclaimer':
    'En algunos casos, es posible que la información de tu reserva ya no sea accesible para el sistema. En estos casos, deberás escalar un caso a través de esta opción.',
  'generalLayoutPage.required': 'Este campo es requerido',
  'generalLayoutPage.Invalid': 'Formato inválido',
  'additionalInfoPage.heading': 'Código de reserva',
  'additionalInfoPage.headingNonRevenue':
    'Código de Reserva - Boleto de Colaborador',
  'additionalInfoPage.pageSection': 'Información adicional necesaria',
  'additionalInfoPage.titlePassengerSection':
    'El reembolso se aplicará a todos los pasajeros a continuación:',
  'additionalInfoPage.disclaimerPassenger':
    'Si deseas solicitar el reembolso solo para ciertos pasajeros, deberás contactarnos al Centro de Reservaciones para separarlos de la reserva y poder regresar a realizar la solicitud.',
  'additionalInfoPage.titleRequesterSection': 'Información del solicitante',
  'additionalInfoPage.descriptionText':
    'Necesitamos tu información de contacto para proceder con la solicitud de reembolso. Únicamente utilizaremos esta información para contactarte en relación a la misma.',
  'additionalInfoPage.inputName': 'Nombre',
  'additionalInfoPage.passengerDisclaimer1':
    'Si deseas solicitar el reembolso solo para ciertos pasajeros, deberás contactarnos al ',
  'additionalInfoPage.passengerDisclaimerURL': 'Centro de Reservaciones',
  'additionalInfoPage.passengerDisclaimer2':
    ' para separarlos de la reserva y poder regresar a realizar la solicitud.',
  'additionalInfoPage.helperTextName': 'Ingresa nombre',
  'additionalInfoPage.inputLastname': 'Apellido',
  'additionalInfoPage.helperTextLastname': 'Ingresa apellido',
  'additionalInfoPage.inputEmail': 'Correo electrónico',
  'additionalInfoPage.helperTextEmail': 'Ingresa correo electrónico',
  'additionalInfoPage.inputConfirmEmail': 'Confirmar correo electrónico',
  'additionalInfoPage.helperTextConfirmEmail': 'Reingresa correo electrónico',
  'additionalInfoPage.confirmError': 'Los correos no coinciden',
  'additionalInfoPage.phone1': 'Número de teléfono',
  'additionalInfoPage.phone2': 'Número de teléfono',
  'additionalInfoPage.samePhone': 'Ingresa un número de teléfono distinto',
  'additionalInfoPage.inputPhoneCode': 'Código de País',
  'additionalInfoPage.inputPhone': 'Número de teléfono',
  'additionalInfoPage.helperTextPhone': 'Ingresa número',
  'additionalInfoPage.titleFOPSection': 'Método de pago original',
  'additionalInfoPage.FOPDescription':
    'Esta reserva fue comprada con la siguiente tarjeta de crédito. De ser aprobada la solicitud, utilizaremos la misma para hacer el reembolso.',
  'additionalInfoPage.connectMilesDescription':
    'Si la solicitud es aprobada, realizaremos el reembolso al método de pago original y acreditaremos nuevamente las millas en la cuenta ConnectMiles.',
  'additionalInfoPage.FOPNumber':
    'Esta reserva fue comprada con una tarjeta de crédito. De ser aprobada la solicitud, utilizaremos la misma para hacer el reembolso.',
  'additionalInfoPage.creditCardTitle': 'Tarjeta de crédito',
  'additionalInfoPage.milesTitle': 'Millas',
  'additionalInfoPage.creditCardDisclaimer':
    'En caso que esta tarjeta haya expirado, al momento de realizar el reembolso, te contactaremos para actualizar los datos.',
  'additionalInfoPage.titleRefundReason': 'Motivo del reembolso',
  'additionalInfoPage.refundSubtitle':
    'Selecciona el motivo de tu solicitud de reembolso. Dependiendo del motivo, es posible que necesitemos que proveeas alguna documentación adicional. Selecciona a continuación la razón que mejor aplique a tu caso y sigue los pasos.',
  'additionalInfoPage.inputRefund': 'Razón de la solicitud',
  'additionalInfoPage.helperTextRefund': 'Seleccióna el motivo de tu solicitud',
  'additionalInfoPage.refundReasonVoluntaryChange':
    'Quiero cancelar de acuerdo a condiciones de la tarifa',
  'additionalInfoPage.refundReasonInvoluntaryChange':
    'Cancelación o cambio de itinerario realizado por Copa Airlines',
  'additionalInfoPage.refundReasonNaturalDisaster': 'Desastre natural',
  'additionalInfoPage.refundReasonDeath': 'Defunción',
  'additionalInfoPage.refundReasonTerminalillness':
    'Enfermedad crítica o terminal',
  'additionalInfoPage.refundReasonOther': 'Otro',
  'additionalInfoPage.refundReasonImmigrationIssues': 'Temas migratorios',
  'additionalInfoPage.titleSelectedReason': 'Razón: ',
  'additionalInfoPage.descriptionFareConditions':
    'Tu solicitud sera procesada según las condiciones de la familia tarifaria comprada. ',
  'additionalInfoPage.descriptionFareConditionsURL':
    'Conoce más acerca de nuestras familias tarifarias.',
  'additionalInfoPage.descriptionDeath':
    'Para estos casos es necesario adjuntar copia del certificado o acta de defunción de los pasajeros. Puedes adjuntar los documentos necesarios aquí abajo.',
  'additionalInfoPage.description Natural Disaster':
    'Aplica en casos donde un desastre natural (ej. tormenta, terremotos, erupción de volcán) afecta significativamente una ciudad o región relacionada a tu itinerario. ',
  'additionalInfoPage.descriptionCopaAffectedMyTrip':
    'Únicamente aplica si alguno de tus vuelos fue modificado por Copa debido a adelanto, retrasos, cancelaciones u operaciones Irregulares. En caso de no ser así tu solicitud será rechazada.',
  'additionalInfoPage.descriptionMigratoryIssues':
    'Aplica si no pudiste realizar el viaje por no cumplir con los requisitos migratorios para el país de destino.',
  'additionalInfoPage.descriptionTerminalDisease':
    'Para estos casos se requiere adjuntar una carta (en papel membretado) de un médico idóneo que confirme que no se recomendó viajar dentro de la vigencia del boleto (un año a partir de la fecha de emisión del boleto) por enfermedad.',
  'additionalInfoPage.descriptionOther':
    'Por favor, explica con mayor detalle la situación.',
  'additionalInfoPage.requesterIdUploadDocumentsTitle':
    'Documento de Identidad',
  'additionalInfoPage.uploadDocumentsTitle': 'Adjuntar documentos',
  'additionalInfoPage.uploadDocumentsFormats':
    'Documentos permitidos: .pdf, .jpg, .png',
  'additionalInfoPage.uploadDocumentsSize': 'Tamaño máximo: 4MB',
  'additionalInfoPage.uploadDocumentsHelper1':
    'Arrastra los archivos aquí o haz clic para cargar',
  'additionalInfoPage.uploadDocumentsHelper2':
    'Suelta aquí para cargar los archivos',
  'additionalInfoPage.uploadDocumentsErrorFormat':
    'Este formato de archivo no es permitido.',
  'additionalInfoPage.uploadDocumentsErrorLimit':
    'No puede agregar más archivos.',
  'additionalInfoPage.uploadDocumentsErrorSize':
    'El tamaño del archivo supera el limite permitido.',
  'additionalInfoPage.uploadDocumentsLoading': 'Cargando',
  'additionalInfoPage.uploadDocumentsUploadError': 'Error al cargar',
  'additionalInfoPage.uploadDocumentsGeneralError':
    'Algunos archivos no pudieron ser cargados. Inténtalo nuevamente.',
  'additionalInfoPage.uploadDocumentsErrorRequired':
    'Debes adjuntar un documento.',
  'additionalInfoPage.checkbox1':
    'Declaro que tengo la potestad de solicitar este reembolso. Entiendo y acepto que la aprobación de mi reembolso está sujeta a las regulaciones de las reglas de la tarifa.',
  'additionalInfoPage.checkbox2':
    'Autorizo a Copa Airlines en forma libre, expresa e inequívoca a recopilar, usar y tratar la información aquí ingresada para procesar mi solicitud de reembolso. También autorizo transferir mi información a empresas afiliadas  y terceros autorizados que resulten necesarios para poder completar el reembolso. Declaro, reconozco y acepto las Políticas de Privacidad de la empresa.',
  'additionalInfoPage.button': 'Continuar',
  'additionalInfoPage.relationship': 'Parentesco con los pasajeros',
  'additionalInfoPage.relationshipPlaceholder': 'Selecciona',
  'additionalInfoPage.relationshipOther': 'Describe parentesco',
  'additionalInfoPage.relationshipOtherPlaceholder': 'Describe parentesco',
  'additionalInfoPage.relationshipOption1':
    'Familiar directo (padres, hijos o conyugue)',
  'additionalInfoPage.relationshipOption2': 'Yo soy uno de los pasajeros',
  'additionalInfoPage.relationshipOption3': 'Otro',
  'warningModalLayout.header':
    'Al continuar con la solicitud, tu itinerario será cancelado. ¿Deseas continuar?',
  'warningModalLayout.passengerDisclaimer':
    'Al confirmar tu solicitud de reembolso, todos los vuelos de tu reserva serán cancelados inmediatamente para todos los pasajeros. Si cambias de opinión y deseas reactivar tu reserva, no podremos garantizar la disponibilidad de tus vuelos actuales. La reactivación dependerá de la disponibilidad en ese momento.',
  'warningModalLayout.passengerDisclaimerNonRevenue':
    'Tienes un boleto de colaborador. Si cancelas tus vuelos, se emitirá el reembolso a Copa Airlines y todos tus vuelos serán cancelados.',
  'warningModalLayout.title': 'Solicitante del reembolso',
  'warningModalLayout.fullName': 'Nombre completo',
  'warningModalLayout.email': 'Correo Electrónico',
  'warningModalLayout.phone': 'Telefóno',
  'warningModalLayout.reason': 'Motivo de la solicitud',
  'warningModalLayout.passengersTitle': 'Pasajeros aplicando al reembolso',
  'warningModalLayout.passengeName': 'Nombre',
  'warningModalLayout.dateOfBirth': 'Fecha de nacimiento',
  'warningModalLayout.eTicket': 'Número de Tiquete',
  'warningModalLayout.button1': 'Atrás',
  'warningModalLayout.button2': 'Solicitar reembolso',

  // ADD PASSENGERS PAGE
  'addPassengersPage.title': 'Solicitar un reembolso',
  'addPassengersPage.subtitle': 'Agregar pasajeros',
  'addPassengersPage.descriptionText':
    'Por favor, ingresa la información de todos los pasajeros de la reserva para solicitar el reembolso. La solicitud se realizará para todos los pasajeros de la reserva.',
  'addPassengersPage.paxTitle': 'Pasajero',
  'addPassengersPage.inputReservation': 'Código de reserva',
  'addPassengersPage.placeholderReservation': 'Ingresa código de reserva',
  'addPassengersPage.inputName': 'Nombre',
  'addPassengersPage.placeholderName': 'Ingresa el nombre',
  'addPassengersPage.inputLastname': 'Apellido',
  'addPassengersPage.placeholderLastname': 'Ingresa el apellido',
  'addPassengersPage.addPaxButton': 'Agregar pasajero ',
  'addPassengersPage.inputTicketCode': 'Número de e-ticket',
  'addPassengersPage.placeholderTicketCode': 'Ingresa número de e-ticket',
  'addPassengersPage.helperTicketCode':
    'El número de e-ticket comienza con 230.',
  'addPassengersPage.errorTicketCode':
    'Formato inválido. El tiquete debe estar conformado por 13 números y comenzar por 230',
  'addPassengersPage.cta': 'Continuar',
  'addPassengersPage.remove': 'Remover',
  'addPassengersPage.alertError':
    'Ya existe una solicitud para este número de tiquete. Este es tu número de caso {caseNumber}, puedes revisar el estatus ',
  'addPassengersPage.alertErrorLink': 'aqui.',
  'addPassengersPage.caseAlreadyOpenLabelError':
    'Ya existe una solicitud para este número de tiquete. ',
  'addPassengersPage.emdErrorScenario':
    'Formato inválido. Utiliza el número de e-ticket de tu compra.',

  // CALL CENTER SPECIAL LINK
  'callCenterSpecialLink.title': 'Buscar Reserva',
  'callCenterSpecialLink.description':
    'Ingresa el código de reserva o número de e-ticket y el apellido para realizar la solicitud de reembolso.',
  'callCenterSpecialLink.subtitle': 'Ingresa la información de tu reserva',
  'callCenterSpecialLink.requiredMessage': 'Este campo es requerido',
  'callCenterSpecialLink.invalidFormatMessage': 'Formato inválido',
  'callCenterSpecialLink.button': 'Buscar Reserva',
  'callCenterSpecialLink.moreOptionTitle': '¿Qué más puedo hacer?',
  'callCenterSpecialLink.moreOptionSubtitle':
    'Abrir un caso con la información de los pasajeros',
  'callCenterSpecialLink.moreOptionBody':
    'En algunos casos, es posible que la información de tu reserva ya no sea accesible para el sistema. En estos casos, deberás escalar un caso a través de esta opción. ',

  // EXCEPTIONS PAGE
  'exceptionsPage.title':
    'Lo sentimos, tu reserva no cumple con los requisitos para poder abrir una solicitud de reembolso',
  'exceptionsPage.subtitle': 'Estas son las posibles razones: ',
  'exceptionsPage.bullet1': 'Tu reserva está pendiente de pago.',
  'exceptionsPage.bullet2': 'Tu reserva es de grupo.',
  'exceptionsPage.bullet3':
    'Tu reserva fue comprada con millas, está asociada a un miembro ConnectMiles o PreferMember Silver y está sujeta al cobro de una penalidad.',
  'exceptionsPage.bullet4':
    'Tu itinerario tiene vuelos operados por otra aerolínea.',
  'exceptionsPage.bullet5': 'Tu reserva ha sido cancelada. ',
  'exceptionsPage.bullet6': 'Tu reserva ha sido reembolsada.',
  'exceptionsPage.bullet7': 'Esta reserva ya fue volada',
  'exceptionsPage.bullet8':
    'Tu reserva fue cancelada por reclamo realizado por el banco.',
  'exceptionsPage.cta': 'Regresar a copa.com',

  //  REASON SECTION PAGE
  'reasonSectionPage.title': 'Motivo del reembolso',
  'reasonSectionPage.subtitle':
    'Selecciona el motivo de tu solicitud de reembolso. Dependiendo del motivo, es posible que necesitemos que proveeas ' +
    'alguna documentación adicional. Selecciona a continuación la razón que mejor aplique a tu caso y sigue los pasos.',
  'reasonSectionPage.field1': 'Razón de la solicitud',
  'reasonSectionPage.placeholder':
    'Selecciona el motivo de tu solicitud de reembolso',
  'reasonSectionPage.invalidFormatError': 'Formato inválido',
  'reasonSectionPage.isRequiredError': 'Este campo es requerido',
  'reasonSectionPage.refundReasonOption1':
    'Deseo cancelar de acuerdo a las condiciones de mi boleto',
  'reasonSectionPage.refundReasonOption2':
    'Copa Airlines realizó un cambio involuntario en el itinerario de mi vuelo.',
  'reasonSectionPage.refundReasonOption3': 'Desastre natural',
  'reasonSectionPage.refundReasonOption4': 'Defunción',
  'reasonSectionPage.refundReasonOption5': 'Otro',
  'reasonSectionPage.titleSelectedReason': 'Razón: ',
  'reasonSectionPage.deathDescription':
    'Para estos casos, es necesario adjuntar el acta de defunción de uno o más ' +
    'pasajeros, emitida por la autoridad relevante del país. Puedes adjuntar los' +
    ' documentos necesarios aquí abajo.',
  'reasonSectionPage.naturalDisasterDescription':
    'Aplica en casos donde un desastre natural (ej. tormenta, terremotos,' +
    ' erupción de volcán) afecta significativamente una ciudad o región relacionada a tu itinerario. ',
  'reasonSectionPage.affectedFlyOptionDescription':
    'Únicamente aplica si alguno de tus vuelos fue modificado por Copa ' +
    'debido a retrasos, cancelaciones, operaciones Irregulares. ' +
    'En caso de no ser así tu solicitud será rechazada.',
  'reasonSectionPage.otherBoxDescription':
    'Por favor, explica con mayor detalle la situación.',
  'reasonSectionPage.descriptionLabel': 'Explicanos la situación',
  'reasonSectionPage.descriptionPlaceholder': 'Ingresar información',
  'reasonSectionPage.checkbox1':
    'Declaro que tengo la potestad de solicitar este reembolso. Entiendo y acepto que la aprobación de mi reembolso está sujeta a las regulaciones de las reglas de la tarifa.',
  'reasonSectionPage.checkbox2':
    'Autorizo a Copa Airlines en forma libre, expresa e inequívoca a recopilar, usar y tratar la información aquí ingresada para procesar mi solicitud de reembolso. También autorizo transferir mi información a empresas afiliadas  y terceros autorizados que resulten necesarios para poder completar el reembolso. Declaro, reconozco y acepto las Políticas de Privacidad de la empresa.',
  'reasonSectionPage.button': 'Continuar',

  // INTERSITIAL
  'intersitial.loading': 'Cargando',
  'intersitial.alertError':
    'Lo sentimos! No pudimos encontrar tu solicitud. Por favor, intenta de nuevo.',
  'intersitialWCAG.alertError':
    'No podemos encontrar tu caso con la informacion proporcionada. Por favor intenta de nuevo cambiando los datos.',

  // ERROR PAGE
  'errorPage.header': 'Solicitar un reembolso',
  'errorPage.alert': '¡Lo sentimos!',
  'errorPage.title': '¡Lo sentimos! Ha ocurrido un error.',
  'errorPage.subtitle':
    'Debido a dificultades técnicas no hemos podido continuar con la creación de tu solicitud de reembolso. Por favor, comienza de nuevo.',
  'errorPage.cta': 'Reintentar',

  // PAYMENT METHOD
  'paymentMethod.countryPurchase': 'País de compra',
  'paymentMethod.countryPurchasePlaceholder': 'Seleccionar país',
  'paymentMethod.PaymentMethod': 'Método de pago',
  'paymentMethod.PaymentMethodPlaceholder': 'Seleccionar método de pago',
  'paymentMethod.TransferCash': 'Transferencia/Efectivo',
  'paymentMethod.CreditCard': 'Tarjeta de crédito',
  'paymentMethod.Other': 'Otro',

  // COUNTRIES
  'countries.BR': 'Brasil',
  'countries.MX': 'México',
  'countries.AR': 'Argentina',
  'countries.CO': 'Colombia',
  'countries.CA': 'Canadá',
  'countries.CL': 'Chile',
  'countries.PA': 'Panamá',
  'countries.EU': 'Europa ',
  'countries.CU': 'Cuba',
  'countries.PE': 'Perú',
  'countries.NI': 'Nicaragua',
  'countries.EC': 'Ecuador',
  'countries.HT': 'Haití',
  'countries.GY': 'Guyana',
  'countries.BB': 'Barbados',
  'countries.TT': 'Trinidad y Tobago',
  'countries.OTHER': 'Otro',

  // 404 PAGE NOT FOUND

  'pageNotFound.button': 'Regresar a copa.com',
  'pageNotFound.Title': 'No encontramos esta página',
  'pageNotFound.Subtitle':
    'Lo sentimos, la página que intentaste visitar no existe en nuestro sitio web.',
  'pageNotFound.CTA': 'Ir al inicio',

  // 404 PAGE NOT FOUND WCAG

  'pageNotFoundWCAG.container':
    'Lo sentimos. La página a la que intentaste ingresar no existe. Regresa a copa.com para intentar de nuevo.',

  //  REASON SECTION PAGE WCAG
  'reasonSectionPageWCAG.refundSelect':
    'Desplegable, use las teclas de flecha hacia arriba y hacia abajo para ' +
    'seleccionar el motivo de la solicitud de reembolso.',
  'reasonSectionPageWCAG.checkbox1':
    'Presione para indicar que declarar que tiene la potestad de solicitar este' +
    ' reembolso. Que entiende y acepta que la aprobación del reembolso está sujeta a las reglas de su tarifa y ' +
    'la politica de reembolso de copa airlines.',
  'reasonSectionPageWCAG.checkbox2':
    '' +
    'Presione para indicar que esta anuente que la información ingresada en este formulario sólo sera utilizada ' +
    'para fines de la solciitud de reembolsos.',
  'reasonSectionPageWCAG.buttonCta':
    'Botón. Presione ENTER para continuar con la solicitud de reembolso.',
  'reasonSectionPageWCAG.textArea':
    'Usa esta caja de texto para describirnos las razones por las cuales deseas realizar' +
    ' el reembolso. Podrás escribir hasta un límite de 250 caracteres.',

  //  EXCEPTIONS PAGE WCAG
  'exceptionsPageWCAG.container':
    'No podemos Procesar tu reembolso, ya que esta reserva fue comprada a través de un' +
    ' intermediario (Ejemplo: Agencia de Viaje, Pagina de Viajes). ' +
    'Debes contacar a la empresa con la que realizaste la compra para solciitar el reembolso.',

  // ADD PASSENGERS PAGE WCAG
  'addPassengersPageWCAG.generalDescription':
    'Página para llenar la información de los pasajeros que van a solicitar el reembolso. El reembolso se va a procesar para todos los pasajeros que hay en la reserva. Esta solciitud solo es válida para reservas de máximo 9 pasajeros.',
  'addPassengersPageWCAG.inputName':
    'Escriba el nombre del pasajero número [X], solo se permiten letras y espacios en blanco.',
  'addPassengersPageWCAG.reservationCode':
    'Puedes agregar el código de reserva a reembolsar. Este código de reserva tiene 6 caracteres.',
  'addPassengersPageWCAG.inputLastname':
    'Escriba el apellido del pasajero número [X], solo se permiten letras y espacios en blanco.',
  'addPassengersPageWCAG.inputETicket':
    'Escriba el boleto electrónico del pasajero número [X], solo se permiten 13 números y debe comenzar por 230.',
  'addPassengersPageWCAG.addPaxButton':
    'Botón de agregar más pasajeros. Presiona enter para agregar otro pasajero a la solicitud.',
  'addPassengersPageWCAG.removePaxButton':
    'Boton para eliminar pasajero. Presiona enter para eliminar pasajero de la solicitud',
  'addPassengersPageWCAG.cta':
    'Buton de continuar. Presion enter para continuar con la solicitud',
  'addPassengersPageWCAG.alertError':
    'Ya existe un numero de caso para este tiquete eléctronico. Tu número de caso es' +
    ' {caseNumber}. Ve a la pagina de revisar status para comprobar el estado de tu solicitud.',

  // WCAG Text
  'additionalInfoPageWCAG.inputName':
    'Escriba su nombre, solo se permiten letras y espacios en blanco',
  'additionalInfoPageWCAG.inputLastname':
    'Escriba su apellido, solo se permiten letras y espacios en blanco.',
  'additionalInfoPageWCAG.inputEmail':
    'Escriba el correo electrónico donde desea recibir la confirmación de la solcitud y donde lo contactaremos en caso de ser necesario.',
  'additionalInfoPageWCAG.inputConfirmEmail':
    'Confirma el correo electrónico donde deseas recibir la confirmación de la solcitud y donde te contactaremos en caso de ser necesario.',
  'additionalInfoPageWCAG.inputPhoneCode1':
    'Desplegable, seleccione la opción del código de su país.',
  'additionalInfoPageWCAG.inputPhone1':
    'Escriba su número de teléfono. Este es un campo numérico.',
  'additionalInfoPageWCAG.inputPhoneCode2':
    'Desplegable, seleccione la opción del código de su país, para un segundo número de teléfono.',
  'additionalInfoPageWCAG.inputPhone2':
    'Escriba un segundo número de teléfono. Este es un campo numérico.',
  'additionalInfoPageWCAG.generalDescription':
    'Página para solicitar reembolso de reserva. Para completar la solicitud, por favor llena la información adicional que requerimos. Algunos campos son obligatorios y otros son opcionales, dependiendo el motivo dle reembolso seleccionado, deberás adjuntar ciertos docuemntos. Por favor asegura que la información sea veraz, ya que una vez se complete la solicitud, no podrás cambiarla. La solicitud de reembolso se hace para todos los pasajeros dentro de la reserva, si solo deseas pedir el reembolso de ciertos pasajeros, deberás llamar primero a nuestro centro de reservaciones para separar lo pasajeros a otra reserva.',
  'additionalInfoPageWCAG.paxContainer':
    'Este es el listado de todos los pasajeros que estan solicitando el reembolso: pasajero número uno [Nombre y Apellido, Fecha de Nacimiento, # de tiquete]; pasajero número dos [Nombre y Apellido, Fecha de Nacimiento, # de tiquete]; pasajero número tres [Nombre y Apellido, Fecha de Nacimiento, # de tiquete], pasajero número cuatro [Nombre y Apellido, Fecha de Nacimiento, # de tiquete], etc. ',
  'additionalInfoPageWCAG.formOfPaymentContainer':
    'El metodo de pago original que se usó para comprar esta reserva fue una tarjeta [tipo] con terminación de [last 4 digits]. Esta será la tarjeta a la cual se realizará el reembolso en caso de ser aceptada la solicitud.',
  'additionalInfoPageWCAG.reasonOfTheRefund':
    'Desplegable, use las teclas de flecha hacia arriba y hacia abajo para seleccionar el motivo de la solicitud de reembolso.',
  'additionalInfoPageWCAG.legalCheckBox1':
    'Presione para indicar que declarar que tiene la potestad de solicitar este reembolso. Que entiende y acepta que la aprobación del reembolso está sujeta a las reglas de su tarifa y la politica de reembolso de copa airlines.',
  'additionalInfoPageWCAG.legalCheckBox2':
    'Presione para indicar que esta anuente que la información ingresada en este formulario sólo sera utilizada para fines de la solciitud de reembolsos.',
  'additionalInfoPageWCAG.uploadDocuments':
    'Puedes adjuntar tus archivos para sustentar tu reembolso aqui. Podras agregar hasta {numberOfDocs} archivos los cuales no deben pesar mas de 4 MB. Solamente permite adjuntar documentos en formato .pdf; .jpg y .png. Luego de adjuntarlos puedes remover cualquier documento al apretar la X junto a cada uno de ellos.',
  'additionalInfoPageWCAG.uploadDocumentsDelete':
    'Apreta aqui para remover este documento.',
  'additionalInfoPageWCAG.uploadDocumentsReload':
    'Parece que hubo un error con el archivo. Toca aqui para volver a cargarlo.',
  'additionalInfoPageWCAG.uploadDocumentsFormatError':
    'El formato que adjuntaste no es el correcto. Por favor adjunta documentos que sean .pdf; .jpg o .png',
  'additionalInfoPageWCAG.uploadDocumentsLimitError':
    'Solo puedes adjuntar hasta {numberOfDocs} archivos. Por favor, elimina uno de los archivos agregados para adjuntar el nuevo.',
  'additionalInfoPageWCAG.uploadDocumentsSizeError':
    'El archivo que intentaste agregar es muy pesado. Solo se permiten archivos de hasta 4 MB',
  'additionalInfoPageWCAG.uploadDocumentsRequiredError':
    'Debes adjuntar un archivo. Por favor adjunta un archivo para continuar',
  'additionalInfoPageWCAG.uploadDocumentsDocumentError':
    'Algunos documentos que adjuntaste no tienen el formato permitido. Eliminalos usando la X junto al archivo y agrega uno nuevo con el formato .pdf, .jpg o .png',
  'additionalInfoPageWCAG.cta':
    'Botón. Presione ENTER para continuar con la solicitud de reembolso.',
  'additionalInfoPageWCAG.relationship':
    'Si no eres el titular de la reserva. Por favor describa su relación con los pasajeros. Este es un campo de seleccion multiple.',
  'additionalInfoPageWCAG.relationshipOther':
    'Si escogiste la opcion otro, por favor describe tu relacion con el pasajero. Este es un campo de texto.',
  'warningModalWCAG.modal':
    'Sección de confirmación de solicitud de reembolso. Encontrarás más información abajo.',
  'warningModalWCAG.container':
    'Resumen de la información de solicitud de Reembolso. Si confirma la solicitud de reembolso, todo el itinerario será cancelado. Esta solicitud de reembolso fue realizada por [nombre] con motivo [Razón de la solicitud] para los siguiente pasajeros: pasajero número uno [nombre], pasajero número dos [nombre], etc.',
  'warningModalWCAG.ctaBack':
    'Botón de regresar a página de información adicional. Presiona enter para regresar a la página anterior.',
  'warningModalWCAG.ctaConfirm':
    'Botón de solicitar reembolso. Presiona enter para confirmar la solicitud de reembolso y cancelar tu itinerario.',
  // ERROR PAGE WCAG
  'errorPageWCAG.container':
    'Debido a dificultades técnicas no hemos podido continuar con la creación de tu solicitud de reembolso. Por favor, comienza de nuevo.',
  'errorPageWCAG.cta': 'Botón para reintentar',
  // MODAL NPAFOPCASH
  'modalNRAndAFOPCash.title': 'Esta reserva fue pagada en efectivo',
  'modalNRAndAFOPCash.text':
    'Te recomendamos solicitar tu reembolso en una de nuestras oficinas de ventas para que sea gestionado de forma inmediata. A través de este formulario el reembolso puede demorar hasta dos meses en hacerse efectivo.',
  'modalNRAndAFOPCash.secundaryButton': 'Continuar con mi solicitud',
  'modalNRAndAFOPCash.primaryButton': 'Cancelar',
  'modalNRAndAFOPCash.primaryButtonWCAG':
    'Presiona si deseas cancelar y regresar al inicio del flujo',
  'modalNRAndAFOPCash.secundaryButtonWCAG':
    'Presiona para continuar con la solicitud de reembolso',
  // ROUTES
  'links.changesAndRefunds': '/es-gs/atencion-al-cliente/cambios-y-reembolsos/',
  // Modal Non-Refundable Fares
  'modalNonRefundableFares.title': 'Este boleto no es reembolsable',
  'modalNonRefundableFares.descriptionStart':
    'La familia tarifaria que compraste no es reembolsable. Sin embargo, ' +
    'pueden aplicar excepciones en ciertas circunstancias. Aquí están las excepciones:',
  'modalNonRefundableFares.firstBullet': 'Defunción (se requiere certificado)',
  'modalNonRefundableFares.secondBullet':
    'Cancelación o cambios realizados por Copa Airlines que impacten tu itinerario',
  'modalNonRefundableFares.descriptionEnd':
    'Si consideras que tu caso podría aplicar a una de estas excepciones, ' +
    'puedes continuar con el proceso.',
  'modalNonRefundableFares.continueButton': 'Solicitar excepción',
  'modalNonRefundableFares.continueButtonWCAG':
    'Presiona para continuar con excepciones',
  'modalNonRefundableFares.cancelButton': 'Volver al incio',
  'modalNonRefundableFares.cancelButtonWCAG':
    'Presiona si deseas cancelar y regresar al inicio del flujo',
}
