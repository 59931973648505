import React from 'react'

export default function UploadIcon({ ...props }) {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24.6367" cy="24.25" r="24" fill="#EFF5FF" />
      <path
        d="M13.6367 13.75C13.6367 12.9216 14.3663 12.25 15.2663 12.25H34.0071C34.9071 12.25 35.6367 12.9216 35.6367 13.75C35.6367 14.5784 34.9071 15.25 34.0071 15.25H15.2663C14.3663 15.25 13.6367 14.5784 13.6367 13.75Z"
        fill="#006CB3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.635 17.6694C25.0837 17.1102 24.1898 17.1102 23.6385 17.6694L17.0502 24.3512C16.4989 24.9103 16.4989 25.8169 17.0502 26.3761C17.6015 26.9352 18.4954 26.9352 19.0468 26.3761L23.2246 22.1389V36.8384C23.2246 37.6181 23.8567 38.2501 24.6364 38.2501C25.4161 38.2501 26.0481 37.6181 26.0481 36.8384V22.1382L30.2267 26.3761C30.778 26.9352 31.6719 26.9352 32.2232 26.3761C32.7746 25.8169 32.7746 24.9103 32.2232 24.3512L25.635 17.6694Z"
        fill="#006CB3"
      />
    </svg>
  )
}
