const useStyles = {
  button: {
    padding: "12px, 24px, 12px, 24px",
    borderRadius: "8px",
    color: "#0032A0",
    background: "white",
    marginY: "56px",
    gap: "4px",
  },
}

export default useStyles
