import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: "3rem !important",
    lineHeight: "56px !important",
    color: theme.palette.primary.main,
    marginTop: "24px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "40px !important",
      lineHeight: "48px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "36px !important",
      lineHeight: "40px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px !important",
      lineHeight: "32px !important",
    },
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[600],
    fontFamily: "SuisseIntl",
    paddingTop: "24px",
    margin: "0 auto",
  },
  container: {
    height: "90vh",
    margin: "auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  ctaContainer: {
    marginTop: "26px !important",
    width: "100%",
    "& button": {
      padding: "16px, 24px, 16px, 24px",
      borderRadius: "56px",
      width: "166px",
      height: "56px",
    },
  },
}))

export default useStyles
