import React from 'react'

export default function ArrowLeft({ ...prop }) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...prop}
    >
      <path
        d="M5.67568 12L0 6L5.67568 0L7 1.4L2.64865 6L7 10.6L5.67568 12Z"
        fill="#AAAAAB"
      />
    </svg>
  )
}
