import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
  paymentMethodContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "80px",
  },
  paymentMethodDisclaimer: {
    display: "flex",
    alignItems: "flex-start",
  },
  idDocumentsUploader: {
    marginTop: "-4px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px",
    },
  },
  refundReasonContainer: {
    marginTop: "74px",
  },
  refundFormChecks: {
    marginTop: "48px",
    [theme.breakpoints.down("md")]: {
      marginTop: "64px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  refundFormChecksItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "26px",
  },
  formSectionContainer: {
    marginTop: "64px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "56px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "72px",
    },
  },
  AFOPFormContainer: {
    marginBottom: "56px",
    marginTop: "64px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "40px",
      marginTop: "56px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "56px",
      marginTop: "72px",
    },
  },
}))

export default useStyles
