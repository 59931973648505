import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Modal } from '@copa/design-system-factory.modal'
import { Typography } from '@copa/design-system-factory.typography'
import { useNavigate } from 'react-router-dom'
import Padlock from '../../../../../assets/Icons/svg/Padlock.svg'
import modalStyles from './styles'
import ModalHeaderIcon from '../ModalHeaderIcon'
import onCancelModal from '../onCancelModal'

function NonRefundableFaresModal({
  showModal,
  setShowModal,
  isAgency,
  setIsNonRefundableFare,
}) {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const handleIsNonRefundableFare = () => {
    setShowModal(false)
    setIsNonRefundableFare(true)
  }

  return (
    <Modal
      open={showModal}
      handleClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleIsNonRefundableFare()
        }
      }}
      disableEscapeKeyDown
      aria-label={formatMessage({
        id: 'warningModalWCAG.modal',
      })}
      secondaryButtonProps={{
        text: formatMessage({
          id: 'modalNonRefundableFares.continueButton',
        }),
        id: 'Button_Modal_Back',
        'aria-label':
          formatMessage({
            id: 'modalNonRefundableFares.continueButtonWCAG',
          }) || '',

        onClick: () => {
          handleIsNonRefundableFare()
        },
        variant: 'outlinePrimaryLight',
        size: 'large',
      }}
      primaryButtonProps={{
        text: formatMessage({
          id: 'modalNonRefundableFares.cancelButton',
        }),
        id: 'Modal_Button_Submit',
        disable: false,
        'aria-label': formatMessage({
          id: 'modalNonRefundableFares.cancelButtonWCAG',
        }),
        onClick: () =>
          onCancelModal({
            isAgency,
            navigate,
            formatMessage,
          }),
        variant: 'solidPrimaryMain',
        size: 'large',
      }}
      dismissButtonProps={{
        'aria-label': 'Close',
        'data-testid': 'Close-button-modal',
      }}
    >
      <Box sx={modalStyles}>
        <ModalHeaderIcon icon={Padlock} />
        <Box as="section">
          <Typography variant="h2">
            {formatMessage({ id: 'modalNonRefundableFares.title' })}
          </Typography>
          <Typography variant="body1">
            {formatMessage({ id: 'modalNonRefundableFares.descriptionStart' })}
          </Typography>

          <ul>
            <li>
              <Typography
                variant="body1"
                className="first-bullet"
                data-testid="Death-test-id"
              >
                {formatMessage({
                  id: 'modalNonRefundableFares.firstBullet',
                })}
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                data-testid="InvoluntaryChange-test-id"
              >
                {formatMessage({
                  id: 'modalNonRefundableFares.secondBullet',
                })}
              </Typography>
            </li>
          </ul>

          <Typography variant="body1" className="description-end">
            {formatMessage({ id: 'modalNonRefundableFares.descriptionEnd' })}
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

NonRefundableFaresModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setIsNonRefundableFare: PropTypes.func.isRequired,
  isAgency: PropTypes.bool.isRequired,
}

export default NonRefundableFaresModal
