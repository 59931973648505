import React from 'react'
import { InlineAlert } from '@copa/design-system-factory.inline-alert'
import { Typography } from '@copa/design-system-factory.typography'
import { Box, useMediaQuery } from '@mui/material'
import { useIntl } from 'react-intl'
import Header from '../../components/Header'
import styles from './styles'
import CheckStatusForm from './CheckStatusForm'
import variants from '../../theme/variants'
import alertIcon from '../../assets/Icons/svg/AlerIcon.svg'

function CheckStatusPage() {
  const { formatMessage } = useIntl()
  const isSemiMedium = useMediaQuery('(min-width:960px) and (max-width:1279px)')

  return (
    <>
      <Header title={formatMessage({ id: 'checkStatusPage.header' })} />
      <Box
        sx={{
          ...styles.container,
          padding: {
            ...styles.container.padding,
            md: isSemiMedium ? '0px 142px' : '0px 169px',
          },
        }}
      >
        <Typography id="exceptions-title" sx={styles.title} variant="h2">
          {formatMessage({ id: 'checkStatusPage.title' })}
        </Typography>

        <Typography id="exceptions-sutitle" sx={styles.subtitle}>
          {formatMessage({ id: 'checkStatusPage.subtitle' })}
        </Typography>

        <Box sx={styles.alert}>
          <InlineAlert
            message={formatMessage({
              id: 'checkStatusPage.alert',
            })}
            icon={alertIcon}
          />
        </Box>

        <CheckStatusForm />

        <Box sx={styles.footer}>
          <Typography sx={styles.footerTitle}>
            {formatMessage({ id: 'checkStatusPage.footerTitle' })}
          </Typography>
          <ul style={{ paddingLeft: '24px', margin: '0px', marginTop: '26px' }}>
            <li>
              <Typography sx={variants.bodyLarge}>
                {formatMessage({ id: 'checkStatusPage.footerBullet' })}
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  )
}

export default CheckStatusPage
