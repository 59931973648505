import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from '@copa/design-system-factory.buttons.button'
import { Box } from '@mui/material'
import Header from '../../components/Header'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import ExceptionMessage from './ExceptionMessage'
import { goToCOPA } from '../../utils/functions'
import { useAppSelectGeneralInfo } from '../../hooks/store-hooks'
import PNRHeading from '../../components/PNRHeading'
import styles from './styles'

function ExceptionPage() {
  const generalContext = useAppSelectGeneralInfo()
  const { formatMessage } = useIntl()

  return (
    <>
      <Header
        title={formatMessage({ id: 'additionalInfoPage.header' })}
        goBackSteps={2}
      />
      <PNRHeading />
      <ResponsiveContainer id="exceptions-message-container" marginTop="15px">
        <ExceptionMessage />
        <Box sx={styles.button.container}>
          <Button
            style={{ width: '100%' }}
            data-cy="button-exception-page"
            variant="outlinePrimaryMain"
            onClick={() => {
              goToCOPA(generalContext.language)
            }}
            size="large"
          >
            {formatMessage({ id: 'exceptionsPage.cta' })}
          </Button>
        </Box>
      </ResponsiveContainer>
    </>
  )
}

export default ExceptionPage
