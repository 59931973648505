const onCancelModal = ({ isAgency, navigate, formatMessage }) => {
  if (isAgency) {
    navigate('/agency/find-reservation')
  } else {
    const uri = formatMessage({
      id: 'links.changesAndRefunds',
    })
    window.location.replace(`${process.env.REACT_APP_COPA_URL}${uri}`)
  }
}

export default onCancelModal
