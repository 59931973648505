import axios from "axios"

const url = process.env.REACT_APP_SRF_API_URL

const instance = axios.create({
  withCredentials: true,
})

const headers = {
  "Accept-Language": "en-US",
  "Content-Type": "application/json",
}

export const createRefund = (formData) =>
  instance.post(`${url}refund/refunds`, formData, { headers })

export const uploadFiles = (data) =>
  instance.post(`${url}refund/documents`, data, {
    ...headers,
    "Content-Type": "multipart/form-data",
  })

export const getRefund = (data) =>
  instance.post(`${url}refund/validateRefund`, data, { headers })
