import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  header: {
    background: "white",
    height: "72px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    boxShadow: "0px 1px 2px 0px #00000030",
    zIndex: 999,
  },
  headerBackButton: {
    width: "72px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #DBDAD8",
    cursor: "pointer",
  },
  headerTextContainer: {
    padding: "15px 16px",
  },
}))

export default useStyles
