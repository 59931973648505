import React, { FC } from 'react'
import { getAssetURL } from '../../../utils/assets'
import { AssetType, IconCategory } from '../../../types/assets.types'

function CreditCard() {
  return (
    <img
      src={getAssetURL('credit_card_2', {
        type: AssetType.icon,
        category: IconCategory.payment,
      })}
      alt=""
    />
  )
}

export default CreditCard as FC
