import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { Button } from '@copa/design-system-factory.buttons.button'
import Header from '../../components/Header'
import HyperlinkWithGA from '../../components/HyperlinkWithGA'
import constants from '../../utils/constants'
import { goToCOPA } from '../../utils/functions'

import useStyles from './styles'
import { useAppSelectGeneralInfo } from '../../hooks/store-hooks'
import PNRHeading from '../../components/PNRHeading'

function PNRErrorAgency() {
  const { classes: styles } = useStyles()
  const generalContext = useAppSelectGeneralInfo()
  const { language } = generalContext
  const { formatMessage } = useIntl()
  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(constants.copa_url[language])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header
        title={formatMessage({ id: 'additionalInfoPage.header' })}
        goBackSteps={2}
      />
      <PNRHeading />
      <section
        className={styles.container}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        aria-label={formatMessage({ id: 'errorAgency.WCAGContainer' })}
      >
        <Typography variant="h2" sx={{ color: '#002880' }}>
          {formatMessage({ id: 'errorAgency.title' })}
        </Typography>

        <section style={{ paddingTop: '16px', paddingBottom: '48px' }}>
          <Typography sx={{ fontSize: '16px', color: '#666666' }}>
            {formatMessage({ id: 'errorAgency.subTitle' })}
            <HyperlinkWithGA
              style={{ color: 'rgb(14, 104, 255)' }}
              linkUrl={url}
              target="_blank"
              linkText={formatMessage({ id: 'errorAgency.link' })}
              action="Reservation center click"
            />
            {formatMessage({ id: 'errorAgency.continue' })}
          </Typography>
        </section>
        <Grid container flex justifyContent="start">
          <Button
            data-cy="button-PNRErrorAgency"
            aria-label={formatMessage({ id: 'errorAgency.WCAGbutton' })}
            variant="outlinePrimaryMain"
            style={{ width: '496px' }}
            onClick={() => {
              goToCOPA(generalContext.language)
            }}
            size="large"
          >
            {formatMessage({ id: 'errorAgency.button' })}
          </Button>
        </Grid>
      </section>
    </>
  )
}

export default PNRErrorAgency
