import React from 'react'

function Discover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path fill="#fff" d="m.084 5.587 23.83-.002v14.83H.087L.084 5.587Z" />
      <path
        fill="#F58220"
        d="M23.83 13.832s-6.512 4.49-18.437 6.499H23.83v-6.499Z"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M0 5.5h24v15H0v-15Zm23.827 14.83V5.67H.173v14.66h23.654Z"
        clipRule="evenodd"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M1.104 10.089h.994c1.099 0 1.865.699 1.865 1.704a1.69 1.69 0 0 1-.63 1.297c-.335.273-.715.393-1.24.393h-.99V10.09Zm.858 2.819c.438 0 .714-.082.927-.27v.001a1.148 1.148 0 0 0 0-1.7c-.224-.197-.49-.274-.927-.274H1.78v2.243h.182Z"
        clipRule="evenodd"
      />
      <path
        fill="#231F20"
        d="M4.275 10.09h.678v3.393h-.678zm2.333 1.302c-.402-.147-.526-.244-.526-.427 0-.214.214-.377.506-.377a.706.706 0 0 1 .546.276l.355-.454a1.54 1.54 0 0 0-1.021-.376c-.615 0-1.083.417-1.083.973 0 .466.218.706.854.93.163.05.32.115.47.193a.403.403 0 0 1 .203.351.479.479 0 0 1-.158.35.502.502 0 0 1-.368.129.807.807 0 0 1-.74-.452l-.437.411c.313.447.687.647 1.208.647.695 0 1.191-.462 1.191-1.117 0-.538-.228-.787-1-1.057Zm1.212.401c-.002.475.192.93.538 1.264.347.332.816.514 1.302.503.292.001.581-.065.842-.194v-.786c-.268.262-.51.372-.817.372-.683 0-1.167-.484-1.167-1.17-.01-.305.104-.6.32-.82.214-.22.51-.345.822-.349.323 0 .567.113.85.382v-.775a1.767 1.767 0 0 0-.834-.209 1.849 1.849 0 0 0-1.31.512 1.764 1.764 0 0 0-.546 1.27Zm8.054.577-.926-2.28h-.739l1.473 3.48h.365l1.5-3.48h-.734l-.939 2.28Zm1.98 1.113h1.92v-.574h-1.243v-.917h1.198v-.574h-1.198v-.754h1.244v-.574h-1.921v3.393Z"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M21.228 10.09c.78 0 1.229.366 1.229 1.002 0 .519-.28.86-.792.963l1.096 1.428h-.833l-.94-1.364H20.9v1.364h-.677V10.09h1.005Zm-.328 1.563h.198c.433 0 .662-.196.662-.526s-.23-.502-.652-.502H20.9v1.028Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#a)"
        d="M14.42 11.793c.002.73-.447 1.39-1.137 1.672a1.88 1.88 0 0 1-2.015-.388 1.775 1.775 0 0 1-.403-1.968 1.848 1.848 0 0 1 1.707-1.116c1.019 0 1.845.805 1.848 1.8Z"
      />
      <path
        fill="url(#b)"
        d="M14.42 11.793c.002.73-.447 1.39-1.137 1.672a1.88 1.88 0 0 1-2.015-.388 1.775 1.775 0 0 1-.403-1.968 1.848 1.848 0 0 1 1.707-1.116c1.019 0 1.845.805 1.848 1.8Z"
        opacity=".65"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M22.774 10.128c.074 0 .116.034.116.094a.084.084 0 0 1-.066.087l.1.127h-.09l-.086-.12v.12h-.072v-.308h.098Zm-.032.136h.013l.007-.002c.036 0 .048-.014.048-.04 0-.028-.018-.04-.055-.04h-.013v.082Z"
        clipRule="evenodd"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M22.52 10.284a.27.27 0 1 1 .539 0 .27.27 0 0 1-.54 0Zm.06.087a.22.22 0 0 0 .209.13l-.002-.005a.216.216 0 0 0 .213-.213.22.22 0 0 0-.377-.154.22.22 0 0 0-.043.242Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="14.52"
          x2="13.283"
          y1="12.621"
          y2="10.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9A020" />
          <stop offset=".25" stopColor="#F89B20" />
          <stop offset=".53" stopColor="#F78E20" />
          <stop offset=".62" stopColor="#F68820" />
          <stop offset=".73" stopColor="#F58120" />
          <stop offset="1" stopColor="#F47521" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="15.142"
          x2="13.341"
          y1="12.305"
          y2="8.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F68820" stopOpacity=".01" />
          <stop offset=".11" stopColor="#EF8023" stopOpacity=".14" />
          <stop offset=".31" stopColor="#E47327" stopOpacity=".35" />
          <stop offset=".5" stopColor="#DB682A" stopOpacity=".52" />
          <stop offset=".69" stopColor="#D5612C" stopOpacity=".64" />
          <stop offset=".85" stopColor="#D15D2E" stopOpacity=".71" />
          <stop offset=".98" stopColor="#D05B2E" stopOpacity=".74" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Discover
