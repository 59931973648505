import React from 'react'

export default function BusinessSeatIcon({ ...props }) {
  return (
    <svg
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="64" cy="64" r="64" fill="#EFF5FF" />
      <path
        d="M64.6302 35.2534L69.3664 34.1623L64.6964 32.7324C63.5767 32.3872 62.5659 31.757 61.7632 30.9036C60.9605 30.0501 60.3933 29.0027 60.1173 27.864L58.9684 23.1113L57.555 27.7648C57.22 28.8764 56.5984 29.8802 55.7525 30.6753C54.9066 31.4704 53.8663 32.0288 52.7361 32.2943L48 33.3936L52.67 34.8236C53.7905 35.1673 54.802 35.797 55.605 36.6507C56.408 37.5044 56.9746 38.5526 57.2491 39.692L58.398 44.4447L59.7866 39.7829C60.1237 38.6683 60.7491 37.6624 61.5996 36.8671C62.4501 36.0717 63.4956 35.515 64.6302 35.2534Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0196 21.3342C59.8211 21.3574 60.5079 21.9142 60.6963 22.6936L61.845 27.4452C62.0485 28.284 62.4667 29.0567 63.0581 29.6855C63.6494 30.3141 64.3938 30.7784 65.2184 31.033C65.2179 31.0328 65.2189 31.0331 65.2184 31.033L69.8868 32.4624C70.6562 32.698 71.1714 33.421 71.143 34.2251C71.1146 35.0293 70.5495 35.7141 69.7654 35.8947L65.0297 36.9857C65.0295 36.9857 65.0298 36.9856 65.0297 36.9857C64.2002 37.177 63.4356 37.5841 62.8138 38.1655C62.1928 38.7463 61.7359 39.4805 61.4893 40.2942C61.4889 40.2952 61.4886 40.2963 61.4883 40.2974L60.1018 44.9521C59.8724 45.7223 59.1548 46.2428 58.3515 46.2218C57.5482 46.2008 56.8588 45.6435 56.67 44.8623L55.5211 40.1097C55.3189 39.2706 54.9013 38.4974 54.31 37.8687C53.7187 37.24 52.9738 36.7763 52.1487 36.5232C52.1487 36.5232 52.1487 36.5232 52.1487 36.5232L47.4795 35.0935C46.7106 34.8581 46.1955 34.1359 46.2232 33.3322C46.251 32.5286 46.8147 31.8437 47.598 31.6619L52.3295 30.5636C52.3302 30.5635 52.3309 30.5633 52.3316 30.5631C53.1571 30.3688 53.9169 29.9607 54.5349 29.3799C55.1533 28.7985 55.6078 28.0646 55.8527 27.2519L55.8539 27.2481L57.2673 22.5946C57.5003 21.8274 58.2181 21.3111 59.0196 21.3342ZM62.9258 33.9366C62.0074 33.4859 61.1741 32.8721 60.4682 32.1215C59.7476 31.3554 59.1773 30.4655 58.7825 29.4984C58.3369 30.4235 57.7236 31.2623 56.97 31.9706C56.2263 32.6697 55.3641 33.2246 54.4276 33.6115C55.3519 34.0623 56.1904 34.6783 56.8999 35.4326C57.6128 36.1905 58.1783 37.0698 58.5718 38.025C59.0193 37.1055 59.6329 36.2721 60.3853 35.5685C61.1292 34.8728 61.9907 34.3211 62.9258 33.9366Z"
        fill="#0032A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 103.111C32 102.129 32.8705 101.333 33.9444 101.333H95.8334C96.9073 101.333 97.7778 102.129 97.7778 103.111C97.7778 104.093 96.9073 104.889 95.8334 104.889H33.9444C32.8705 104.889 32 104.093 32 103.111Z"
        fill="#0032A0"
      />
      <path
        d="M39.1883 81.9901L26.9168 90.5677C24.8439 92.0167 24.4045 94.9049 25.9526 96.9049C27.4042 98.7799 30.0756 99.178 32.0107 97.8075L38.677 93.0863C39.5526 92.4662 40.1412 91.5192 40.3094 90.4595L41.4314 83.3937C41.6303 82.1411 40.2277 81.2636 39.1883 81.9901Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5417 97.9836C22.3754 95.1852 22.9789 91.1455 25.8685 89.1025L43.9923 76.2885C44.6137 75.8492 45.3539 75.6091 46.1148 75.6L49.7555 75.5565L49.798 79.1119L46.1573 79.1553C46.117 79.1558 46.0779 79.1685 46.045 79.1917L27.9212 92.0057C26.6843 92.8802 26.426 94.6093 27.3532 95.8071C28.2258 96.9343 29.8318 97.1736 30.9951 96.3498L39.021 90.6657L41.0759 93.5673L33.05 99.2513C30.3322 101.176 26.5803 100.617 24.5417 97.9836Z"
        fill="#0032A0"
      />
      <path
        d="M65.2821 75.5558H50.845C46.6078 75.5558 42.9597 78.5466 42.1287 82.7015L39.9257 93.7168C39.0456 98.1171 42.4112 102.222 46.8987 102.222H72.8802C75.068 102.222 77.0701 100.993 78.0591 99.0412L103.323 49.1954C104.61 46.6566 105.28 43.8504 105.28 41.0041V32.8892C105.28 28.4709 101.698 24.8892 97.2801 24.8892H93.7245C90.779 24.8892 88.3912 27.277 88.3912 30.2225V42.9524C88.3912 43.9285 88.1233 44.8859 87.6167 45.7203L84.6134 50.6669L80.8912 56.0003L66.7024 74.8473C66.3665 75.2934 65.8405 75.5558 65.2821 75.5558Z"
        fill="white"
      />
      <path
        d="M88.8892 24.8892V43.5558C96.2345 44.78 103.002 40.6467 104.556 33.884C105.001 31.945 104.472 29.9513 103.664 28.1332L102.222 24.8892H88.8892Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.7251 26.706C91.7614 26.706 90.1695 28.3153 90.1695 30.3006V43.1704C90.1695 44.4862 89.8123 45.7768 89.1369 46.9015L87.6264 49.2456C87.3055 49.7436 86.7533 50.044 86.1609 50.0428C84.8101 50.0402 83.9777 48.566 84.6731 47.408L86.0976 45.0359C86.4354 44.4736 86.614 43.8283 86.614 43.1704V30.3006C86.614 26.3301 89.7977 23.1113 93.7251 23.1113H97.2806C102.681 23.1113 107.058 27.5371 107.058 32.9966V41.2007C107.058 44.3606 106.322 47.476 104.909 50.2945L79.6454 100.688C78.3535 103.265 75.7384 104.889 72.8807 104.889H46.8992C41.2899 104.889 37.0829 99.701 38.183 94.1401L40.386 83.0038C41.3832 77.9631 45.761 74.3348 50.8456 74.3348H65.1887L77.5545 54.5045L80.5623 56.4215L68.1964 76.2518C67.5453 77.296 66.4097 77.9294 65.1887 77.9294H50.8456C47.4558 77.9294 44.5373 80.3483 43.8725 83.7087L41.6695 94.8451C41.0094 98.1816 43.5337 101.294 46.8992 101.294H72.8807C74.3987 101.294 75.7877 100.432 76.4739 99.063L101.738 48.6694C102.898 46.3546 103.503 43.7959 103.503 41.2007V32.9966C103.503 29.5224 100.717 26.706 97.2806 26.706H93.7251Z"
        fill="#0032A0"
      />
      <path
        d="M45.7227 60.4444C45.7227 57.9898 47.7125 56 50.1671 56H79.5715C81.2269 56 82.3477 57.6864 81.7067 59.2126L81.5317 59.6294C80.1933 62.8159 77.0743 64.8889 73.6181 64.8889H50.1671C47.7125 64.8889 45.7227 62.899 45.7227 60.4444Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9448 60.4449C43.9448 57.0084 46.7306 54.2227 50.167 54.2227H79.5715C82.4976 54.2227 84.4788 57.2036 83.3457 59.9015L83.1707 60.3182L81.5316 59.6298L83.1707 60.3182C81.5551 64.1648 77.7901 66.6671 73.618 66.6671H50.167C46.7306 66.6671 43.9448 63.8813 43.9448 60.4449ZM50.167 57.7782C48.6943 57.7782 47.5004 58.9721 47.5004 60.4449C47.5004 61.9176 48.6943 63.1115 50.167 63.1115H73.618C76.3584 63.1115 78.8314 61.4679 79.8925 58.9414L80.0676 58.5246L81.6661 59.196L80.0676 58.5246C80.2165 58.17 79.9561 57.7782 79.5715 57.7782H50.167Z"
        fill="#0032A0"
      />
    </svg>
  )
}
