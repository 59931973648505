import constants from "../utils/constants"
import { useAppSelectGeneralInfo } from "./store-hooks"

const useIsNRPayedMisc = () => {
  const { triphubData } = useAppSelectGeneralInfo()
  return (
    triphubData?.trip?.revenue?.isNonRevenue &&
    parseInt(triphubData?.trip?.refund?.nonRevenueEligibility, 10) ===
      constants.NON_REVENUE_ELIGIBILITY.HIDDE_BANK_INFO
  )
}

export default useIsNRPayedMisc
