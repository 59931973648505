/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import localeData from './translations'
import CreateRequestPage from './pages/CreateRequest'
import EnhancedRoute from './components/EnhancedRoute'
import AddPassengersPage from './pages/AddPassengers'
import PNRNotFoundPage from './pages/PNRNotFound'
import PNRErrorAgency from './pages/PNRErrorAgency'
import RequestConfirmationPage from './pages/RequestConfirmation'
import FindReservationPage from './pages/CallCenter/FindReservation'
import ExceptionPage from './pages/ExceptionPage'
import CheckStatusPage from './pages/CheckStatus/CheckStatusPage'
import {
  getPersistedParameter,
  persistParameter,
  sendGAPageView,
} from './utils/functions'
import { useAppDispatch, useAppSelectGeneralInfo } from './hooks/store-hooks'
import ErrorPage from './pages/Error'
import PageNotFound from './pages/PageNotFound'
import ErrorWrapper from './components/ErrorWrapper'
import { identifyUser, setupLogsRecording } from './utils/logs'
import CreateRequestFormPage from './pages/CreateRequestForm'
import { Locale } from './types/translations.types'

function persistParameters(params: {
  languageParam: Locale
  country: string | null
}) {
  persistParameter('lang', params.languageParam)
  persistParameter('country', params.country)
}

// URL to be received from Copa.com
// refunds.copa.com/?request_code=325435543&last_name=acevedo&lang=es&country=col
// Locale value needs to be captured from lang querystring

function App() {
  const [language, setLanguage] = useState<Locale>(Locale.es)
  const dispatch = useAppDispatch()
  const { requestCode } = useAppSelectGeneralInfo()

  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const languageParam = (searchParams.get('lang') ||
    getPersistedParameter('lang') ||
    Locale.es) as Locale
  const requestCodeParam = searchParams.get('request_code')

  useEffect(() => {
    setupLogsRecording()
    const userLastName = searchParams.get('last_name')
    const country =
      searchParams.get('country') || getPersistedParameter('country')
    const generalContext = {
      requestCode: requestCodeParam,
      userLastName,
      language: languageParam,
      country,
      source: pathname.includes('/agency') ? 'callcenter' : 'copacom',
    }
    dispatch(generalContext)
    persistParameters({ languageParam, country })
    setLanguage(languageParam)
    if (requestCode && userLastName) {
      identifyUser(requestCode, userLastName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    sendGAPageView(
      pathname,
      languageParam,
      requestCodeParam || requestCode || 'Information not available'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <IntlProvider locale={language} messages={localeData[language]}>
      <ErrorWrapper>
        <Routes>
          <Route
            path="/create-request"
            element={
              <EnhancedRoute
                ariaText="createRequestPage.wcag.pageDescription"
                component={<CreateRequestPage />}
              />
            }
          />
          <Route
            path="/create-refund-ticket"
            element={
              <EnhancedRoute
                ariaText="createRequestPage.wcag.pageDescription"
                component={<CreateRequestFormPage />}
              />
            }
          />
          <Route
            path="/agency/create-request"
            element={
              <EnhancedRoute
                ariaText="additionalInfoPageWCAG.generalDescription"
                component={<CreateRequestPage isAgency />}
              />
            }
          />
          <Route
            path="/add-passengers"
            element={
              <EnhancedRoute
                ariaText="addPassengersPageWCAG.generalDescription"
                component={<AddPassengersPage />}
              />
            }
          />
          <Route
            path="/agency/add-passengers"
            element={
              <EnhancedRoute
                ariaText="addPassengersPageWCAG.generalDescription"
                component={<AddPassengersPage isAgency />}
              />
            }
          />
          <Route
            path="/reservation-not-found"
            element={
              <EnhancedRoute ariaText="" component={<PNRNotFoundPage />} />
            }
          />
          <Route
            path="/agency/reservation-not-found"
            element={
              <EnhancedRoute
                ariaText=""
                component={<PNRNotFoundPage isAgency />}
              />
            }
          />
          <Route
            path="/request-confirmation"
            element={
              <EnhancedRoute
                ariaText=""
                component={<RequestConfirmationPage confirmation />}
              />
            }
          />
          <Route
            path="/request-status"
            element={
              <EnhancedRoute
                ariaText=""
                component={<RequestConfirmationPage />}
              />
            }
          />
          <Route
            path="/request-to-agency"
            element={
              <EnhancedRoute ariaText="" component={<PNRErrorAgency />} />
            }
          />
          <Route
            path="/agency/find-reservation"
            element={
              <EnhancedRoute ariaText="" component={<FindReservationPage />} />
            }
          />
          <Route
            path="/non-eligible-request"
            element={
              <EnhancedRoute
                ariaText="exceptionsPageWCAG.container"
                component={<ExceptionPage />}
              />
            }
          />
          <Route
            path="/check-status"
            element={
              <EnhancedRoute
                ariaText="checkStatusPageWCAG.container"
                component={<CheckStatusPage />}
              />
            }
          />
          <Route
            path="/error"
            element={
              <EnhancedRoute
                ariaText="errorPageWCAG.container"
                component={<ErrorPage />}
              />
            }
          />
          <Route
            path="*"
            element={
              <EnhancedRoute
                ariaText="pageNotFoundWCAG.container"
                component={<PageNotFound />}
              />
            }
          />
        </Routes>
      </ErrorWrapper>
    </IntlProvider>
  )
}

export default App
