const styles = {
  header: {
    fontWeight: { xs: 700, sm: 500, md: 700 },
    lineHeight: { xs: '24px', md: '40px' },
    fontSize: {
      xs: '18px',
      sm: '20px',
      md: '32px',
    },
  },
  button: {
    container: {
      width: { xs: '100%', sm: '40%', md: '25%', lg: '20%' },
    },
    '& > div': {
      width: { xs: '379px', sm: '560px', md: '362px', lg: '392px' },
      height: '48px',
      borderRadius: '4px',
      marginTop: {
        xs: '96px',
        sm: '80px',
        md: '72px',
        lg: '88px',
      },
      mb: { xs: '96px' },
    },
  },
}

export default styles
