import React, { FC } from 'react'
import { Typography } from '@copa/design-system-factory.typography'
import { theme } from '@copa/design-system-factory.theme'
import { Box, SxProps } from '@mui/material'
import { PAYMENT_METHOD_BY_CODE } from './utils'

type PaymentMethodBannerProps = {
  methodCode?: keyof typeof PAYMENT_METHOD_BY_CODE
  text: string
  sx?: SxProps
  testId?: string
}

function PaymentMethodBanner({
  methodCode,
  text,
  sx = {},
  testId = '',
}: PaymentMethodBannerProps) {
  const Icon = methodCode ? PAYMENT_METHOD_BY_CODE[methodCode]?.icon : null
  return (
    <Box
      data-testid={testId}
      sx={{
        maxWidth: '496px',
        height: '64px',
        borderRadius: '8px',
        padding: '20px',
        border: `1px solid ${theme.palette.grey[200]}`,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        ...sx,
      }}
    >
      {Icon}
      <Typography
        style={{
          marginLeft: '16px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          color: theme.palette.grey[700],
        }}
        sx={{ fontFamily: 'SuisseIntl' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default PaymentMethodBanner as FC<PaymentMethodBannerProps>
