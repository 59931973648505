/* eslint-disable import/prefer-default-export */
export const INPUT_FLOATING_HELPER_TEXT = {
  '& > div': {
    position: 'relative',
  },
  '& > div > div:nth-of-type(2)': {
    position: 'absolute',
    top: '100%',
    left: 0,
    paddingTop: '0.5rem',
  },
}
