// @ts-check
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { theme } from '@copa/design-system-factory.theme'
import { InlineAlert } from '@copa/design-system-factory.inline-alert'
import { Pill } from '@copa/design-system-factory.pill'
import useStyle from './style'
import { useAppSelectGeneralInfo } from '../../../../hooks/store-hooks'
import { Passenger, Status } from '../../../../types/state.types'
import alertIcon from '../../../../assets/Icons/svg/AlerIcon.svg'
import { PILL_CONFIG } from './utils'

type Props = {
  passengers?: Passenger[]
  confirmation?: boolean
  refundCase: {
    caseNumber: string
    status?: Status
  }
}

const CaseStatus: FC<Props> = ({
  passengers,
  refundCase,
  confirmation = false,
}) => {
  const { formatMessage } = useIntl()
  const { language } = useAppSelectGeneralInfo()
  const formatDate = (date?: string) => {
    if (!date) {
      return ''
    }
    const dateObj = new Date(date)
    const dayAndMonth = dateObj.toLocaleString(String(language).toLowerCase(), {
      day: '2-digit',
      month: 'long',
    })

    return `${dayAndMonth}, ${dateObj.getFullYear()}`
  }

  const statusName = refundCase?.status?.name
  const statusConfig = statusName ? PILL_CONFIG[statusName] : null
  return (
    <>
      <Box sx={useStyle.title}>
        {confirmation ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <CheckCircle
              sx={{
                fontSize: { xs: '20px', md: '24px' },
                marginRight: '8px',
                marginTop: { xs: '0px', sm: '4px', md: '0px' },
                color: theme.palette.success.main,
              }}
            />
            <Typography sx={useStyle.titleFont} variant="h2">
              {formatMessage({
                id: 'refundConfirmation.title',
              })}
            </Typography>
          </Box>
        ) : (
          <Typography sx={useStyle.titleFont} variant="h2">
            {formatMessage({
              id: 'refundStatus.title',
            })}
          </Typography>
        )}
      </Box>
      {!confirmation && (
        <Box sx={useStyle.description}>
          <Typography>
            {formatMessage({
              id: 'refundStatus.description',
            })}
          </Typography>
        </Box>
      )}
      <InlineAlert
        message={formatMessage({
          id: 'refundConfirmation.passengerDisclaimer',
        })}
        icon={alertIcon}
      />
      <Box sx={useStyle.caseStatus}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '18px', lg: '20px' },
                lineHeight: { xs: '24px', lg: '32px' },
                fontFamily: 'Gilroy, sans-serif',
              }}
            >
              {formatMessage({
                id: 'refundConfirmation.case',
              })}
              {refundCase.caseNumber}
            </Typography>
          </Grid>
          <Grid item alignItems="center" display="flex">
            <Typography
              sx={{
                color: theme.palette.grey['800'],
                display: { xs: 'none', md: 'inline' },
                paddingRight: '20px',
              }}
            >
              {formatMessage({
                id: 'refundConfirmation.caseStatus',
              })}
            </Typography>
            {statusConfig && (
              <Pill
                color={statusConfig.color}
                text={formatMessage({
                  id: statusConfig.text,
                })}
              />
            )}
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: theme.palette.grey['800'],
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {formatMessage({
            id: 'refundConfirmation.caseDateHeader',
          })}{' '}
          {formatDate(refundCase?.status?.date)}
        </Typography>

        <Box display={{ sm: 'none' }} sx={{ color: theme.palette.grey['800'] }}>
          <Typography>
            {formatMessage({
              id: 'refundConfirmation.caseDateHeader',
            })}
          </Typography>
          <Typography>{formatDate(refundCase?.status?.date)}</Typography>
        </Box>

        <Box sx={useStyle.passengersContainer}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                md: 'repeat(4, 1fr)',
                sm: 'repeat(3, 1fr)',
                xs: 'repeat(1, 1fr)',
              },
              columnGap: '16px',
            }}
          >
            {passengers?.map(pass => (
              <Grid
                key={`Passanger_Box${pass.number}`}
                item
                xs={6}
                sm={4}
                md={3}
                sx={useStyle.passengers}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.primary.main,
                    wordBreak: 'break-word',
                  }}
                >
                  {`${pass?.firstName || pass?.givenName} ${pass?.lastName}`}
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {pass.number ? `# ${pass.number}` : ''}
                  {pass.eTicket ? `# ${pass.eTicket}` : ''}
                </Typography>
              </Grid>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CaseStatus
