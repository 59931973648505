const styles = {
  ctaContainer: {
    border: "1px solid #DBDAD8",
    borderRadius: "10px",
    display: "grid",
    textAlign: {
      xs: "center",
      sm: "left",
    },
    gridTemplateColumns: {
      lg: "52px 1fr 22px",
      md: "40px 1fr 22px",
      sm: "52px 1fr 22px",
      xs: "1fr",
    },
    columnGap: {
      lg: "32px",
      md: "40px",
      sm: "16px",
    },
    rowGap: {
      xs: "10px",
      sm: "0px",
    },
    marginTop: {
      xs: "38px",
      sm: "56px",
      md: "48px",
      lg: "32px",
    },
    padding: {
      xs: "24px",
      sm: "24px 26px",
      md: "36px 70px",
      lg: "45.5px 60px",
    },
  },
  header: {
    marginBottom: {
      xs: "16px",
      sm: "8px",
    },
    color: "primary.main",
    fontWeight: 700,
  },
  title: {
    fontFamily: "Gilroy",
    fontWeight: 700,
    fontSize: {
      md: "18px",
      lg: "20px",
    },
    lineHeight: {
      sm: "24px",
      md: "32px",
    },
    letterSpacing: "0px",
    textAlign: "left",
  },
  firstIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: {
      xs: "center",
      sm: "flex-start",
    },
    marginRight: {
      xs: "0",
      sm: "16px",
      md: "40px",
      lg: "32px",
    },
  },
  lastIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: {
      xs: "center",
      sm: "flex-start",
    },
  },
  ctaText: {
    maxWidth: "756px",
    marginTop: {
      xs: "6px",
      sm: "0px",
    },
  },
  body1: {
    textAlign: {
      xs: "center",
      sm: "left",
    },
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#666666",
  },
}

export default styles
