import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@copa/design-system-factory.typography'
import { theme } from '@copa/design-system-factory.theme'
import { InlineAlert } from '@copa/design-system-factory.inline-alert'
import { Box } from '@mui/material'
import { sendGACustomEvent } from '../../../../utils/functions'
import alertIcon from '../../../../assets/Icons/svg/AlerIcon.svg'

function AlertMessage({
  message,
  show,
  actionlink,
  actionText,
  wcagDescription,
}) {
  const navigate = useNavigate()
  function handleLinkClick() {
    sendGACustomEvent('ETKT error check status')
    navigate(actionlink)
  }

  if (!show) {
    return null
  }

  return (
    <Box
      aria-label={wcagDescription}
      aria-live="assertive"
      sx={{
        marginBottom: { sm: '36px', xs: '32px' },
        marginTop: { lg: '-28px', md: '-12px', xs: '-4px' },
        width: '100%',
      }}
    >
      <InlineAlert
        variant="error"
        message={
          <>
            {message}
            {actionlink && actionText && (
              <button
                type="button"
                style={{
                  cursor: 'pointer',
                  background: 'transparent',
                  border: 'none',
                  padding: 0,
                }}
                onClick={handleLinkClick}
              >
                <Typography
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: '14px',
                    fontWeight: 500,
                    textDecoration: 'underline',
                  }}
                >
                  {actionText}
                </Typography>
              </button>
            )}
          </>
        }
        icon={alertIcon}
      />
    </Box>
  )
}

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  actionlink: PropTypes.string,
  actionText: PropTypes.string,
  wcagDescription: PropTypes.string,
}

AlertMessage.defaultProps = {
  actionlink: '',
  actionText: '',
  wcagDescription: '',
}

export default AlertMessage
