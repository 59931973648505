const modalStyles = {
  marginBottom: "32px",
  width: "100%",
  textAlign: "center",
  "& div": {
    height: "80px",
    width: "100%",
    padding: "2px",
    margin: "2px",
  },
  "& div img": {
    height: "72px",
    width: "72px",
  },
}

export default modalStyles
