import React, { CSSProperties, ReactNode } from "react"
import useStyles from "./styles"

type Props = {
  style: CSSProperties
  linkUrl: string
  target: string
  linkText: string | ReactNode | ReactNode[]
  className?: string
}

const Hyperlink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ linkUrl = "", linkText = "", className = "", ...props }, ref) => {
    const { classes: styles } = useStyles()
    const defaultClass = className || styles.hyperlink
    return (
      <a {...props} className={defaultClass} href={linkUrl} ref={ref}>
        {linkText}
      </a>
    )
  }
)

export default Hyperlink
export type HyperlinkProps = Props
