import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DocumentChip from '../DocumentChip'
import { useUploadDocument } from '../../../../hooks/useAPICall'
import { sendGACustomEvent } from '../../../../utils/functions'

function DocumentChipWrapper({
  file,
  index,
  handleRemoveDocument,
  handleUploadSuccess,
  notifyUploadErrors,
  notifyUploadErrorsFixed,
}) {
  const { request, isLoading, error, responseData } = useUploadDocument()
  const [isReloaded, setIsReloaded] = useState(false)
  useEffect(() => {
    /** Only if It's a new document is going to do the request */
    if (file.isNew) {
      handleRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.isNew])

  useEffect(() => {
    if (responseData) {
      responseData.contentType = file.type
      responseData.name = file.name
      handleUploadSuccess(responseData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData])

  useEffect(() => {
    /* Let the parent component know if the error is still present or not */
    if (error && !isReloaded) {
      sendGACustomEvent('File upload error')
      notifyUploadErrors(index)
    } else if (!error && isReloaded) {
      notifyUploadErrorsFixed(index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  function handleRequest() {
    const data = new FormData()
    data.append('file', file)
    request(data)
  }

  function handleReload() {
    setIsReloaded(true)
    handleRequest()
  }

  function handleRemove(documentIndex) {
    handleRemoveDocument(documentIndex, responseData)
  }

  return (
    <DocumentChip
      documentInfo={{ name: file.name, index }}
      isLoading={isLoading}
      error={!!error}
      handleRemoveDocument={idx => handleRemove(idx)}
      handleReload={() => handleReload()}
    />
  )
}

DocumentChipWrapper.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleRemoveDocument: PropTypes.func.isRequired,
  handleUploadSuccess: PropTypes.func.isRequired,
  notifyUploadErrors: PropTypes.func.isRequired,
  notifyUploadErrorsFixed: PropTypes.func.isRequired,
}

export default DocumentChipWrapper
