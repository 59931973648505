import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "55px 212px",
    [theme.breakpoints.down("lg")]: {
      padding: "55px 172px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "55px 106px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "55px 16px",
    },
  },
}))

export default useStyles
