import es from "./languages/es"
import en from "./languages/en"
import pt from "./languages/pt"
import fr from "./languages/fr"
import { Locale } from "../types/translations.types"

export default {
  en,
  es,
  pt,
  fr,
} as { [key in Locale]: Record<string, string> }
