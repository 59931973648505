import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { Typography } from '@copa/design-system-factory.typography'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './styles'

import ManageIcon from './icons/ManageIcon'
import LeftArrowIcon from './icons/LeftArrowIcon'
import { useAppDispatch } from '../../../../hooks/store-hooks'

function ManualRequestCTA({
  containerStyle,
  title,
  subtitle,
  disclaimerMessage,
  isAgency,
}) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // restart the flow to automatic
    dispatch({ flow: 'automatic' })
  }, [dispatch])

  return (
    <Box sx={{ ...containerStyle }}>
      <Typography sx={styles.title}>{title}</Typography>
      <Link
        to={isAgency ? '/agency/add-passengers' : '/add-passengers'}
        style={{ textDecoration: 'none' }}
      >
        <Box id="manual-option-cta-container" sx={styles.ctaContainer}>
          <Grid item sx={styles.firstIcon}>
            <ManageIcon
              id="manage-cta-icon"
              sx={{
                fontSize: { xs: '64px', sm: '52px', md: '40px', lg: '52px' },
              }}
            />
          </Grid>
          <Grid item>
            <Box sx={styles.ctaText}>
              <Typography
                id="manual-option-cta-subtitle"
                variant="h4"
                sx={styles.header}
              >
                {subtitle}
              </Typography>

              <Typography id="manual-option-cta-disclaimer" sx={styles.body1}>
                {disclaimerMessage}
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={styles.lastIcon}>
            <LeftArrowIcon
              id="left-arrow-cta-icon"
              sx={{
                fontSize: { xs: '25px', sm: '22px', md: '24px', lg: '22px' },
              }}
            />
          </Grid>
        </Box>
      </Link>
    </Box>
  )
}

ManualRequestCTA.propTypes = {
  containerStyle: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  disclaimerMessage: PropTypes.string.isRequired,
  isAgency: PropTypes.bool,
}

ManualRequestCTA.defaultProps = {
  containerStyle: {},
  isAgency: false,
}

export default ManualRequestCTA
