export const createErrorsMessages = (formatMessage) => ({
  required: formatMessage({ id: "reasonSectionPage.isRequiredError" }),
  minLength: formatMessage({
    id: "reasonSectionPage.invalidFormatError",
  }),
  maxLength: formatMessage({
    id: "reasonSectionPage.invalidFormatError",
  }),
  pattern: formatMessage({
    id: "reasonSectionPage.invalidFormatError",
  }),
  validate: formatMessage({
    id: "callCenterSpecialLink.invalidFormatMessage",
  }),
  "pnr-not-found": formatMessage({
    id: "inputErrorMessage.pnrNotFound",
  }),
  confirmation: formatMessage({
    id: "additionalInfoPage.confirmError",
  }),
  same: formatMessage({
    id: "additionalInfoPage.samePhone",
  }),
})

export const createErrorsMessagesTicketCode = (formatMessage) => ({
  required: formatMessage({ id: "callCenterSpecialLink.requiredMessage" }),
  minLength: formatMessage({
    id: "addPassengersPage.errorTicketCode",
  }),
  maxLength: formatMessage({
    id: "addPassengersPage.errorTicketCode",
  }),
  pattern: formatMessage({
    id: "addPassengersPage.errorTicketCode",
  }),
  "case-already-open": formatMessage({
    id: "addPassengersPage.caseAlreadyOpenLabelError",
  }),
  isValidETicket: formatMessage({
    id: "addPassengersPage.emdErrorScenario",
  }),
})
