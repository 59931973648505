import React from 'react'

export default function TicketIcon({ ...props }) {
  return (
    <svg
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25_5785)">
        <path
          d="M128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64Z"
          fill="#EFF5FF"
        />
        <path
          d="M68.1636 29.8647C72.3292 25.6991 79.0829 25.6991 83.2485 29.8647L98.1353 44.7515C102.301 48.9171 102.301 55.6709 98.1353 59.8365L59.7868 98.185C55.6212 102.351 48.8675 102.351 44.7019 98.185L29.8151 83.2982C25.6495 79.1326 25.6495 72.3788 29.8151 68.2132L68.1636 29.8647Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.9065 28.6076C71.7664 23.7478 79.6458 23.7478 84.5056 28.6077L99.3924 43.4944C104.252 48.3542 104.253 56.2332 99.3927 61.0932L91.4546 69.0313C90.7604 69.7255 89.6348 69.7255 88.9405 69.0313C88.2462 68.337 88.2462 67.2114 88.9405 66.5171L96.8786 58.579C100.35 55.1078 100.35 49.48 96.8782 46.0086L81.9914 31.1218C78.5201 27.6505 72.892 27.6505 69.4207 31.1218L31.0722 69.4703C27.6008 72.9416 27.6008 78.5698 31.0722 82.0411L45.9589 96.9279C49.4303 100.399 55.0584 100.399 58.5297 96.9279L66.093 89.3646C66.7873 88.6703 67.9129 88.6703 68.6072 89.3646C69.3014 90.0588 69.3015 91.1845 68.6072 91.8787L61.0439 99.442C56.184 104.302 48.3046 104.302 43.4448 99.442L28.558 84.5553C23.6981 79.6954 23.6981 71.816 28.558 66.9562L66.9065 28.6076Z"
          fill="#0032A0"
        />
        <path
          d="M61.0782 76.4948L53.0782 72.2496L53.0439 69.5499L53.0268 68.0146C53.0255 67.6324 52.9473 67.2544 52.7969 66.903C52.6465 66.5516 52.427 66.2341 52.1514 65.9693C51.8758 65.7045 51.5498 65.4979 51.1927 65.3617C50.8356 65.2255 50.4547 65.1624 50.0727 65.1764C49.6903 65.1838 49.3133 65.2681 48.9641 65.4242C48.6148 65.5803 48.3006 65.8051 48.04 66.0852C47.7794 66.3652 47.5778 66.6948 47.4472 67.0543C47.3166 67.4139 47.2596 67.796 47.2797 68.1779L47.3374 69.6958L47.4991 72.4071L39.7466 77.078C39.606 77.1637 39.4902 77.2847 39.4109 77.4291C39.3316 77.5735 39.2915 77.7361 39.2946 77.9007L39.3464 79.8068C39.3513 79.9549 39.3911 80.0998 39.4626 80.2296C39.534 80.3595 39.6351 80.4706 39.7576 80.554C39.8743 80.6375 40.0088 80.6929 40.1504 80.716C40.2921 80.7391 40.4372 80.7292 40.5744 80.6872L47.9383 78.3742C48.0723 79.7196 48.2829 81.0561 48.5691 82.3774L46.7669 84.6545C46.6403 84.825 46.5749 85.0333 46.5814 85.2455L46.6159 86.6475C46.6214 86.8041 46.6655 86.957 46.7443 87.0924C46.8231 87.2278 46.9342 87.3417 47.0677 87.4238C47.2013 87.507 47.3541 87.5544 47.5113 87.5614C47.6686 87.5685 47.8251 87.535 47.9657 87.4641L50.6656 86.1196L53.4347 87.3125C53.6419 87.4024 53.875 87.4127 54.0893 87.3414C54.1714 87.3133 54.2494 87.2743 54.3211 87.2255C54.444 87.1387 54.5437 87.023 54.6115 86.8886C54.6792 86.7542 54.7128 86.6053 54.7094 86.4549L54.6923 85.0239C54.6886 84.8138 54.613 84.6114 54.478 84.4504L52.5898 82.1913C52.7955 80.877 52.9253 79.5519 52.9787 78.2227L60.4634 80.1216C60.6337 80.1622 60.8119 80.1561 60.9791 80.1041C61.0857 80.0704 61.1843 80.0151 61.2688 79.9419C61.3914 79.8539 61.4905 79.737 61.5573 79.6015C61.624 79.4661 61.6563 79.3163 61.6513 79.1655L61.5995 77.2594C61.5873 77.0974 61.5328 76.9415 61.4413 76.8073C61.3498 76.673 61.2246 76.5653 61.0782 76.4948Z"
          fill="#00A3E0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.5071 35.5735C71.2013 34.8792 72.327 34.8792 73.0212 35.5735L75.5241 38.0764C76.2184 38.7706 76.2184 39.8962 75.5241 40.5905C74.8298 41.2848 73.7042 41.2848 73.0099 40.5905L70.5071 38.0876C69.8128 37.3934 69.8128 36.2678 70.5071 35.5735ZM78.0157 43.0821C78.7099 42.3878 79.8356 42.3878 80.5298 43.0821L85.5356 48.0878C86.2298 48.7821 86.2298 49.9077 85.5356 50.602C84.8413 51.2962 83.7157 51.2962 83.0214 50.602L78.0157 45.5962C77.3214 44.902 77.3214 43.7763 78.0157 43.0821ZM88.0271 53.0935C88.7214 52.3993 89.847 52.3993 90.5413 53.0935L93.0442 55.5964C93.7384 56.2907 93.7384 57.4163 93.0442 58.1106C92.3499 58.8048 91.2243 58.8048 90.53 58.1106L88.0271 55.6077C87.3329 54.9134 87.3329 53.7878 88.0271 53.0935Z"
          fill="#0032A0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3387 48.4943C72.033 49.1886 72.033 50.3142 71.3387 51.0085L60.0842 62.2627C59.3899 62.957 58.2643 62.957 57.57 62.2627C56.8757 61.5684 56.8758 60.4428 57.57 59.7485L68.8246 48.4943C69.5188 47.8001 70.6445 47.8001 71.3387 48.4943Z"
          fill="#0032A0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.0211 57.8088C73.7154 58.5031 73.7154 59.6287 73.0211 60.323L65.5822 67.7617C64.8879 68.4559 63.7623 68.4559 63.068 67.7616C62.3738 67.0674 62.3738 65.9417 63.0681 65.2475L70.507 57.8088C71.2013 57.1145 72.3269 57.1145 73.0211 57.8088Z"
          fill="#0032A0"
        />
        <path
          d="M67.0562 90.9997L77.0562 80.9998"
          stroke="#0032A0"
          strokeWidth="3.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_5785">
          <rect
            width="128"
            height="128"
            fill="white"
            transform="matrix(-1 0 0 1 128 0)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
