import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { useIntl } from 'react-intl'
import styles from './styles'

function ExceptionMessage() {
  const { formatMessage } = useIntl()
  const bulletText = [
    formatMessage({ id: 'exceptionsPage.bullet1' }),
    formatMessage({ id: 'exceptionsPage.bullet2' }),
    formatMessage({ id: 'exceptionsPage.bullet3' }),
    formatMessage({ id: 'exceptionsPage.bullet4' }),
    formatMessage({ id: 'exceptionsPage.bullet5' }),
    formatMessage({ id: 'exceptionsPage.bullet6' }),
    formatMessage({ id: 'exceptionsPage.bullet7' }),
    formatMessage({ id: 'exceptionsPage.bullet8' }),
  ]
  return (
    <Box sx={styles.root}>
      <Typography
        id="exceptions-title"
        sx={styles.title}
        color="primary.main"
        variant="h2"
      >
        {formatMessage({ id: 'exceptionsPage.title' })}
      </Typography>

      <Box>
        <Typography
          id="exceptions-subtitle"
          sx={styles.subtitle}
          variant="body1"
          fontWeight={700}
        >
          {formatMessage({ id: 'exceptionsPage.subtitle' })}
        </Typography>

        <ul id="exceptions-situations" style={styles.bulletLitContainer}>
          {bulletText.map(bt => (
            <li style={styles.bulletListElement}>
              <Typography
                variant="body1"
                sx={styles.bulletText}
                color="grey.600"
              >
                {bt}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  )
}

export default ExceptionMessage
