import { theme } from "@copa/design-system-factory.theme"

const variants = {
  bodyLarge: {
    fontFamily: "SuisseIntl,sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey["700"],
  },
}

export default variants
