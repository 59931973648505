import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@copa/design-system-factory.typography'
import { Box } from '@mui/material'
import CreditCardDisclaimer from '../../pages/CreateRequestForm/components/CreditCardDisclaimer'
import PaymentMethodBanner from '../../pages/CreateRequestForm/components/PaymentMethodBanner'
import { PAYMENT_METHOD_BY_CODE } from '../../pages/CreateRequestForm/components/PaymentMethodBanner/utils'
import useStyles from './styles'

const extractCardDetails = (rawCardDetails?: string) => {
  const [cardType = '', cardNumber = ''] = rawCardDetails?.split('/') || []
  const methodCode = cardType as keyof typeof PAYMENT_METHOD_BY_CODE
  const cardConfig = PAYMENT_METHOD_BY_CODE[methodCode]
  return {
    methodCode,
    cardNumber: `${cardConfig?.name ?? cardType} ****${cardNumber}`,
  }
}

type PaymentMethodProps = {
  cardDetails: string
  isRedemption?: boolean
}

function PaymentMethod({
  cardDetails,
  isRedemption = false,
}: PaymentMethodProps) {
  const { classes: styles } = useStyles()
  const { formatMessage } = useIntl()

  const { cardNumber, methodCode } = useMemo(
    () => extractCardDetails(cardDetails),
    [cardDetails]
  )

  const marginStyle = {
    marginTop: '16px',
    marginBottom: cardDetails ? undefined : '34px',
  }

  const showCard = !!cardDetails && !isRedemption

  return (
    <section className={styles.renderCreditCardContainer}>
      <Typography variant="h3">
        {formatMessage({
          id: 'additionalInfoPage.titleFOPSection',
        })}
      </Typography>

      {!showCard && !isRedemption && (
        <Typography style={marginStyle}>
          {formatMessage({
            id: 'additionalInfoPage.FOPDescription',
          })}
        </Typography>
      )}

      {showCard && (
        <>
          <Typography style={marginStyle}>
            {formatMessage({
              id: 'additionalInfoPage.FOPDescription',
            })}
          </Typography>
          <PaymentMethodBanner
            testId="credit-card-with-details-test-id"
            text={cardNumber}
            methodCode={methodCode}
            sx={{ margin: '24px 0 34px 0' }}
          />
        </>
      )}
      {isRedemption && (
        <>
          <Typography style={marginStyle}>
            {formatMessage({
              id: 'additionalInfoPage.connectMilesDescription',
            })}
          </Typography>
          <Box className={styles.redemptionMethodRoot}>
            <PaymentMethodBanner
              text={formatMessage({
                id: 'additionalInfoPage.milesTitle',
              })}
              methodCode="MILES"
            />
            <Box className={styles.redemptionMethodSeparator}>
              <Typography sx={{ fontSize: '32px' }}>&#43;</Typography>
            </Box>
            <PaymentMethodBanner
              text={formatMessage({
                id: 'additionalInfoPage.creditCardTitle',
              })}
              methodCode="CREDIT_CARD"
            />
          </Box>
        </>
      )}
      <CreditCardDisclaimer
        message={formatMessage({
          id: 'additionalInfoPage.creditCardDisclaimer',
        })}
      />
    </section>
  )
}

export default PaymentMethod as FC<PaymentMethodProps>
