const styles = {
  container: {
    marginTop: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #DBDAD8",
    borderRadius: "16px",
    padding: { xs: "16px 24px", sm: "16px 40px" },
    gap: "40px",
    cursor: "pointer",
    textDecoration: "none",
    color: "#121212",
    "&:visited": { color: "#121212" },
  },
  subtitle: {
    color: "#121212",
    fontSize: "12px",
    lineHeight: "16px",
  },
  description: {
    fontWeight: 700,
    fontSize: { xs: "18px", sm: "20px" },
    lineHeight: { xs: "24px", sm: "32px" },
  },
  icon: {
    marginTop: "14px",
  },
}

export default styles
