import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  renderCreditCardContainer: {
    marginBottom: '64px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '56px',
    },
  },
  redemptionMethodRoot: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0 34px 0',
    flexDirection: 'row',
    rowGap: 0,
    columnGap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '8px',
      columnGap: 0,
    },
  },
  redemptionMethodSeparator: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '496px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

export default useStyles
