const styles = {
  refundOptionsSection: {
    display: "grid",
    columnGap: 3,
    rowGap: 3,
    gridTemplateColumns: { md: "repeat(2, 1fr)" },
    marginTop: "32px",
  },
  title: {
    color: "primary.main",
    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
    lineHeight: { xs: "24px", sm: "32px", lg: "40px" },
    fontWeight: { xs: 700 },
  },
  subtitle: {
    marginTop: "16px",
    fontSize: { xs: "14px", sm: "16px" },
    lineHeight: { xs: "20px", sm: "24px" },
  },
  otherOptionsSection: {
    marginTop: "56px",
  },
  otherOptionsTitle: {
    color: "primary.main",
    fontSize: { xs: "18px", sm: "20px" },
    lineHeight: { xs: "24px", sm: "32px" },
    fontWeight: { xs: 700 },
  },
}

export default styles
