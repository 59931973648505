import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Typography } from '@copa/design-system-factory.typography'
import { Box } from '@mui/material'
import Header from '../../components/Header'
import Interstitial from '../../components/Interstitial/interstitial'
import { useGetTripHubInfo } from '../../hooks/useAPICall'
import redirectErrorPage from './redirectErrorPage'
import {
  useAppDispatch,
  useAppSelectGeneralInfo,
} from '../../hooks/store-hooks'
import useStoreRefund from '../../hooks/usePersistData'
import PNRHeading from '../../components/PNRHeading'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import { cleanUpSpecialChars, getCodeType } from '../../utils/functions'
import { identifyUser } from '../../utils/logs'
import styles from './styles'
import OtherRefundOption from './components/OtherRefundOption'
import { isAgencyPNR, isRequestedByETicket } from './validations'
import getRefundOptions from './refundOptions'

const CreateRequestPage: FC<{ isAgency?: boolean }> = ({
  isAgency = false,
}) => {
  const [searchParams] = useSearchParams()
  const isManualFlow = searchParams.get('skipRequest')

  const [wasRequested, setWasRequested] = useState(false) // To avoid to send the same request twice
  const generalContext = useAppSelectGeneralInfo()
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const { requestCode, userLastName, source } = generalContext

  const { refundNotFound, isLoading: isRefundLoading } = useStoreRefund({
    requestCode,
    source,
    lastname: userLastName,
    goBackSteps: 2,
  })

  const {
    responseData,
    error,
    isLoading: isTripHubInfoLoading,
  } = useGetTripHubInfo({
    requestCode,
    userLastName: cleanUpSpecialChars(userLastName || ''),
    skip: !!isManualFlow || wasRequested || !refundNotFound,
    loadingInitialState: !isManualFlow,
  })

  const navigate = useNavigate()

  useEffect(() => {
    dispatch({
      isAgency,
      isManualFlow,
      triphubData: responseData,
      isNonRevenue: responseData?.trip?.revenue?.isNonRevenue,
    })
    isRequestedByETicket({
      tripHubResponse: responseData,
      setWasRequested,
      dispatch,
      requestCode,
    })
    isAgencyPNR({
      tripHubResponse: responseData,
      isAgency,
      navigate,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData])

  useEffect(() => {
    redirectErrorPage(error, navigate, isAgency, {
      source,
      requestCodeType: getCodeType(requestCode),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (requestCode && userLastName) {
      identifyUser(requestCode, userLastName)
    }
  }, [requestCode, userLastName])

  if (isRefundLoading || isTripHubInfoLoading) {
    return (
      <Interstitial
        loadingText={formatMessage({ id: 'intersitial.loading' })}
      />
    )
  }
  return (
    <>
      <Header title={formatMessage({ id: 'additionalInfoPage.header' })} />
      <PNRHeading />
      <ResponsiveContainer
        marginTop={{ xs: '55px', sm: '47px' }}
        marginBottom="160px"
      >
        <Box component="section">
          <Typography variant="h2" sx={styles.title}>
            {formatMessage({ id: 'createRequestPage.title' })}
          </Typography>
        </Box>

        <Box
          component="section"
          id="refund-options"
          sx={styles.refundOptionsSection}
        >
          {getRefundOptions({
            formatMessage,
            ticketRefundCTA: () => {
              navigate('/create-refund-ticket')
            },
            paidServiceRefundCTA: () => {
              window.open(
                formatMessage({
                  id: 'createRequestPage.refundPaidServices.notHonoredURL',
                })
              )
            },
          })}
        </Box>

        <Box component="section" sx={styles.otherOptionsSection}>
          <Typography variant="h3" sx={styles.otherOptionsTitle}>
            {formatMessage({ id: 'createRequestPage.otherOptions.title' })}
          </Typography>
          <OtherRefundOption
            href={formatMessage({
              id: 'createRequestPage.otherOptions.doubleChargeURL',
            })}
            subtitle={formatMessage({
              id: 'createRequestPage.otherOptions.subtitle',
            })}
            description={formatMessage({
              id: 'createRequestPage.otherOptions.description',
            })}
            wcag={formatMessage({
              id: 'createRequestPage.wcag.refundDuplicateService',
            })}
          />
        </Box>
      </ResponsiveContainer>
    </>
  )
}

export default CreateRequestPage
