import { formatDate } from "../../../../utils/date"

const getCreateRefundRequestPassengers = (passengers, loadTripPayload) => {
  let result = []

  if (loadTripPayload) {
    const { isNonRevenue } = loadTripPayload.trip.revenue

    result = passengers.map((passenger) => ({
      firstName: passenger.documents?.firstName || passenger.givenName,
      lastName: passenger.lastName,
      birthDate: passenger.documents?.birthday
        ? formatDate(passenger.documents.birthday)
        : "",
      frequentFlyerStatus: passenger.loyalty?.statusConnectMiles,
      passengerType: passenger.typeCode,
      ...(isNonRevenue ? { passengerSubType: passenger.passengerSubType } : {}),
    }))
  } else {
    result = passengers.map((passenger) => ({
      firstName: passenger.givenName,
      lastName: passenger.lastName,
    }))
  }

  return result
}

export default getCreateRefundRequestPassengers
