import { useContext } from "react"
import {
  DispatchGeneralContext,
  GeneralContext,
} from "../contexts/GeneralContextProvider"

export const useAppSelectGeneralInfo = () => useContext(GeneralContext)
export const useAppDispatch = () => {
  const dispatchGeneralContext = useContext(DispatchGeneralContext)

  return function dispatch(data) {
    dispatchGeneralContext({ action: "set", payload: data })
  }
}
