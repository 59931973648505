import {
  AssetType,
  AssetExtension,
  IconCategory,
  PictogramCategory,
} from '../types/assets.types'

const COPA_URL = process.env.REACT_APP_COPA_URL

export const WEBASSET_BASE_URL = `${COPA_URL}/webassets`

export const getAssetURL = (
  name: string,
  {
    type,
    extension = AssetExtension.svg,
    category,
    baseURL = WEBASSET_BASE_URL,
  }: {
    type: AssetType
    category: IconCategory | PictogramCategory
    extension?: AssetExtension
    baseURL?: string
  }
) => `${baseURL}/${type}/${category}/${name}.${extension}`
