import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@copa/design-system-factory.typography'
import { Box } from '@mui/material'
import { Button } from '@copa/design-system-factory.buttons.button'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import useStyles from './style'
import AlertIcon from '../../assets/Icons/Alert'

const ErrorPage: FC<{}> = () => {
  const { formatMessage } = useIntl()
  const { classes: styles } = useStyles()

  function handleRetry() {
    window.location.href = formatMessage({
      id: 'refundConfirmation.buttonURL',
    })
  }

  return (
    <ResponsiveContainer className={styles.container}>
      <AlertIcon />
      <Typography variant="h2" className={styles.title} tabIndex={0}>
        {formatMessage({ id: 'errorPage.title' })}
      </Typography>
      <Box className={styles.subtitle} tabIndex={0}>
        {formatMessage({ id: 'errorPage.subtitle' })}
      </Box>
      <Box className={styles.ctaContainer}>
        <Button
          aria-label={formatMessage({ id: 'errorPageWCAG.cta' })}
          onClick={() => handleRetry()}
          size="large"
          width="100%"
        >
          {formatMessage({ id: 'errorPage.cta' })}
        </Button>
      </Box>
    </ResponsiveContainer>
  )
}

export default ErrorPage
