/* eslint-disable react/prefer-stateless-function */
import React, { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from '../../pages/Error'

type ErrorWrapperProps = {
  children: ReactNode
}

type ErrorWrapperState = {
  hasError: boolean
}

class ErrorWrapper extends Component<ErrorWrapperProps, ErrorWrapperState> {
  constructor(props: ErrorWrapperProps) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <ErrorPage />
    }
    return children
  }
}

export default ErrorWrapper
