import React from 'react'
import FormOfPaymentAR from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentAR'
import FormOfPaymentBR from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentBR'
import FormOfPaymentCA from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentCA'
import FormOfPaymentCO from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentCO'
import FormOfPaymentCUHT from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentCUHT'
import FormOfPaymentEU from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentEU'
import FormOfPaymentGeneric from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentGeneric'
import FormOfPaymentMX from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentMX'
import FormOfPaymentGY from '../../pages/CreateRequestForm/components/FormOfPayment/FormOfPaymentGY'
import CONSTANTS from '../../utils/constants'

function AFOPForms({
  country,
  control,
  formErrors,
  onHandleDocs,
  isNonRevenueOnAutomaticFlow,
}) {
  switch (country) {
    case 'AR':
      return <FormOfPaymentAR control={control} formErrors={formErrors} />
    case 'BR':
      return <FormOfPaymentBR control={control} formErrors={formErrors} />
    case 'CA':
      return <FormOfPaymentCA control={control} formErrors={formErrors} />
    case 'CO':
      return (
        <FormOfPaymentCO
          control={control}
          formErrors={formErrors}
          handleDocs={(file, actionType) =>
            onHandleDocs(
              file,
              actionType,
              CONSTANTS.DOCUMENT_TYPES.BANKING_CERTIFICATE
            )
          }
          isNonRevenueOnAutomaticFlow={isNonRevenueOnAutomaticFlow}
        />
      )
    case 'MX':
      return <FormOfPaymentMX control={control} formErrors={formErrors} />
    case 'CU':
    case 'HT':
      return <FormOfPaymentCUHT control={control} formErrors={formErrors} />
    case 'GY':
      return <FormOfPaymentGY control={control} formErrors={formErrors} />
    case 'AT':
    case 'BE':
    case 'CH':
    case 'CY':
    case 'DE':
    case 'DK':
    case 'ES':
    case 'EU':
    case 'FR':
    case 'GR':
    case 'IT':
    case 'NL':
    case 'NO':
    case 'PT':
    case 'RU':
    case 'SE':
    case 'UK':
      return <FormOfPaymentEU control={control} formErrors={formErrors} />
    default:
      return (
        <FormOfPaymentGeneric
          control={control}
          formErrors={formErrors}
          country={country}
        />
      )
  }
}

export default AFOPForms
