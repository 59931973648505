import { SvgIcon } from '@mui/material'
import React from 'react'

function LeftArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
        <path
          fill="#AAAAAB"
          d="m12.083 3.402 7.917 7.6H1.042v2H20l-7.917 7.6 1.459 1.4 10.416-10-10.416-10-1.459 1.4Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default LeftArrowIcon
