export enum AssetType {
  icon = 'icons',
  pictogram = 'pictogramas',
}

export enum AssetExtension {
  svg = 'svg',
}

export enum IconCategory {
  regular = 'regular',
  payment = 'payment',
  flags = 'flags',
  social = 'social',
}

export enum PictogramCategory {
  viajes = 'viajes',
  funcionales = 'funcionales',
  connectmiles = 'connectmiles',
  equipaje = 'equipaje',
  aeropuerto = 'aeropuerto',
  documentos = 'documentos',
}
