import React, { FC, ReactNode, useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import { Locale } from '../../types/translations.types'

type Props = {
  ariaText: string
  id: string
  ariaTextProps?: string
  title: string
  keywords: string[]
  children: ReactNode
  metaTypeSEO: string
  language?: Locale
  meta?: { name: string; content: string }[]
}

const SeoWrapper: FC<Props> = ({
  children,
  ariaText,
  metaTypeSEO,
  id,
  language,
  title,
  keywords,
  meta = [],
  ariaTextProps: _,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus()
    }
  }, [])

  return (
    <>
      <Helmet
        htmlAttributes={{
          language,
        }}
        title={title}
        // titleTemplate="&s | Copa Airlines"
        meta={[
          {
            name: `description`,
            content: metaTypeSEO,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaTypeSEO,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : []
          )
          .concat(meta)}
      />
      <div id={id} aria-label={ariaText} ref={containerRef} role="contentinfo">
        {children}
      </div>
    </>
  )
}

export default SeoWrapper
