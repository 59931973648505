import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: 10000,
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  animationContainer: {
    maxWidth: "569px",
    margin: "0 auto",
    padding: "0 69px",
    [theme.breakpoints.down("md")]: {
      padding: "0 120px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
}))

export default useStyles
