export default {
  copa_url: {
    es: "https://www.copaair.com/es-gs/atencion-al-cliente/centro-de-llamadas/",
    en: "https://www.copaair.com/en-gs/customer-service/call-center/",
    pt: "https://www.copaair.com/pt-gs/atendimento-ao-cliente/centro-de-chamadas/",
    fr: "https://www.copaair.com/fr-gs/soutien-au-client/centre-dappels/",
  },
  REASON_OPTIONS: {
    VOLUNTARY_CHANGE: "VoluntaryChange",
    INVOLUNTARY_CHANGE: "InvoluntaryChange",
    NATURAL_DISASTER: "NaturalDisaster",
    DEATH: "Death",
    TERMINAL_ILLNESS: "Terminalillness",
    OTHER: "Other",
    IMMIGRATIONISSUES: "ImmigrationIssues",
  },
  DOCUMENT_TYPES: {
    ID_DOCUMENT: "ID DOCUMENT",
    BANKING_CERTIFICATE: "BANKING CERTIFICATE",
    TERMINAL_ILLNESS: "TERMINAL ILLNESS",
    DEATH_DOCUMENTS: "DEATH DOCUMENT",
  },
  ANALYTICS_EVENTS: {
    PAGE_VIEW: "pageview",
    CUSTOM_EVENT: "custom_event",
  },
  NON_REVENUE_ELIGIBILITY: {
    SHOW_CASH_MODAL: 1,
    HIDDE_BANK_INFO: 3,
  },
}
