import LogRocket from "logrocket"

export function setupLogsRecording() {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
    rootHostname: process.env.REACT_APP_DOMAIN_ALLOWED,
    dom: {
      inputSanitizer: true,
    },
  })
}

export function identifyUser(requestCode, lastName) {
  LogRocket.identify(requestCode, {
    name: lastName,
  })
}
