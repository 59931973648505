import React from 'react'

function VisaIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        fill="#1A1F71"
        d="M24 19.148a.889.889 0 0 1-.893.892H.893A.889.889 0 0 1 0 19.148V5.792A.89.89 0 0 1 .893 4.9h22.214a.89.89 0 0 1 .893.892v13.356Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.967 13.594c0-1.018-.843-1.457-1.511-1.806-.44-.229-.803-.418-.803-.706 0-.232.231-.463.694-.53a2.877 2.877 0 0 1 1.62.298l.298-1.355c-.397-.132-.893-.298-1.554-.298-1.62 0-2.777.86-2.777 2.116 0 .926.826 1.421 1.455 1.719.628.297.859.496.859.793 0 .43-.496.629-.992.629-.813 0-1.307-.225-1.669-.39l-.017-.007-.297 1.388c.396.165 1.09.33 1.818.33 1.719 0 2.843-.859 2.876-2.181ZM9.124 9.296l-.014.034H7.372l-1.719 4.33-.694-3.702a.748.748 0 0 0-.76-.662h-2.81l-.034.199c.562.099 1.224.33 1.62.529.232.132.33.264.397.562l1.322 5.09h1.752L9.11 9.33h.014v-.033Zm12.661 6.38h-1.52l-.232-.958h-2.116l-.33.959h-1.72l2.48-5.918a.73.73 0 0 1 .694-.463h1.422l1.322 6.38Zm-3.372-2.248.86-2.38.496 2.38h-1.356Zm-8.297 2.249 1.355-6.38H9.818l-1.355 6.38h1.653Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default VisaIcon
