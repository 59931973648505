/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import React, { FC, useEffect } from 'react'
import { Grid, Box } from '@mui/material'
import {
  useForm,
  useFieldArray,
  Controller,
  useFormState,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { theme } from '@copa/design-system-factory.theme'
import InputAdornment from '@mui/material/InputAdornment'
import CheckIcon from '@mui/icons-material/Check'
import { Button } from '@copa/design-system-factory.buttons.button'
import { InputRegular } from '@copa/design-system-factory.input-regular'
import { Typography } from '@copa/design-system-factory.typography'
import AddIcon from '@mui/icons-material/Add'
import Header from '../../components/Header'
import Heading from '../../components/Heading/Heading'
import useStyles from './styles'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import VALIDATIONS from '../../utils/validations'
import Passenger from './components/Passenger'
import { useAppDispatch } from '../../hooks/store-hooks'

type Props = {
  isAgency?: boolean
}

type FromValue = {
  name: string
  reservationCode?: string
  addPassenger: {
    id?: string
    givenName: string
    lastName: string
    eTicket: string
  }[]
}

const AddPassengersPage: FC<Props> = ({ isAgency = false }) => {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const { classes: styles } = useStyles()
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    clearErrors,
    watch,
  } = useForm<FromValue>({
    defaultValues: {
      addPassenger: [{ givenName: '', lastName: '', eTicket: '' }],
    },
    mode: 'all',
  })
  const { isValid } = useFormState({ control })
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'addPassenger',
  })
  const { formatMessage } = useIntl()

  useEffect(() => {
    // set the flow to manual
    dispatch({ flow: 'manual' })
  }, [dispatch])

  const handleAddPassenger = () => {
    insert(parseInt('10', 10), {
      id: uuid(),
      givenName: '',
      lastName: '',
      eTicket: '',
    })
  }

  const handleFormSubmit = (data: FromValue) => {
    const passengers = data.addPassenger
    const trip = {
      passengers,
      requestCode: data.reservationCode,
      userLastName: passengers?.[0]?.lastName,
    }
    const createRequestPage = isAgency
      ? 'agency/create-request'
      : 'create-request'
    dispatch(trip)
    history(
      `/${createRequestPage}?request_code=${data.reservationCode}&last_name=${passengers?.[0]?.lastName}&skipRequest=true`
    )
  }

  return (
    <>
      <Header title={formatMessage({ id: 'addPassengersPage.title' })} />
      <Heading
        titleComponent={
          <Typography variant="h2" sx={{ color: theme.palette.primary.dark }}>
            {formatMessage({ id: 'addPassengersPage.subtitle' })}
          </Typography>
        }
        textComponent={
          <Typography variant="body2" sx={{ marginTop: '8px' }}>
            {formatMessage({ id: 'addPassengersPage.descriptionText' })}
          </Typography>
        }
        style={{ paddingTop: '32px', minHeight: '140px' }}
      />
      <ResponsiveContainer marginTop="50px">
        <form>
          <Grid container spacing={2} marginBottom="20px">
            <Grid item md={6} sm={12} xs={12}>
              <Controller
                control={control}
                name="reservationCode"
                rules={VALIDATIONS.RESERVATION_CODE}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputRegular
                    id="reservationCode"
                    label={formatMessage({
                      id: 'addPassengersPage.inputReservation',
                    })}
                    placeholder={formatMessage({
                      id: 'addPassengersPage.placeholderReservation',
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value && !formErrors.reservationCode?.type && (
                            <CheckIcon
                              style={{
                                color: theme.palette.grey['400'],
                                fontSize: '18px',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      formErrors.reservationCode &&
                      formatMessage({
                        id: 'callCenterSpecialLink.invalidFormatMessage',
                      })
                    }
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      maxLength: 6,
                      'aria-label': formatMessage({
                        id: 'addPassengersPageWCAG.reservationCode',
                      }),
                    }}
                    value={value}
                    handleChange={onChange}
                    onBlur={onBlur}
                    hasErrors={!!formErrors.reservationCode}
                  />
                )}
              />
            </Grid>
          </Grid>
          {fields.map((passenger, index) => (
            <Passenger
              key={passenger.id}
              passengerNumber={`addPassenger.${index}`}
              form={{ control, formErrors, watch, clearErrors }}
              index={index}
              remove={remove}
              fields={fields.length}
            />
          ))}
          <Box className={styles.nextContainer}>
            <Box>
              <Button
                disabled={fields.length === 9}
                aria-label={formatMessage({
                  id: 'addPassengersPageWCAG.addPaxButton',
                })}
                variant="outlinePrimaryMain"
                onClick={handleAddPassenger}
                width="100%"
              >
                <AddIcon sx={{ marginRight: '8px' }} />
                {formatMessage({ id: 'addPassengersPage.addPaxButton' })}
              </Button>
            </Box>
          </Box>
          <Box className={styles.submitContainer}>
            <Button
              aria-label={formatMessage({ id: 'addPassengersPageWCAG.cta' })}
              disabled={!isValid}
              onClick={() => {
                handleSubmit(handleFormSubmit)()
              }}
              size="large"
            >
              {formatMessage({ id: 'addPassengersPage.cta' })}
            </Button>
          </Box>
        </form>
      </ResponsiveContainer>
    </>
  )
}

export default AddPassengersPage
