import theme from '@copa/design-system-factory.theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  bulletLitContainer: { marginTop: '8px', paddingLeft: '16px' },
  bulletListElement: {
    color: theme.palette.grey[600],
  },
}

export default styles
