import { Box } from "@mui/material"
import React, { FC, ReactNode } from "react"
import { MUISxProperty } from "../../types/styles.types"

type Props = {
  id?: string
  children: ReactNode
  className?: string
  marginTop?: MUISxProperty<string>
  marginBottom?: MUISxProperty<string>
}

const ResponsiveContainer: FC<Props> = ({
  id,
  children,
  marginTop,
  marginBottom,
  ...props
}) => {
  const styles = {
    padding: {
      xs: "0px 16px 0px 16px",
      sm: "0px 104px 0px 104px",
      md: "0px 169px 0px 169px",
      lg: "0px 212px 0px 212px",
    },
    marginTop,
    marginBottom,
  }

  return (
    <Box id={id} sx={styles} {...props}>
      {children}
    </Box>
  )
}

export default ResponsiveContainer
