import React from "react"
import PropTypes from "prop-types"
import Lottie from "lottie-react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  root: {
    position: "absolute",
    zIndex: -1,
  },
}))

function AnimationDisplayer({
  config,
  autoplay,
  loop,
  wcagDescription,
  ...props
}) {
  const { classes } = useStyles()
  return (
    <div role="alert" aria-live="assertive">
      <span className={classes.root}>{wcagDescription}</span>
      <Lottie
        animationData={config}
        autoPlay={autoplay}
        loop={loop}
        {...props}
      />
    </div>
  )
}

AnimationDisplayer.propTypes = {
  config: PropTypes.shape().isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  wcagDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

AnimationDisplayer.defaultProps = {
  autoplay: true,
  loop: true,
  wcagDescription: "",
}

export default AnimationDisplayer
