export default {
  'inputErrorMessage.pnrNotFound': "Nous n'avons pas trouvé votre réservation",
  'createRequestPage.fateInfo':
    'https://www.copaair.com/fr-gs/information-de-voyage/familles-tarifaires/',

  //  CREATE REQUEST PAGE
  'createRequestPage.title': 'Que voulez-vous rembourser ?',
  'createRequestPage.refundTicket.title': 'Remboursement du billet',
  'createRequestPage.refundTicket.description':
    "Si vos plans de voyage ont changé et que vous devez demander le remboursement de votre billet, vous pouvez ici demander l'annulation de votre itinéraire et obtenir le remboursement de votre billet.",
  'createRequestPage.refundTicket.cta': 'Demande de remboursement',
  'createRequestPage.refundPaidServices.title':
    'Remboursement des services supplémentaires',
  'createRequestPage.refundPaidServices.description':
    'Les frais de bagages, les surclassements et les sièges achetés' +
    " ne sont pas remboursables. Si le service n'a pas été fourni en raison de la responsabilité de la" +
    ' compagnie aérienne, vous pouvez demander un remboursement.',
  'createRequestPage.refundPaidServices.cta': 'Demande de remboursement',
  'createRequestPage.otherOptions.title': 'Autres options',
  'createRequestPage.otherOptions.subtitle':
    'Vous avez été facturé deux fois pour la même transaction ?',
  'createRequestPage.otherOptions.description':
    "Les billets d'avion et autres services liés à votre voyage ont été " +
    'facturés en double ou de manière incorrecte.',
  'createRequestPage.refundPaidServices.notHonoredURL':
    'https://help.copaair.com/hc/en-us/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=___dc.servicios_adicionales_pagados_no_honrados__&ticket_form_id=360004175854',
  'createRequestPage.otherOptions.doubleChargeURL':
    'https://help.copaair.com/hc/en-us/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=__dc.cobro_duplicado_o_incorrecto__&ticket_form_id=360004175854',

  //  CREATE REQUEST PAGE WCAG
  'createRequestPage.wcag.pageDescription':
    'À travers cette page, vous pourrez sélectionner le type de remboursement' +
    ' que vous souhaitez effectuer.',
  'createRequestPage.wcag.refundRequestBtn':
    'Cliquez pour demander un remboursement de billet.',
  'createRequestPage.wcag.refundPaidServiceBtn':
    'Cliquez pour demander un remboursement de services supplémentaires.' +
    " Une nouvelle page s'ouvrira pour demander ce remboursement.",
  'createRequestPage.wcag.refundDuplicateService':
    'Appuyez pour demander un remboursement pour des frais incorrects ' +
    "ou en double pour des billets d'avion et d'autres services liés à votre voyage. Une nouvelle page s'ouvrira" +
    ' pour demander ce remboursement.',

  //  CHECK STATUS PAGE WCAG
  'checkStatusPageWCAG.container':
    'Pour consulter votre demande de remboursement, vous aurez besoin de votre code de' +
    ' réservation, de votre numéro de billet ou de votre numéro de dossier. Entrez-les dans les champs ci-dessous,' +
    ' puis appuyez sur Rechercher la demande',

  //  CHECK STATUS PAGE
  'checkStatusPage.header': "Vérifier l'état de la demande",
  'checkStatusPage.title': 'Vérifier une demande de remboursement',
  'checkStatusPage.subtitle':
    'Saisissez le numéro de dossier de votre demande.',
  'checkStatusPage.ticketLabel':
    'Numéro de dossier, code de réservation ou numéro de billet',
  'checkStatusPage.ticketPlaceholder': 'RFD-WEB1234567',
  'checkStatusPage.ticketHelperText':
    'Le numéro de dossier doit comporter 13 ou 14 chiffres et commencer par RFD',
  'checkStatusPage.lastnameLabel': 'Nom de famille',
  'checkStatusPage.lastnamePlaceholder': 'Saisissez le nom de famille',
  'checkStatusPage.cta': 'Rechercher demande',
  'checkStatusPage.footerTitle': 'Où trouver mon numéro de dossier ?',
  'checkStatusPage.footerBullet':
    'Une fois votre demande de remboursement terminée, nous vous envoyons un e-mail ; Dans cet e-mail, vous trouverez votre numéro de dossier, il est composé de 13 ou 14 chiffres et commence par RFD (par exemple RFD-WEB1234567).',
  'checkStatusPage.alert':
    "Cette fonctionnalité n'est disponible que pour les demandes effectuées après le 25 août 2022.",
  'pageHeader.description':
    "Options de remboursement et d'annulation pour les passagers de Copa Airlines",
  'errorAgency.title':
    "Pour demander un remboursement, veuillez contacter l'agence de voyage ou la société auprès de laquelle vous avez effectué cette réservation.",
  'errorAgency.subTitle':
    "Cette réservation a été achetée par l'intermédiaire d'un tiers (par exemple, une agence de voyage, un site Web de voyage). Pour en demander le remboursement, vous devez le faire par leur intermédiaire. Si la société tierce a déjà confirmé qu'il n'est pas possible de traiter le remboursement par son intermédiaire, veuillez nous contacter notre ",
  'errorAgency.link': 'Centre de Réservation ',
  'errorAgency.continue': "pour plus d'attention.",
  'errorAgency.button': 'Regresar a copa.com',
  'errorAgency.WCAGbutton': 'Regresar a Copa.com',
  'errorAgency.WCAGContainer':
    "Nous ne pouvons pas traiter votre remboursement, car cette réservation a été achetée par l'intermédiaire d'un tiers (Exemple : Agence de voyage, Page de voyage). Vous devez contacter l'entreprise avec laquelle vous avez effectué l'achat pour demander un remboursement.",
  'additionalInfoPage.header': 'Demande à être remboursé',
  'formOfPayment.title':
    'Coordonnées bancaires pour effectuer un remboursement',
  'formOfPayment.bankName': 'Nom de banque',
  'formOfPayment.placeholderBankName': 'Entrez le nom de la banque',
  'formOfPayment.accountHolder': 'Titulaire de compte',
  'formOfPayment.placeholderAccountHolder': 'Saisir le bénéficiaire',
  'formOfPayment.accountType': 'Type de compte',
  'formOfPayment.placeholderAccountType': 'Sélectionnez le type de compte',
  'formOfPayment.savingsAccount': 'Compte épargne',
  'formOfPayment.checkingAccount': 'Compte courant',
  'formOfPayment.accountNumber': 'Numéro de compte',
  'formOfPayment.placeholderAccountNumber': 'Entrez le numéro de compte',
  'formOfPayment.cpfCpnj': 'CPF / CPNJ',
  'formOfPayment.placeholderCpfCpnj': 'Entrez le nom de la banque',
  'formOfPayment.bankCode': 'Code bancaire',
  'formOfPayment.placeholderBankCode': 'Entrez le code bancaire à',
  'formOfPayment.agency': 'Agence',
  'formOfPayment.placeholderAgency': "Entrez le numéro d'agence",
  'formOfPayment.interbankKey': 'Clé interbancaire',
  'formOfPayment.placeholderInterbankKey': 'Entrez la clé interbancaire',
  'formOfPayment.swiftcode': 'Numéro de Routing (ABA/Swiftcode)',
  'formOfPayment.placeholderSwiftcode': 'Entrer le numéro de routing',
  'formOfPayment.rfc': 'RFC',
  'formOfPayment.placeholderRfc': 'Entrez RFC',
  'formOfPayment.cuitCuil': 'CUIT / CUIL',
  'formOfPayment.placeholderCuitCuil': 'Entrez CUIT / CUIL',
  'formOfPayment.cbu': 'CBU',
  'formOfPayment.placeholderCbu': 'Entrez CBU',
  'formOfPayment.bankingCertification': 'Attestation bancaire',
  'formOfPayment.placeholderBankingCertification': 'Entrez Attestation',
  'formOfPayment.IDNumber': "Numéro d'identification",
  'formOfPayment.placeholderIDNumber': 'Entrez identification',
  'formOfPayment.CCI': 'Code de compte interbancaire ou CCI',
  'formOfPayment.placeholderCCI': 'Entrez code',
  'formOfPayment.interbankAccountNumber': 'Numéro de compte interbancaire',
  'formOfPayment.placeholderInterbankAccountNumber': 'Entrez numéro de compte',
  'formOfPayment.NIT': "Numéro d'identification ou NIT",
  'formOfPayment.placeholderNIT': "Numéro d'identification ",
  'formOfPayment.phoneNumber': 'Téléphone',
  'formOfPayment.placeholderPhoneCode': 'Code du pays',
  'formOfPayment.placeholderPhoneNumber': 'Entrez numéro',
  'formOfPayment.address': 'Adresse',
  'formOfPayment.placeholderAddress': 'Entrez adresse',
  'formOfPayment.RUC': "Numéro d'identification ou RUC",
  'formOfPayment.placeholderRUC': "Numéro d'identification ",
  'formOfPayment.transit': 'Numéro de transit',
  'formOfPayment.placeholderTransit': 'Entrez Numéro de transit',
  'formOfPayment.iban': 'IBAN',
  'formOfPayment.placeholderIban': 'Entrez IBAN',
  'formOfPayment.branch': 'Succursale',
  'formOfPayment.placeholderBranch': 'Entrez Succursale',
  'formOfPayment.passport': "Numéro d'identification ou passseport",
  'formOfPayment.placeholderPassport': "Numéro d'identification",
  'refundConfirmation.header': 'Statut du remboursement',
  'refundConfirmation.heading': 'Code de réservation',
  'refundConfirmation.passengerDisclaimer':
    "Nous commencerons à évaluer votre candidature dans les 21 prochains jours. Une fois traitée, cela peut prendre jusqu'à deux relevés pour que votre demande soit traitée.",
  'refundConfirmation.title':
    'Demande de remboursement envoyée avec succès (En attente de validation)',
  'refundConfirmation.caseDateHeader': 'Date de candidature :',
  'refundConfirmation.caseDate': '15 février 2022',
  'refundConfirmation.caseStatus': 'Statut actuel:',
  'refundConfirmation.case': 'Dossier: ',
  'refundConfirmation.new': 'Reçu',
  'refundConfirmation.evaluation': "En cours d'examen",
  'refundConfirmation.escalated': 'En attente',
  'refundConfirmation.approved': 'Approuvé',
  'refundConfirmation.closed': 'Clôturé',
  'refundConfirmation.button': 'Demander un autre remboursement',
  'refundConfirmation.title2': 'Suivant?',
  'refundConfirmation.subtitle2':
    "Vous pouvez vérifier l'état de votre demande via <cta>copa.com</cta>, dans la section" +
    ` remboursement et en sélectionnant l'option "Vérifier l'état".`,
  'refundConfirmation.subtitle3':
    "Votre demande peut avoir l'un des statuts suivants :",
  'refundConfirmation.paragraph1':
    '<b>"Reçu"</b>: Statut initial attribué lors de la création de la demande sur le formulaire.',
  'refundConfirmation.paragraph2':
    '<b>"En cours d\'examen"</b>: la demande est en cours d\'examen par notre équipe de remboursement.',
  'refundConfirmation.paragraph3':
    '<b>"En attente"</b>: la demande nécessite des informations supplémentaires de la part du passager/demandeur pour être validée par notre équipe de remboursement.',
  'refundConfirmation.paragraph4':
    '<b>"Clôturé"</b>: Nous avons répondu à la demande par courrier électronique.',
  'refundConfirmation.buttonURL':
    'https://www.copaair.com/fr-gs/soutien-au-client/echange-et-remboursement',
  'refundStatus.title': 'État de la candidature',
  'refundStatus.description':
    "Cette réservation a déjà une ou plusieurs demandes de remboursement ouvertes. Vérifiez l'état des candidatures sur cette page.",
  'generalLayoutPage.title1': "Nous n'avons pas trouvé votre réservation",
  'generalLayoutPage.bullet1':
    "Assurez-vous d'utiliser un code de réservation Copa Airlines, qui est un code alphanumérique à" +
    " 6 chiffres (c'est-à-dire ABC123))",
  'generalLayoutPage.bullet2':
    "Nous vous recommandons de localiser votre réservation en utilisant le numéro de billet de l'un des passagers." +
    " (c'est-à-dire 2301234567890)",
  'generalLayoutPage.bullet3':
    "Si vous avez acheté votre réservation par l'intermédiaire d'une agence de voyage, veuillez la contacter " +
    'pour demander un remboursement.',
  'generalLayoutPage.bullet4':
    "Si votre réservation date de plus d'un an, vous devez sélectionner l'option " +
    '"Ouvrir un dossier avec des informations sur les passagers" en bas de la page.',
  'generalLayoutPage.subtitle1': 'Saisissez le informations de réservation',
  'generalLayoutPage.field1': 'Code de réservation ou numéro de billet',
  'generalLayoutPage.helperText1': 'Saisissez le code de réservation',
  'generalLayoutPage.field2': 'Nom de famille',
  'generalLayoutPage.helperText2': 'Saisissez le nom de famille',
  'generalLayoutPage.errorAlertMessage':
    "Nous n'avons pas eu cette réservation",
  'generalLayoutPage.button': 'Rechercher une réservation',
  'generalLayoutPage.title2': 'Que puis-je faire de plus?',
  'generalLayoutPage.subtitle2':
    'Ouvrir un dossier avec des informations sur les passagers',
  'generalLayoutPage.passengerDisclaimer':
    'Dans certains cas, vos informations de réservation peuvent ne plus être accessibles au système. Dans ces cas, vous devrez faire remonter un dossier via cette option.',
  'generalLayoutPage.required': 'Ce champ est obligatoire',
  'generalLayoutPage.Invalid': 'Format invalide',
  'additionalInfoPage.heading': 'Code de réservation',
  'additionalInfoPage.headingNonRevenue':
    'Code de réservation - Billet Collaborateur',
  'additionalInfoPage.pageSection': 'Informations supplémentaires requises',
  'additionalInfoPage.titlePassengerSection':
    "La demande de remboursement s'appliquera à tous les passagers ci-dessous :",
  'additionalInfoPage.disclaimerPassenger':
    'Si vous souhaitez demander un remboursement pour certains passagers, vous devrez nous contacter notre Centre de Réservation pour les séparer de la réservation et pouvoir revenir en faire la demande.',
  'additionalInfoPage.titleRequesterSection':
    'Information relative aux demandeurs',
  'additionalInfoPage.descriptionText':
    'Nous avons besoin de vos coordonnées pour procéder à la demande de remboursement. Ces informations ne seront utilisées que pour vous contacter dans le cadre de la demande.',
  'additionalInfoPage.inputName': 'Prénom',
  'additionalInfoPage.helperTextName': 'Saisissez le prénom',
  'additionalInfoPage.inputLastname': 'Nom de famille',
  'additionalInfoPage.helperTextLastname': 'Saisissez le nom de famille',
  'additionalInfoPage.inputEmail': 'Adresse électronique',
  'additionalInfoPage.helperTextEmail': "Saisissez l'adresse électronique",
  'additionalInfoPage.inputConfirmEmail': "Confirmez l'adresse électronique",
  'additionalInfoPage.helperTextConfirmEmail':
    "Saisissez à nouveau l'adresse électronique",
  'additionalInfoPage.confirmError': 'Les e-mails ne correspondent pas',
  'additionalInfoPage.phone1': 'Numéro de téléphone',
  'additionalInfoPage.phone2': 'Numéro de téléphone',
  'additionalInfoPage.samePhone': 'Entrez un autre numéro de téléphone',
  'additionalInfoPage.inputPhoneCode': 'Code du pays',
  'additionalInfoPage.inputPhone': 'Numéro de téléphone',
  'additionalInfoPage.helperTextPhone': 'Saisissez un numéro',
  'additionalInfoPage.titleFOPSection': 'Mode de paiement original',
  'additionalInfoPage.FOPDescription':
    "Cette réservation a été achetée avec la carte de crédit suivante. Si la demande est approuvée, nous l'utiliserons pour effectuer le remboursement.",
  'additionalInfoPage.connectMilesDescription':
    'Si la demande est approuvée, nous effectuerons le remboursement sur le mode de paiement original et créditerons à nouveau les miles sur le compte ConnectMiles.',
  'additionalInfoPage.FOPNumber':
    "Cette réservation a été achetée avec une carte de crédit. Si la demande est approuvée, nous l'utiliserons pour effectuer le remboursement.",
  'additionalInfoPage.creditCardTitle': 'Carte de crédit',
  'additionalInfoPage.milesTitle': 'Miles',
  'additionalInfoPage.creditCardDisclaimer':
    'Si cette carte a expiré, lors du remboursement, nous vous contacterons pour mettre à jour les informations.',
  'additionalInfoPage.titleRefundReason': 'Raison de votre remboursement',
  'additionalInfoPage.refundSubtitle':
    "Sélectionnez le motif de votre demande de remboursement. Nous pouvons avoir besoin de vous pour fournir des documents supplémentaires. Sélectionnez ci-dessous la raison qui s'applique le mieux à votre cas et suivez les étapes.",
  'additionalInfoPage.inputRefund': 'Raison de votre demande',
  'additionalInfoPage.helperTextRefund':
    'Sélectionnez le motif de votre demande',
  'additionalInfoPage.refundReasonVoluntaryChange':
    'Je souhaite annuler selon conditions tarifaires',
  'additionalInfoPage.refundReasonInvoluntaryChange':
    "Annulation ou changement d'itinéraire effectuée par Copa Airlines",
  'additionalInfoPage.refundReasonNaturalDisaster': 'Désastre naturel',
  'additionalInfoPage.refundReasonDeath': 'Décès',
  'additionalInfoPage.refundReasonTerminalillness':
    'Maladie critique ou terminale',
  'additionalInfoPage.refundReasonOther': 'Autre',
  'additionalInfoPage.refundReasonImmigrationIssues': "Problèmes d'immigration",
  'additionalInfoPage.titleSelectedReason': 'Motif: ',
  'additionalInfoPage.descriptionFareConditions':
    'Votre demande sera traitée selon les conditions de la famille tarifaire achetée. ',
  'additionalInfoPage.descriptionFareConditionsURL':
    'En savoir plus sur nos familles de tarifs.',
  'additionalInfoPage.descriptionDeath':
    'Pour ces cas, il est nécessaire de joindre le certificat de décès du passagers. Vous pouvez joindre les documents nécessaires ci-dessous.',
  'additionalInfoPage.description Natural Disaster':
    "S'applique dans les cas où une catastrophe naturelle (par exemple, une tempête, un tremblement de terre, une éruption volcanique) affecte de manière significative une ville ou une région liée à votre itinéraire.",
  'additionalInfoPage.descriptionCopaAffectedMyTrip':
    "Cela ne s'applique que si l'un de vos vols a été modifié par Copa en raison d'un avancement, de retards, d'annulations ou d'opérations irrégulières. A défaut, votre demande sera rejetée.",
  'additionalInfoPage.descriptionMigratoryIssues':
    "S'applique si vous n'avez pas pu effectuer le voyage parce que vous n'avez pas satisfait aux exigences d'immigration du pays de destination.",
  'additionalInfoPage.descriptionTerminalDisease':
    "Pour ces cas, il est nécessaire de joindre une lettre (sur papier à en-tête) d'un médecin agréé confirmant que le voyage n'était pas recommandé pendant la validité du billet (un an après la date d'émission du billet) en raison d'une maladie.",
  'additionalInfoPage.descriptionOther':
    'Veuillez expliquer la situation plus en détail.',
  'additionalInfoPage.requesterIdUploadDocumentsTitle': 'Joindre des documents',
  'additionalInfoPage.uploadDocumentsTitle': 'Joindre des documents',
  'additionalInfoPage.uploadDocumentsFormats':
    'Documents autorisés : .pdf, .jpg, .png',
  'additionalInfoPage.uploadDocumentsSize': 'Taille maximale: 4 Mo',
  'additionalInfoPage.uploadDocumentsHelper1':
    'Glissez les fichiers ici ou cliquez pour télécharger',
  'additionalInfoPage.uploadDocumentsHelper2':
    'Déposez ici pour télécharger les fichiers',
  'additionalInfoPage.uploadDocumentsErrorFormat':
    "Ce format de fichier n'est pas autorisé.",
  'additionalInfoPage.uploadDocumentsErrorLimit':
    "Vous ne pouvez pas ajouter d'autres fichiers.",
  'additionalInfoPage.uploadDocumentsErrorSize':
    'La taille du fichier dépasse la limite autorisée.',
  'additionalInfoPage.uploadDocumentsLoading': 'Chargement',
  'additionalInfoPage.uploadDocumentsUploadError': 'Erreur lors du chargement',
  'additionalInfoPage.uploadDocumentsGeneralError':
    "Certains fichiers n'ont pas pu être chargés. Veuillez essayer à nouveau.",
  'additionalInfoPage.uploadDocumentsErrorRequired':
    'Vous devez joindre un document.',
  'additionalInfoPage.checkbox1':
    "Je déclare avoir le pouvoir de demander ce remboursement. Je comprends et accepte que l'approbation de mon remboursement est soumise aux réglementations tarifaires.",
  'additionalInfoPage.checkbox2':
    "J'autorise librement, expressément et sans équivoque Copa Airlines à collecter, utiliser et traiter les informations saisies ici pour traiter ma demande de remboursement. J'autorise également le transfert de mes informations à des sociétés affiliées et à des tiers autorisés si nécessaire pour effectuer le remboursement. Je déclare, reconnais et accepte les politiques de confidentialité de l'entreprise.",
  'additionalInfoPage.button': 'Continuer',
  'additionalInfoPage.passengerDisclaimer1':
    'Si vous souhaitez demander un remboursement pour certains passagers, vous devrez nous contacter notre  ',
  'additionalInfoPage.passengerDisclaimerURL': 'Centre de Réservation',
  'additionalInfoPage.passengerDisclaimer2':
    ' pour les séparer de la réservation et pouvoir revenir en faire la demande.',
  'additionalInfoPage.relationship': 'Relation avec les passagers',
  'additionalInfoPage.relationshipPlaceholder': 'Choisir',
  'additionalInfoPage.relationshipOther': 'Décrivez votre relation',
  'additionalInfoPage.relationshipOtherPlaceholder': 'Décrivez votre relation',
  'additionalInfoPage.relationshipOption1':
    'Famille directe (parent, enfant ou conjoint)',
  'additionalInfoPage.relationshipOption2': "Je suis l'un des passagers",
  'additionalInfoPage.relationshipOption3': 'Autre',
  'warningModalLayout.header':
    'En poursuivant la demande, votre itinéraire sera annulé. Voulez-vous continuer?',
  'warningModalLayout.passengerDisclaimer':
    "Dès confirmation de votre demande de remboursement, tous les vols de votre réservation seront immédiatement annulés pour tous les passagers. Si vous changez d'avis et souhaitez réactiver votre réservation, nous ne pouvons garantir la disponibilité de vos vols actuels. La réactivation dépendra de la disponibilité à ce moment-là.",
  'warningModalLayout.passengerDisclaimerNonRevenue':
    "Vous avez un billet collaborateur. L'annulation de vos vols entraînera le remboursement qui sera émis à Copa Airlines et tous vos vols seront annulés.",
  'warningModalLayout.title': 'Demandeur de remboursement',
  'warningModalLayout.fullName': 'Nom complet',
  'warningModalLayout.email': 'E-mail',
  'warningModalLayout.phone': 'Numéro de téléphone',
  'warningModalLayout.reason': 'Raison de votre demande',
  'warningModalLayout.passengersTitle': 'Passagers demandant un remboursement',
  'warningModalLayout.passengeName': 'Nom',
  'warningModalLayout.dateOfBirth': 'Date de naissance',
  'warningModalLayout.eTicket': 'Numéro de billet',
  'warningModalLayout.button1': 'Retourner',
  'warningModalLayout.button2': 'Demande de remboursement',

  // ADD PASSENGERS PAGE
  'addPassengersPage.title': 'Demande à être remboursé',
  'addPassengersPage.subtitle': 'Ajouter des passagers',
  'addPassengersPage.descriptionText':
    'Veuillez saisir les informations de tous les passagers dans la réservation pour demander un remboursement. La demande sera faite pour tous les passagers de la réservation.',
  'addPassengersPage.paxTitle': 'Passager',
  'addPassengersPage.inputReservation': 'Code de réservation',
  'addPassengersPage.placeholderReservation':
    'Saisissez le code de réservation',
  'addPassengersPage.inputName': 'Prénom',
  'addPassengersPage.placeholderName': 'Saisissez le prénom',
  'addPassengersPage.inputLastname': 'Nom de famille',
  'addPassengersPage.placeholderLastname': 'Saisissez le nom de famille',
  'addPassengersPage.addPaxButton': 'Ajouter un passager',
  'addPassengersPage.inputTicketCode': 'Numéro de billet',
  'addPassengersPage.placeholderTicketCode': 'Saisissez le numéro du billet',
  'addPassengersPage.helperTicketCode': 'Le numéro de billet commence par 230',
  'addPassengersPage.emdErrorScenario':
    'Format invalide. Utilisez le numéro de billet électronique de votre achat.',
  'addPassengersPage.errorTicketCode':
    'Format invalide. Le billet doit être composé de 13 chiffres et commencer par 230.',
  'addPassengersPage.cta': 'Continuez',
  'addPassengersPage.remove': 'Retirer',
  'addPassengersPage.alertError':
    'Une demande de remboursement existe déjà pour ce numéro de billet. Ceci est votre numéro de dossier {caseNumber}, vous pouvez vérifier le statut ',
  'addPassengersPage.alertErrorLink': 'ici.',
  'addPassengersPage.caseAlreadyOpenLabelError':
    'Une demande de remboursement existe déjà pour ce numéro de billet. ',

  // CALL CENTER SPECIAL LINK
  'callCenterSpecialLink.title': 'Rechercher une réservation',
  'callCenterSpecialLink.description':
    'Saisissez le code de réservation ou le numéro de billet et le nom de famille pour effectuer la demande de remboursement.',
  'callCenterSpecialLink.subtitle': 'Saisissez le informations de réservation',
  'callCenterSpecialLink.requiredMessage': 'Ce champ est obligatoire',
  'callCenterSpecialLink.invalidFormatMessage': 'Format invalide',
  'callCenterSpecialLink.button': 'Rechercher une réservation',
  'callCenterSpecialLink.moreOptionTitle': 'Que puis-je faire de plus?',
  'callCenterSpecialLink.moreOptionSubtitle':
    'Ouvrir un dossier avec des informations sur les passagers',
  'callCenterSpecialLink.moreOptionBody':
    'Dans certains cas, vos informations de réservation peuvent ne plus être accessibles au système. Dans ces cas, vous devrez faire remonter un dossier via cette option.  ',

  //  EXCEPTIONS PAGE
  'exceptionsPage.title':
    'Désolé, votre réservation ne remplit pas les conditions pour ouvrir une demande de remboursement',
  'exceptionsPage.subtitle': 'Ce sont les raisons possibles :',
  'exceptionsPage.bullet1': 'Votre réservation est en attente de paiement.',
  'exceptionsPage.bullet2': 'Votre réservation est pour les groupes.',
  'exceptionsPage.bullet3':
    'Votre réservation a été achetée avec des miles, est associée à un membre ConnectMiles ou PreferMember Silver, et est soumise à des frais de pénalité.',
  'exceptionsPage.bullet4':
    'Votre itinéraire comporte des vols opérés par une autre compagnie aérienne.',
  'exceptionsPage.bullet5': 'Votre réservation a été annulée.',
  'exceptionsPage.bullet6': 'Votre réservation a été remboursée.',
  'exceptionsPage.bullet7': 'Vous avez terminé votre itinéraire de voyage',
  'exceptionsPage.bullet8':
    "Votre réservation a été annulée en raison d'une réclamation faite par la banque.",
  'exceptionsPage.cta': 'Retour à copa.com',

  //  REASON SECTION PAGE
  'reasonSectionPage.title': 'Raison de votre remboursement',
  'reasonSectionPage.subtitle':
    'Sélectionnez le motif de votre demande de remboursement. Nous pouvons avoir besoin de vous pour fournir des documents supplémentaires.' +
    " Sélectionnez ci-dessous la raison qui s'applique le mieux à votre cas et suivez les étapes.",
  'reasonSectionPage.field1': 'Raison de votre demande',
  'reasonSectionPage.placeholder':
    'Sélectionnez le motif de votre demande de remboursement.',
  'reasonSectionPage.invalidFormatError': 'Format invalide',
  'reasonSectionPage.isRequiredError': 'Ce champ est obligatoire',
  'reasonSectionPage.refundReasonOption1':
    'Je souhaite annuler selon les conditions de mon billet',
  'reasonSectionPage.refundReasonOption2':
    'Copa Airlines a modifié involontairement mon itinéraire de vol.',
  'reasonSectionPage.refundReasonOption3': 'Désastre naturel',
  'reasonSectionPage.refundReasonOption4': 'Décès',
  'reasonSectionPage.refundReasonOption5': 'Autre',
  'reasonSectionPage.titleSelectedReason': 'Motif: ',
  'reasonSectionPage.deathDescription':
    "Pour ces cas, il est nécessaire de joindre le certificat de décès d'un ou plusieurs passagers," +
    " délivré par l'autorité compétente du pays. Vous pouvez joindre les documents nécessaires ci-dessous.",
  'reasonSectionPage.naturalDisasterDescription':
    "S'applique dans les cas où une catastrophe naturelle (par exemple, une tempête, un tremblement de terre," +
    ' une éruption volcanique) affecte de manière significative une ville ou une région liée à votre itinéraire.',
  'reasonSectionPage.affectedFlyOptionDescription':
    "Il ne s'applique que si l'un de vos vols a été modifié par Copa en" +
    " raison de retards, d'annulations ou d'opérations irrégulières. A défaut, votre demande sera rejetée.",
  'reasonSectionPage.otherBoxDescription':
    'Veuillez expliquer la situation plus en détail.',
  'reasonSectionPage.descriptionLabel': 'Explique la situation',
  'reasonSectionPage.descriptionPlaceholder': 'Soumettre des informations',
  'reasonSectionPage.checkbox1':
    "Je déclare avoir le pouvoir de demander ce remboursement. Je comprends et accepte que l'approbation de mon remboursement est soumise aux réglementations tarifaires.",
  'reasonSectionPage.checkbox2':
    "J'autorise librement, expressément et sans équivoque Copa Airlines à collecter, utiliser et traiter les informations saisies ici pour traiter ma demande de remboursement. J'autorise également le transfert de mes informations à des sociétés affiliées et à des tiers autorisés si nécessaire pour effectuer le remboursement. Je déclare, reconnais et accepte les politiques de confidentialité de l'entreprise.",
  'reasonSectionPage.button': 'Continuer',

  // ERROR PAGE
  'errorPage.header': 'Demande à être remboursé',
  'errorPage.alert': 'Nous sommes désolés!',
  'errorPage.title': 'Nous sommes désolés! Une erreur est survenue.',
  'errorPage.subtitle':
    "En raison de difficultés techniques, nous n'avons pas été en mesure de poursuivre votre demande de remboursement. Veuillez commencer de nouveau.",
  'errorPage.cta': 'Essayer à nouveau',

  // INTERSITIAL
  'intersitial.loading': 'Chargement en cours',
  'intersitial.alertError':
    "Nous sommes désolés! Nous n'avons pas trouvé votre demande. Veuillez réessayer.",

  'intersitialWCAG.alertError':
    'Nous ne pouvons pas trouver votre cas avec les informations fournies. Veuillez réessayer en modifiant les données.',

  // PAYMENT METHOD
  'paymentMethod.countryPurchase': "Pays d'achat",
  'paymentMethod.countryPurchasePlaceholder': 'Choisissez le pays',
  'paymentMethod.PaymentMethod': 'Mode de paiement',
  'paymentMethod.PaymentMethodPlaceholder': 'Sélectionnez le mode de paiement',
  'paymentMethod.TransferCash': 'Virement bancaire/Espèces',
  'paymentMethod.CreditCard': 'Carte de crédit',
  'paymentMethod.Other': 'Autre',

  // COUNTRIES
  'countries.BR': 'Brésil',
  'countries.MX': 'Mexique',
  'countries.AR': 'Argentine',
  'countries.CO': 'Colombie',
  'countries.CA': 'Canada',
  'countries.CL': 'Chili',
  'countries.PA': 'Panama',
  'countries.EU': "L'Europe",
  'countries.CU': 'Cuba',
  'countries.PE': 'Pérou',
  'countries.NI': 'Nicaragua',
  'countries.EC': 'Équateur',
  'countries.HT': 'Haïti',
  'countries.GY': 'Guyana',
  'countries.BB': 'Barbade',
  'countries.TT': 'Trinité-et-Tobago',
  'countries.OTHER': 'Autre',

  // 404 PAGE NOT FOUND

  'pageNotFound.button': 'Retour sur copa.com',
  'pageNotFound.Title': "Nous n'avons pas pu trouver cette page",
  'pageNotFound.Subtitle':
    "Nous sommes désolés, la page que vous avez essayé de visiter n'existe pas sur notre site Web.",
  'pageNotFound.CTA': 'Aller au début',

  // 404 PAGE NOT FOUND WCAG

  'pageNotFoundWCAG.container':
    "Nous sommes désolés. La page à laquelle vous avez essayé d'accéder n'existe pas. Veuillez retourner sur copa.com pour réessayer.",

  //  REASON SECTION PAGE WCAG
  'reasonSectionPageWCAG.refundSelect':
    'Dans le menu déroulant, utilisez les touches fléchées haut et bas' +
    ' pour sélectionner le motif de la demande de remboursement.',
  'reasonSectionPageWCAG.checkbox1':
    'Appuyez sur pour indiquer que vous déclarez avoir le pouvoir de demander ce remboursement. Que vous comprenez et ' +
    "acceptez que l'approbation du" +
    ' remboursement est soumise aux règles de votre tarif et à la politique de remboursement de Copa Airlines.',
  'reasonSectionPageWCAG.checkbox2':
    'Cliquez pour indiquer que vous acceptez que les informations saisies ' +
    "dans ce formulaire ne soient utilisées qu'à des fins de demande de remboursement.",
  'reasonSectionPageWCAG.buttonCta':
    'Bouton. Appuyez sur ENTER pour poursuivre la demande de remboursement.',
  'reasonSectionPageWCAG.textArea':
    'Utilisez cette zone de texte pour décrire les raisons pour lesquelles vous' +
    " souhaitez effectuer le remboursement. Vous pouvez écrire jusqu'à une limite de 250 caractères.",

  // EXCEPTIONS PAGE WCAG
  'exceptionsPageWCAG.container':
    "Nous ne pouvons pas traiter votre remboursement, car cette réservation a été achetée par l'intermédiaire d'un tiers" +
    " (Exemple : Agence de voyage, Page de voyage). Vous devez contacter l'entreprise avec laquelle vous avez " +
    "effectué l'achat pour demander un remboursement.",

  // ADD PASSENGERS PAGE WCAG
  'addPassengersPageWCAG.generalDescription':
    "Page pour remplir les informations des passagers qui vont demander le remboursement. Le remboursement sera traité pour tous les passagers de la réservation. Cette demande n'est valable que pour les réservations d'un maximum de 9 passagers.",
  'addPassengersPageWCAG.inputName':
    'Écrivez le nom du numéro de passager [X], seules les lettres et les espaces sont autorisés.',
  'addPassengersPageWCAG.reservationCode':
    'Vous pouvez ajouter le code de réservation pour être remboursé. Ce code de réservation comporte 6 caractères.',
  'addPassengersPageWCAG.inputLastname':
    'Écrivez le nom de famille du numéro de passager [X], seules les lettres et les espaces sont autorisés.',
  'addPassengersPageWCAG.inputETicket':
    'Écrivez le billet électronique du numéro de passager [X], seuls 13 chiffres sont autorisés et il doit commencer par 230.',
  'addPassengersPageWCAG.addPaxButton':
    'Bouton Ajouter plus de passagers. Appuyez sur Entrée pour ajouter un autre passager à la demande.',
  'addPassengersPageWCAG.removePaxButton':
    'Bouton pour supprimer un passager. Appuyez sur Entrée pour supprimer le passager de la demande',
  'addPassengersPageWCAG.cta':
    'Bouton pour continuer. Appuyez sur Entrée pour poursuivre la demande.',
  'addPassengersPageWCAG.alertError':
    'Un numéro de dossier existe déjà pour ce billet électronique. Votre numéro de' +
    " dossier est {caseNumber}. Accédez à la page de vérification de l'état pour vérifier l'état de votre demande.",

  // WCAG Text
  'additionalInfoPageWCAG.generalDescription':
    "Page pour demander le remboursement de votre réservation. Pour compléter la demande, veuillez remplir les informations supplémentaires dont nous avons besoin. Certains champs sont obligatoires et d'autres sont facultatifs. Selon le motif que vous avez sélectionné pour le remboursement, vous devez joindre certains documents. Veuillez vous assurer que les informations sont exactes, car une fois la demande remplie, vous ne pourrez plus la modifier. La demande de remboursement est faite pour tous les passagers de la réservation, si vous ne souhaitez demander un remboursement que pour certains passagers, vous devez d'abord appeler notre centrale de réservation pour séparer les passagers d'une autre réservation.",
  'additionalInfoPageWCAG.paxContainer':
    'Voici la liste de tous les passagers qui demandent un remboursement : passager numéro un [nom et prénom, date de naissance, numéro de billet] ; passager numéro deux [prénom et nom, date de naissance, numéro de billet] ; passager numéro trois [nom et prénom, date de naissance, numéro de billet], passager numéro quatre [nom et prénom, date de naissance, numéro de billet], etc.',
  'additionalInfoPageWCAG.inputName':
    'Tapez votre prénom, seules les lettres et les espaces blancs sont autorisés.',
  'additionalInfoPageWCAG.inputLastname':
    'Tapez votre nom de famille ou « nom de famille », seules les lettres et les espaces blancs sont autorisés.',
  'additionalInfoPageWCAG.inputEmail':
    "Écrivez l'e-mail où vous souhaitez recevoir la confirmation de la demande et où nous vous contacterons si nécessaire.",
  'additionalInfoPageWCAG.inputConfirmEmail':
    "Confirmez l'e-mail où vous souhaitez recevoir la confirmation de la demande et où nous vous contacterons si nécessaire.",
  'additionalInfoPageWCAG.inputPhoneCode1':
    "Dans la liste déroulante, sélectionnez l'option correspondant à votre code de pays.",
  'additionalInfoPageWCAG.inputPhone1':
    "Tapez votre numéro de téléphone. Il s'agit d'un champ numérique.",
  'additionalInfoPageWCAG.inputPhoneCode2':
    "Liste déroulante, sélectionnez l'option pour votre code de pays d'un deuxième numéro.",
  'additionalInfoPageWCAG.inputPhone2':
    "Tapez un deuxième numéro de téléphone. Il s'agit d'un champ numérique.",
  'additionalInfoPageWCAG.formOfPaymentContainer':
    'Le mode de paiement initial utilisé pour acheter cette réservation était une carte [type] se terminant par [4 derniers chiffres]. Ce sera la carte sur laquelle le remboursement sera effectué si la demande est acceptée.',
  'additionalInfoPageWCAG.reasonOfTheRefund':
    'Dans le menu déroulant, utilisez les touches fléchées haut et bas pour sélectionner le motif de la demande de remboursement.',
  'additionalInfoPageWCAG.legalCheckBox1':
    "Appuyez sur pour indiquer que vous déclarez avoir le pouvoir de demander ce remboursement. Que vous comprenez et acceptez que l'approbation du remboursement est soumise aux règles de votre tarif et à la politique de remboursement de Copa Airlines.",
  'additionalInfoPageWCAG.legalCheckBox2':
    "Cliquez pour indiquer que vous acceptez que les informations saisies dans ce formulaire ne soient utilisées qu'à des fins de demande de remboursement.",
  'additionalInfoPageWCAG.uploadDocuments':
    "Vous pouvez joindre vos fichiers pour justifier votre remboursement ici. Vous pouvez ajouter jusqu'à {numberOfDocs} fichiers qui ne doivent pas peser plus de 4 Mo. Il permet uniquement de joindre des documents au format .pdf ; .jpg et .png. Après les avoir attachés, vous pouvez supprimer n'importe quel document en appuyant sur le X à côté de chacun d'eux.",
  'additionalInfoPageWCAG.uploadDocumentsDelete':
    'Cliquez ici pour supprimer ce document.',
  'additionalInfoPageWCAG.uploadDocumentsReload':
    "Il semble qu'il y ait eu une erreur avec le fichier. Cliquez ici pour le recharger.",
  'additionalInfoPageWCAG.uploadDocumentsFormatError':
    "Le format que vous avez joint n'est pas correct. Veuillez joindre des documents au format .pdf; .jpg ou .png",
  'additionalInfoPageWCAG.uploadDocumentsLimitError':
    "Message d'erreur - Limite de téléchargement Vous ne pouvez télécharger que {numberOfDocs} fichiers maximum. Veuillez supprimer l'un des fichiers ajoutés pour joindre le nouveau.",
  'additionalInfoPageWCAG.uploadDocumentsSizeError':
    "Message d'erreur - Taille limite Le fichier que vous avez essayé d'ajouter est trop volumineux. Seuls les fichiers jusqu'à 4 Mo sont autorisés",
  'additionalInfoPageWCAG.uploadDocumentsRequiredError':
    'Vous devez joindre un fichier. Veuillez joindre un fichier pour continuer',
  'additionalInfoPageWCAG.uploadDocumentsDocumentError':
    "Certains documents que vous avez joints n'ont pas le format autorisé. Supprimez-les en utilisant le X à côté du fichier et ajoutez-en un nouveau au format .pdf, .jpg ou .png",
  'additionalInfoPageWCAG.cta':
    'Bouton. Appuyez sur ENTER pour poursuivre la demande de remboursement.',
  'additionalInfoPageWCAG.relationship':
    "Si vous n'êtes pas le propriétaire de la réservation. Veuillez décrire votre relation avec les passagers. Ceci est un champ à choix multiples.",
  'additionalInfoPageWCAG.relationshipOther':
    "Si vous avez choisi l'autre option, veuillez décrire votre relation avec le passager. Il s'agit d'un champ de texte.",
  'warningModalWCAG.modal':
    "Section de confirmation de demande de remboursement. Vous trouverez plus d'informations ci-dessous.",
  'warningModalWCAG.container':
    "Résumé de votre demande de remboursement. Si vous poursuivez la demande de remboursement, tout l'itinéraire sera annulé. Cette demande de remboursement a été faite par [nom] en raison de [Raison de la demande], pour les passagers suivants : passager numéro un [nom], passager numéro deux [nom], etc.",
  'warningModalWCAG.ctaBack':
    "Bouton pour revenir à la page d'informations supplémentaires. Appuyez sur Entrée pour revenir à la page précédente.",
  'warningModalWCAG.ctaConfirm':
    'Bouton de demande de remboursement. Appuyez sur le bouton pour confirmer la demande de remboursement et annuler votre itinéraire.',
  // ERROR PAGE WCAG
  'errorPageWCAG.container':
    "En raison de difficultés techniques, nous n'avons pas été en mesure de poursuivre votre demande de remboursement. Veuillez commencer de nouveau.",
  'errorPageWCAG.cta': 'Bouton Réessayer',
  // MODAL NPAFOPCASH
  'modalNRAndAFOPCash.title': 'Cette réservation a été payée en espèces',
  'modalNRAndAFOPCash.text':
    "Nous vous recommandons de demander votre remboursement à l'un de nos bureaux de vente afin qu'il puisse être traité immédiatement. Grâce à ce formulaire, le remboursement peut prendre jusqu'à deux mois pour devenir effectif.",
  'modalNRAndAFOPCash.secundaryButton': 'Continuer avec demande',
  'modalNRAndAFOPCash.primaryButton': 'Annuler',
  'modalNRAndAFOPCash.primaryButtonWCAG':
    'Appuyez sur oui pour vouloir annuler et revenir au début du flux',
  'modalNRAndAFOPCash.secundaryButtonWCAG':
    'Cliquez pour continuer avec la demande de remboursement',
  // ROUTES
  'links.changesAndRefunds':
    '/fr-gs/soutien-au-client/echange-et-remboursement/',
  // Modal Non-Refundable Fares
  'modalNonRefundableFares.title': "Ce billet n'est pas remboursable",
  'modalNonRefundableFares.descriptionStart':
    "La famille tarifaire que vous avez achetée n'est pas remboursable. " +
    "Cependant, des exceptions peuvent s'appliquer dans certaines circonstances. Voici les exceptions:",
  'modalNonRefundableFares.firstBullet': 'Décès (un certificat est requis)',
  'modalNonRefundableFares.secondBullet':
    'Annulations ou modifications effectuées par Copa Airlines qui impactent votre itinéraire ',
  'modalNonRefundableFares.descriptionEnd':
    "Si vous pensez que votre cas pourrait correspondre à l'une de ces exceptions, vous pouvez continuer le processus.",
  'modalNonRefundableFares.continueButton': 'Demander une exception',
  'modalNonRefundableFares.continueButtonWCAG':
    'Appuyez sur pour continuer avec les exceptions',
  'modalNonRefundableFares.cancelButton': 'Retour au début',
  'modalNonRefundableFares.cancelButtonWCAG':
    'Appuyez sur oui pour vouloir annuler et revenir au début du flux',
}
