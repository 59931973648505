import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@copa/design-system-factory.typography'
import Header from '../../../components/Header'
import ResponsiveContainer from '../../../components/ResponsiveContainer'
import PNRRetrieveForm from '../../PNRNotFound/components/PNRRetrieveForm'
import ManualRequestCTA from '../../PNRNotFound/components/ManualRequestCTA'
import useStyles from './styles'

function FindReservationPage() {
  const { formatMessage } = useIntl()

  return (
    <>
      <Header title={formatMessage({ id: 'additionalInfoPage.header' })} />

      <ResponsiveContainer
        id="find-reservation-container"
        marginTop={{ xs: '56px', lg: '88px' }}
        marginBottom={{ lg: '134px', sm: '172px', xs: '136px' }}
      >
        <section>
          <Typography
            id="find-reservation-title"
            sx={useStyles.title}
            variant="h2"
          >
            {formatMessage({ id: 'callCenterSpecialLink.title' })}
          </Typography>

          <Typography id="find-reservation-subtitle" sx={useStyles.description}>
            {formatMessage({ id: 'callCenterSpecialLink.description' })}
          </Typography>
        </section>

        <PNRRetrieveForm
          isAgency
          containerStyle={{
            marginTop: '40px',
          }}
          buttonText={formatMessage({ id: 'callCenterSpecialLink.button' })}
        />

        <ManualRequestCTA
          isAgency
          containerStyle={{ marginTop: { xs: '72px', sm: '56px' } }}
          title={formatMessage({ id: 'callCenterSpecialLink.moreOptionTitle' })}
          subtitle={formatMessage({
            id: 'callCenterSpecialLink.moreOptionSubtitle',
          })}
          disclaimerMessage={formatMessage({
            id: 'callCenterSpecialLink.moreOptionBody',
          })}
        />
      </ResponsiveContainer>
    </>
  )
}

export default FindReservationPage
