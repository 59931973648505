import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  passengersListContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  passengerListDisclaimer: {
    display: "flex",
    alignItems: "flex-start",
  },
}))

export default useStyles
