import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
  formOfPaymentContainer: {
    marginTop: "64px",
    [theme.breakpoints.down("md")]: {
      marginTop: "56px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
    },
  },
}))

export default useStyles
