import React, { FC } from "react"
import { useIntl } from "react-intl"
import SeoWrapper from "../SeoWrapper"

type Props = {
  component: React.JSX.Element
  ariaText: string
  idContainer?: string
  ariaTextProps?: string
  pageTitle?: string
  keywords?: string[]
  meta?: { name: string; content: string }[]
}

const EnhancedRoute: FC<Props> = ({
  component,
  ariaText,
  idContainer = "",
  pageTitle = "Copa Airlines",
  keywords = [],
  ...rest
}) => {
  const { formatMessage } = useIntl()

  return (
    <SeoWrapper
      ariaText={ariaText && formatMessage({ id: ariaText })}
      id={idContainer}
      title={pageTitle}
      metaTypeSEO={formatMessage({ id: "pageHeader.description" })}
      keywords={keywords}
      {...rest}
    >
      {React.cloneElement(component)}
    </SeoWrapper>
  )
}

export default EnhancedRoute
