const useStyles = {
  header: {
    fontFamily: "Gilroy",
    fontWeight: 700,
    fontSize: { xs: "18px", sm: "24px", md: "32px" },
    lineHeight: "40px",
    color: "#333333",
  },
  title: {
    fontFamily: "Gilroy",
    fontWeight: 700,
    fontSize: { sm: "24px", md: "32px" },
    lineHeight: "40px",
    color: "#002880",
  },
  description: {
    marginTop: "8px",
    fontSize: { sm: "16px", md: "20px" },
    fontWeight: 400,
    lineHeight: "32px",
    color: "#666666",
  },
}

export default useStyles
