import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Header from '../../components/Header'
import ErrorMessage from './components/ErrorMessage'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import PNRRetrieveForm from './components/PNRRetrieveForm'
import ManualRequestCTA from './components/ManualRequestCTA'

function PNRNotFoundPage({ isAgency }) {
  const { formatMessage } = useIntl()

  return (
    <>
      <Header title={formatMessage({ id: 'additionalInfoPage.header' })} />
      <ResponsiveContainer
        marginTop="64px"
        marginBottom={{ lg: '134px', sm: '172px', xs: '136px' }}
      >
        <ErrorMessage />
        <PNRRetrieveForm
          hasError
          containerStyle={{
            marginTop: {
              xs: '72px',
              sm: '56px',
              md: '56px',
            },
          }}
          isAgency={isAgency}
          buttonText={formatMessage({ id: 'generalLayoutPage.button' })}
        />
        <ManualRequestCTA
          containerStyle={{
            marginTop: { xs: '72px', sm: '56px' },
          }}
          title={formatMessage({ id: 'generalLayoutPage.title2' })}
          subtitle={formatMessage({ id: 'generalLayoutPage.subtitle2' })}
          disclaimerMessage={formatMessage({
            id: 'generalLayoutPage.passengerDisclaimer',
          })}
          isAgency={isAgency}
        />
      </ResponsiveContainer>
    </>
  )
}

PNRNotFoundPage.propTypes = {
  isAgency: PropTypes.bool,
}

PNRNotFoundPage.defaultProps = {
  isAgency: false,
}

export default PNRNotFoundPage
