import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import getTripInfo from '../services/triphub/triphub.service'
import {
  createRefund,
  getRefund,
  uploadFiles,
} from '../services/SRF/srf.service'
import { cleanUpSpecialChars } from '../utils/functions'
import {
  TripHubApiRespose,
  UseGeTripHubInfoArgs,
  UseApiCallRequestArgs,
  UseGetRefundArgs,
} from '../types/useApiCall.types'

export const useAPICall = <T>(
  apiCallFunc: (...args: UseApiCallRequestArgs) => Promise<{ data: T }>,
  loadingInitialState = false
) => {
  const [error, setError] = useState<AxiosError | string | null>(null)
  const [responseData, setResponseData] = useState<null | T>(null)
  const [isLoading, setIsLoading] = useState(loadingInitialState)

  const request: (...args: UseApiCallRequestArgs) => Promise<void> = async (
    ...args
  ) => {
    setIsLoading(true)
    try {
      const result = await apiCallFunc(...args)
      setResponseData(result.data)
      setError(null)
    } catch (e) {
      setError((e as AxiosError) || 'Unexpected Error!')
      setResponseData(null)
    } finally {
      setIsLoading(false)
    }
  }

  return { request, error, responseData, isLoading }
}

export const useGetTripHubInfo = (params: UseGeTripHubInfoArgs) => {
  const { requestCode, userLastName, skip, loadingInitialState } = params
  const resp = useAPICall<TripHubApiRespose>(getTripInfo, loadingInitialState)

  useEffect(() => {
    if (!skip && requestCode && userLastName) {
      resp.request(requestCode, userLastName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCode, userLastName, skip])

  return resp
}

// TODO: Change this any when updating Passenger page
export const useGetRefund = (params: UseGetRefundArgs) => {
  const resp = useAPICall(getRefund)
  const { caseNumber, eTicket, pnr, lastname, skip } = params

  useEffect(() => {
    if (!skip && (caseNumber || eTicket || pnr)) {
      resp.request({
        caseNumber,
        eTicket,
        pnr,
        lastName: lastname ? cleanUpSpecialChars(lastname) : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseNumber, eTicket, pnr, lastname, skip])
  return resp
}

export const useUploadDocument = () => useAPICall(uploadFiles)
export const useCreateRefund = () => useAPICall(createRefund)
