import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Box } from '@mui/material'
import { Button } from '@copa/design-system-factory.buttons.button'
import { Typography } from '@copa/design-system-factory.typography'
import { useSearchParams } from 'react-router-dom'
import { theme } from '@copa/design-system-factory.theme'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import Header from '../../components/Header'
import Interstitial from '../../components/Interstitial/interstitial'
import CaseStatus from './components/CaseStatus'
import useStyle from './style'
import { useAppSelectGeneralInfo } from '../../hooks/store-hooks'
import useStoreRefund from '../../hooks/usePersistData'
import PNRHeading from '../../components/PNRHeading'
import Hyperlink from '../../components/Hyperlink'
import { getLinkToCopa, sendGACustomEvent } from '../../utils/functions'
import { identifyUser } from '../../utils/logs'

type Props = {
  confirmation?: boolean
}

const converToHyperLink =
  (linkUrl: string) => (linkText: ReactNode | ReactNode[]) => {
    const hyperlinkProps = {
      style: {
        color: theme.palette.primary.light,
        fontFamily: 'SuisseIntl',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textDecorationColor: theme.palette.primary.light,
        textDecoration: 'none',
      },
      linkUrl,
      target: '_blank',
      linkText,
    }
    return <Hyperlink {...hyperlinkProps} />
  }

const RequestConfirmationPage: FC<Props> = ({ confirmation }) => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const containerRef = useRef<HTMLDivElement>(null)
  const goBackSteps = searchParams.get('goBackSteps')
  const caseNumberParam = searchParams.get('case_number')
  const lastNameParam = searchParams.get('lastname')
  const generalContext = useAppSelectGeneralInfo()

  const {
    passengers,
    caseNumber = '',
    status,
    requestCode,
    language,
  } = generalContext

  const { isLoading: isRefundLoading } = useStoreRefund({
    skip: caseNumber,
    requestCode: caseNumberParam,
    lastname: lastNameParam,
    reload: false,
  })

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const code = requestCode || caseNumberParam
    if (code) {
      identifyUser(code, lastNameParam)
    }
  }, [requestCode, caseNumberParam, lastNameParam])

  if (isRefundLoading) {
    return (
      <Interstitial
        loadingText={formatMessage({ id: 'intersitial.loading' })}
      />
    )
  }

  const convertToBoldText = (text: ReactNode[] | ReactNode) => (
    <b
      style={{
        fontFamily: 'SuisseIntl',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
      }}
    >
      {text}
    </b>
  )

  return (
    <div ref={containerRef}>
      <Header
        title={formatMessage({ id: 'refundConfirmation.header' })}
        goBackSteps={goBackSteps ? Number(goBackSteps) : undefined}
      />
      <PNRHeading />

      <ResponsiveContainer marginTop="71px">
        <CaseStatus
          confirmation={confirmation}
          passengers={passengers}
          refundCase={{ caseNumber, status }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Box
            sx={{
              width: {
                xs: '100%',
                md: 'inherit',
              },
            }}
          >
            <Button
              sx={useStyle.button}
              size="large"
              variant="outlinePrimaryMain"
              onClick={() => {
                sendGACustomEvent('Request another refund')
                window.location.href = formatMessage({
                  id: 'refundConfirmation.buttonURL',
                })
              }}
              width="100%"
            >
              {formatMessage({ id: 'refundConfirmation.button' })}
            </Button>
          </Box>
        </Box>
        <section
          style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
        >
          <Typography
            variant="h3"
            sx={{ marginBottom: '32px', fontSize: '20px', fontWeight: 700 }}
          >
            {formatMessage({ id: 'refundConfirmation.title2' })}
          </Typography>
          <Typography>
            <FormattedMessage
              id="refundConfirmation.subtitle2"
              values={{
                cta: converToHyperLink(getLinkToCopa(language)),
              }}
            />
          </Typography>
          <Typography>
            <FormattedMessage id="refundConfirmation.subtitle3" />
          </Typography>

          <ul style={{ margin: 0, paddingLeft: '24px' }}>
            <li>
              <Typography style={{ paddingTop: '8px' }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph1"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: '8px' }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph2"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: '8px' }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph3"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
            <li>
              <Typography style={{ paddingTop: '8px', marginBottom: '106px' }}>
                <FormattedMessage
                  id="refundConfirmation.paragraph4"
                  values={{ b: convertToBoldText }}
                />
              </Typography>
            </li>
          </ul>
        </section>
      </ResponsiveContainer>
    </div>
  )
}

export default RequestConfirmationPage
