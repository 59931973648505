import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Typography } from '@copa/design-system-factory.typography'
import PropTypes from 'prop-types'
import useStyles from './styles'
import SpinningLoader from '../../../../assets/Icons/SpinningLoader'
import DocumentIcon from '../../../../assets/Icons/Document'

function DocumentChip({
  documentInfo,
  handleRemoveDocument,
  handleReload,
  error,
  isLoading,
}) {
  const { classes: styles } = useStyles()
  const { formatMessage } = useIntl()

  const handleRemove = () => {
    handleRemoveDocument(documentInfo.index)
  }

  const handleRemoveKeyDown = evt => {
    if (evt.code === 'Space' || evt.code === 'Enter') {
      evt.preventDefault()
      handleRemoveDocument()
    }
  }

  const handleRefreshKeyDown = evt => {
    if (evt.code === 'Space' || evt.code === 'Enter') {
      evt.preventDefault()
      handleReload()
    }
  }

  const fileName =
    documentInfo.name.length >= 12
      ? `${documentInfo.name.substr(0, 5).toLowerCase()}...${documentInfo.name
          .substr(documentInfo.name.length - 6, documentInfo.name.length)
          .toLowerCase()}`
      : documentInfo.name.toLowerCase()

  const fileNameErrorSufix = error
    ? `- ${formatMessage({
        id: 'additionalInfoPage.uploadDocumentsUploadError',
      })}`
    : ''

  const fileNameSufix = isLoading
    ? `- ${formatMessage({ id: 'additionalInfoPage.uploadDocumentsLoading' })}`
    : fileNameErrorSufix

  return (
    <Box
      className={`${styles.chip} ${error && !isLoading ? styles.error : ''}`}
    >
      <DocumentIcon
        style={{
          width: '14px',
          marginRight: '0px',
        }}
      />
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {fileName} {fileNameSufix}
      </Typography>

      {isLoading && <SpinningLoader className={styles.icon} />}
      {!isLoading && error ? (
        <button
          type="button"
          aria-label={`${formatMessage({
            id: 'additionalInfoPageWCAG.uploadDocumentsReload',
          })} ${documentInfo.name}`}
          onClick={handleReload}
          onKeyDown={handleRefreshKeyDown}
          style={{
            marginLeft: '10px',
            marginBottom: '3px',
            width: '20px',
            height: '20px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RefreshIcon
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        </button>
      ) : (
        <button
          type="button"
          aria-label={`${formatMessage({
            id: 'additionalInfoPageWCAG.uploadDocumentsDelete',
          })} ${documentInfo.name}`}
          onClick={handleRemove}
          onKeyDown={handleRemoveKeyDown}
          style={{
            marginLeft: '5px',
            width: '15px',
            height: '15px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CloseIcon
            sx={{
              width: '15px',
              height: '15px',
            }}
          />
        </button>
      )}
    </Box>
  )
}

DocumentChip.defaultProps = {
  error: false,
  isLoading: false,
}

DocumentChip.propTypes = {
  documentInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleRemoveDocument: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
}

export default DocumentChip
