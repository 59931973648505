/* eslint-disable import/prefer-default-export */

export const formatDate = date => {
  const currentDate = new Date(date)
  let month = (currentDate.getUTCMonth() + 1).toString()
  let day = currentDate.getUTCDate().toString()
  const year = currentDate.getUTCFullYear()

  month = month.padStart(2, '0')
  day = day.padStart(2, '0')

  return [day, month, year].join('/')
}
