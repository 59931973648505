import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'
import ArrowLeft from '../../assets/Icons/ArrowLeft'

const Header: FC<{ title: string; goBackSteps?: number }> = ({
  title,
  goBackSteps = 1,
}) => {
  const { classes: styles } = useStyles()
  const navigate = useNavigate()
  const handleGoBack = (goBack: number) => {
    navigate(-1 * goBack)
  }
  return (
    <header id="header-container" className={styles.header}>
      <Box
        className={styles.headerBackButton}
        onClick={() => handleGoBack(goBackSteps)}
      >
        <ArrowLeft id="back-icon" />
      </Box>

      <section className={styles.headerTextContainer}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: { xs: 700 },
            lineHeight: { xs: '24px', md: '40px' },
            fontSize: {
              xs: '18px',
              sm: '20px',
              md: '32px',
            },
          }}
        >
          {title}
        </Typography>
      </section>
    </header>
  )
}

export default Header
