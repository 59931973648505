import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { InlineHelperText } from '@copa/design-system-factory.inline-helper-text'
import ErrorIcon from '@mui/icons-material/Error'
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from 'uuid'
import useStyles from './styles'
import UploadIcon from '../../../../assets/Icons/Upload'
import DocumentChipWrapper from '../DocumentChipWrapper'

function addIsNewFlag(file) {
  /* eslint-disable-next-line no-param-reassign */
  file.id = uuid()
  /* eslint-disable-next-line no-param-reassign */
  file.isNew = true
  return file
}

function UploadErrorText({ ariaLabel, text, id }) {
  return (
    <Box sx={{ margin: '1rem auto' }}>
      <InlineHelperText
        id={`${id}-helper-text`}
        showMessage
        helperText={text}
        isError
        aria-label={ariaLabel}
        icon={
          <ErrorIcon
            sx={{
              color: 'error.main',
              width: '12px',
              height: '12px',
            }}
          />
        }
      />
    </Box>
  )
}

function DocumentUploader({
  label = '',
  id,
  required,
  enabledFormats,
  maxFilesCount,
  maxFileSize,
  handleDocumentsChange,
  ...props
}) {
  const [droppedFiles, setDroppedFiles] = useState([])
  const [isDragOver, setIsDragOver] = useState(false)
  const [isInvalidFormat, setIsInvalidFormat] = useState(false)
  const [isMaxFilesReached, setIsMaxFilesReached] = useState(false)
  const [isMaxFileSizeReached, setIsMaxFileSizeReached] = useState(false)
  const [numberOfUploadErrors, setNumberOfUploadErrors] = useState(0)
  const [isEmptyError, setIsEmptyError] = useState(false)
  const hasErrors =
    isInvalidFormat ||
    isMaxFileSizeReached ||
    isMaxFilesReached ||
    numberOfUploadErrors > 0 ||
    isEmptyError

  const { classes: styles } = useStyles()
  const formRef = useRef(null)
  const inputFileRef = useRef(null)
  const { formatMessage } = useIntl()

  const cleanupErrors = () => {
    setIsEmptyError(false)
    setIsInvalidFormat(false)
    setIsMaxFilesReached(false)
    setIsMaxFileSizeReached(false)
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const incrementUploadErrors = () => {
    setNumberOfUploadErrors(count => count + 1)
  }

  const decrementUploadErrors = () => {
    setNumberOfUploadErrors(count => count - 1)
  }

  useEffect(() => {
    /** Cleanup the maxFiles Error (If It exists) when the user deletes one file */
    if (isMaxFilesReached && droppedFiles.length < maxFilesCount) {
      setIsMaxFilesReached(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFiles])

  const isValidSize = useCallback(
    files => {
      // check if the size of the file is greater than the allowed
      if (maxFileSize && files.some(file => file.size > maxFileSize)) {
        setIsMaxFileSizeReached(true)
        setIsDragOver(false)
        return false
      }
      return true
    },
    [maxFileSize]
  )

  const isValidFormat = useCallback(
    files => {
      // check if some uploaded file is not in one of the allowed formats
      if (
        enabledFormats &&
        files.some(
          file =>
            !enabledFormats.some(format =>
              file.name.toLowerCase().endsWith(format.toLowerCase())
            )
        )
      ) {
        setIsInvalidFormat(true)
        setIsDragOver(false)
        return false
      }
      return true
    },
    [enabledFormats]
  )

  const isValidLimit = useCallback(
    files => {
      // check if the provided count prop is less than uploaded count of files
      if (maxFilesCount && maxFilesCount < droppedFiles.length + files.length) {
        setIsMaxFilesReached(true)
        setIsDragOver(false)
        return false
      }
      return true
    },
    [droppedFiles.length, maxFilesCount]
  )

  const handleDrop = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      cleanupErrors()
      const files = [...e.dataTransfer.files]
      if (
        !isValidLimit(files) ||
        !isValidFormat(files) ||
        !isValidSize(files)
      ) {
        return
      }

      if (files && files.length) {
        const newFiles = [...droppedFiles, ...files.map(addIsNewFlag)]
        setDroppedFiles(newFiles)
      }
      setIsDragOver(false)
    },
    [droppedFiles, isValidFormat, isValidLimit, isValidSize]
  )

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragOver(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragOver(false)
  }

  useEffect(() => {
    const element = formRef.current
    element.addEventListener('dragover', handleDragOver)
    element.addEventListener('drop', handleDrop)
    element.addEventListener('dragenter', handleDragEnter)
    element.addEventListener('dragleave', handleDragLeave)

    return () => {
      element.removeEventListener('dragover', handleDragOver)
      element.removeEventListener('drop', handleDrop)
      element.removeEventListener('dragenter', handleDragEnter)
      element.removeEventListener('dragleave', handleDragLeave)
    }
  }, [handleDrop])

  const handleRemoveDocument = (documentIndex, responseData) => {
    const docs = [...droppedFiles]
    docs.splice(documentIndex, 1)
    setDroppedFiles(docs)
    if (docs.length <= 0) {
      setIsEmptyError(true)
    }
    handleDocumentsChange(responseData, 'DELETE')
  }

  const handleUploadSuccess = responseData => {
    handleDocumentsChange(responseData)
  }

  const handleInputFileClick = () => {
    inputFileRef.current.click()
  }

  const handleInputFileKeyDown = e => {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault()
      handleInputFileClick()
    }
  }

  const handleInputFileChange = e => {
    const event = e
    event.dataTransfer = { files: inputFileRef.current.files || [] }
    handleDrop(event)
    inputFileRef.current.value = ''
  }

  const handleOnBlur = () => {
    if (required && droppedFiles.length <= 0) {
      setIsEmptyError(true)
    }
  }

  const maxFilesErrorMessage = formatMessage(
    {
      id: 'additionalInfoPageWCAG.uploadDocumentsLimitError',
    },
    { numberOfDocs: maxFilesCount }
  )

  const dragStyle = hasErrors
    ? `${styles.container} ${styles.hasErrorHelper}`
    : `${styles.container}`

  return (
    <section {...props}>
      <Typography
        variant="body2"
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          margin: '20px 0',
          color: 'grey.700',
          fontFamily: 'SuisseIntl',
        }}
      >
        {label}
        <span style={{ color: 'red', fontFamily: 'SuisseIntl' }}>*</span>
      </Typography>
      {droppedFiles.length > 0 && (
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '12px',
            columnGap: '8px',
            marginBottom: '12px',
          }}
        >
          {droppedFiles.map((file, index) => (
            <DocumentChipWrapper
              key={`Document-${file.id}`}
              file={file}
              index={index}
              handleRemoveDocument={handleRemoveDocument}
              handleUploadSuccess={handleUploadSuccess}
              notifyUploadErrors={incrementUploadErrors}
              notifyUploadErrorsFixed={decrementUploadErrors}
            />
          ))}
        </Box>
      )}

      <Box
        onClick={handleInputFileClick}
        onKeyDown={handleInputFileKeyDown}
        onBlur={handleOnBlur}
        tabIndex={0}
        aria-describedby="docError-helper-text"
        aria-label={`${label}. ${formatMessage(
          {
            id: 'additionalInfoPageWCAG.uploadDocuments',
          },
          { numberOfDocs: maxFilesCount }
        )}`}
        role="button"
        data-cy={`UploadDocuments-${id}`}
      >
        <Box
          className={
            isDragOver ? `${styles.container} ${styles.dragOver}` : dragStyle
          }
          ref={formRef}
        >
          <input
            type="file"
            ref={inputFileRef}
            style={{
              visibility: 'hidden',
              height: '0px',
            }}
            onChange={handleInputFileChange}
          />
          <UploadIcon
            style={{
              margin: '0px auto',
            }}
          />
          {!isDragOver && (
            <>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'SuisseIntl',
                  color: 'primary.dark',
                  marginTop: '8px',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {formatMessage({
                  id: 'additionalInfoPage.uploadDocumentsHelper1',
                })}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: '8px',
                  fontSize: '12px',
                  lineHeight: '16px',
                }}
              >
                {formatMessage({
                  id: 'additionalInfoPage.uploadDocumentsFormats',
                })}
                <br />
                {formatMessage({
                  id: 'additionalInfoPage.uploadDocumentsSize',
                })}
              </Typography>
            </>
          )}
          {isDragOver && (
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'SuisseIntl',
                color: 'primary.dark',
                marginTop: '8px',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {formatMessage({
                id: 'additionalInfoPage.uploadDocumentsHelper2',
              })}
            </Typography>
          )}
          {isInvalidFormat && !isDragOver && (
            <UploadErrorText
              id="docError"
              ariaLabel={formatMessage({
                id: 'additionalInfoPageWCAG.uploadDocumentsFormatError',
              })}
              text={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsErrorFormat',
              })}
            />
          )}
          {isMaxFilesReached && !isDragOver && (
            <UploadErrorText
              id="docError"
              ariaLabel={maxFilesErrorMessage}
              text={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsErrorLimit',
              })}
            />
          )}
          {isMaxFileSizeReached && !isDragOver && (
            <UploadErrorText
              id="docError"
              ariaLabel={formatMessage({
                id: 'additionalInfoPageWCAG.uploadDocumentsSizeError',
              })}
              text={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsErrorSize',
              })}
            />
          )}
          {numberOfUploadErrors > 0 && !isDragOver && (
            <UploadErrorText
              id="docError"
              ariaLabel={formatMessage({
                id: 'additionalInfoPageWCAG.uploadDocumentsDocumentError',
              })}
              text={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsGeneralError',
              })}
            />
          )}
          {isEmptyError && !isDragOver && (
            <UploadErrorText
              id="docError"
              ariaLabel={formatMessage({
                id: 'additionalInfoPageWCAG.uploadDocumentsRequiredError',
              })}
              text={formatMessage({
                id: 'additionalInfoPage.uploadDocumentsErrorRequired',
              })}
            />
          )}
        </Box>
      </Box>
    </section>
  )
}

DocumentUploader.defaultProps = {
  required: false,
}

DocumentUploader.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  enabledFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxFileSize: PropTypes.number.isRequired,
  maxFilesCount: PropTypes.number.isRequired,
  handleDocumentsChange: PropTypes.func.isRequired,
}

UploadErrorText.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default DocumentUploader
