/* eslint-disable import/prefer-default-export */
import { StatusType } from 'src/types/state.types'

export const PILL_CONFIG: {
  [key in StatusType]: { color: string; text: string }
} = {
  NEW: {
    color: 'primary.main',
    text: 'refundConfirmation.new',
  },
  EVALUATION: {
    color: 'alternative.gold',
    text: 'refundConfirmation.evaluation',
  },
  ESCALATED: {
    color: 'alternative.gold',
    text: 'refundConfirmation.escalated',
  },
  CLOSED: {
    color: 'error.main',
    text: 'refundConfirmation.closed',
  },
}
