import React, {
  createContext,
  DispatchWithoutAction,
  ReactNode,
  useReducer,
} from 'react'
import { Locale } from '../types/translations.types'
import { GlobalState } from '../types/state.types'

const DEFAULT_STATE: GlobalState = {
  flow: 'automatic',
  language: Locale.es,
}

export const DispatchGeneralContext = createContext<DispatchWithoutAction>(
  () => {}
)
export const GeneralContext = createContext<GlobalState>(DEFAULT_STATE)

// General context is used to share global data across the components tree
/* generalContext = {
  language,
  country,
  request_code,
  last_name,
  passengers,
  status: {date, name},
  flow,
  source
  ....
} */

const generalContextReducer = (
  state: GlobalState,
  update = { action: '', payload: {} }
) => {
  if (update.action === 'set') {
    return { ...state, ...update.payload }
  }
  return state
}

export function GeneralContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(generalContextReducer, DEFAULT_STATE)

  return (
    <DispatchGeneralContext.Provider value={dispatch}>
      <GeneralContext.Provider value={state}>
        {children}
      </GeneralContext.Provider>
    </DispatchGeneralContext.Provider>
  )
}
