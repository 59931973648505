import React from 'react'
import VisaIcon from '../../../../assets/Icons/creditcards/Visa'
import AmericanExpress from '../../../../assets/Icons/creditcards/AmericanExpress'
import DinnersClub from '../../../../assets/Icons/creditcards/DinnersClub'
import Discover from '../../../../assets/Icons/creditcards/Discover'
import MasterCard from '../../../../assets/Icons/creditcards/MasterCard'
import Uatp from '../../../../assets/Icons/creditcards/Uatp'
import { CreditCard, Miles } from '../../../../assets/Icons/paymentMethods'

export const PAYMENT_METHOD_BY_CODE = {
  VI: { name: 'Visa', icon: <VisaIcon /> },
  AX: { name: 'American Express', icon: <AmericanExpress /> },
  DC: { name: 'Diners Club', icon: <DinnersClub /> },
  DS: { name: 'Discover', icon: <Discover /> },
  CA: { name: 'MasterCard', icon: <MasterCard /> },
  CAD: { name: 'MasterCard Debit', icon: <MasterCard /> },
  TP: { name: 'UATP', icon: <Uatp /> },
  VID: { name: 'Visa Debit', icon: <VisaIcon /> },
  EMD: { name: 'EMD', icon: null },
  CREDIT_CARD: {
    name: 'Credit Card',
    icon: <CreditCard />,
  },
  MILES: {
    name: 'Miles',
    icon: <Miles />,
  },
}

export default PAYMENT_METHOD_BY_CODE
