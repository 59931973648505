import React, { FC } from 'react'
import { getAssetURL } from '../../../utils/assets'
import { AssetType, PictogramCategory } from '../../../types/assets.types'

function Miles() {
  return (
    <img
      src={getAssetURL('estrella_de_millas_b', {
        type: AssetType.pictogram,
        category: PictogramCategory.connectmiles,
      })}
      style={{ width: '32px', height: 'auto' }}
      alt=""
    />
  )
}

export default Miles as FC
