export default {
  'inputErrorMessage.pnrNotFound': "We couldn't find your reservation",
  'createRequestPage.fateInfo':
    'https://www.copaair.com/en-gs/travel-information/fare-families/',

  //  CREATE REQUEST PAGE
  'createRequestPage.title': 'What do you want to refund?',
  'createRequestPage.refundTicket.title': 'Refund Ticket',
  'createRequestPage.refundTicket.description':
    'If your travel plans have changed and you need to request a refund for your ticket, you can request the cancellation of your itinerary and obtain a refund for your ticket here.',
  'createRequestPage.refundTicket.cta': 'Request Refund',
  'createRequestPage.refundPaidServices.title': 'Refund Paid Services',
  'createRequestPage.refundPaidServices.description':
    'Baggage fees, class upgrades, seats are non-refundable. ' +
    'If the service was not honored due to the airline’s responsibility you may request it.',
  'createRequestPage.refundPaidServices.cta': 'Request Refund',
  'createRequestPage.otherOptions.title': 'Other options',
  'createRequestPage.otherOptions.subtitle':
    'Have you been charged twice for the same transaction?',
  'createRequestPage.otherOptions.description':
    'Duplicate or incorrect charges for airfare and other services ' +
    'related to your trip.',
  'createRequestPage.refundPaidServices.notHonoredURL':
    'https://help.copaair.com/hc/en-us/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=___dc.servicios_adicionales_pagados_no_honrados__&ticket_form_id=360004175854',
  'createRequestPage.otherOptions.doubleChargeURL':
    'https://help.copaair.com/hc/en-us/requests/new?tf_360045459154=tuve_un_problema_con_mi_boleto_o_mi_vuelo_y_debo_exponer_una_queja&tf_5070193789719=__dc.cobro_duplicado_o_incorrecto__&ticket_form_id=360004175854',

  //  CREATE REQUEST PAGE WCAG
  'createRequestPage.wcag.pageDescription':
    'Through this page, you will be able to select the type of refund you want to make.',
  'createRequestPage.wcag.refundRequestBtn': 'Press to request a ticket refund',
  'createRequestPage.wcag.refundPaidServiceBtn':
    'Press to request a paid service refund. A new tab will open to request this refund.',
  'createRequestPage.wcag.refundDuplicateService':
    'Press to request a refund for duplicate or incorrect charges for' +
    ' airfare and other services related to your trip. A new tab will open to request this refund.',

  //  CHECK STATUS PAGE WCAG
  'checkStatusPageWCAG.container':
    'To look up your refund request you will need your reservation code, ticket number or your case number.' +
    ' Enter them in the fields below and then press Find Request',

  //  CHECK STATUS PAGE
  'checkStatusPage.header': 'Review refund request status',
  'checkStatusPage.title': 'Review refund request',
  'checkStatusPage.subtitle': 'Enter the case number of your refund request.',
  'checkStatusPage.ticketLabel':
    'Case number, reservation code or e-ticket number',
  'checkStatusPage.ticketPlaceholder': 'RFD-WEB1234567',
  'checkStatusPage.ticketHelperText':
    'Case number must be 13 or 14 digits and start with RFD',
  'checkStatusPage.lastnameLabel': 'Surname',
  'checkStatusPage.lastnamePlaceholder': 'Enter last name',
  'checkStatusPage.cta': 'Find request',
  'checkStatusPage.footerTitle': 'Where do I find my case number?',
  'checkStatusPage.footerBullet':
    'At the end of your refund request, we sent you an email. In this email you can find your case number; it is made up of 13 or 14 digits and begins with RFD (ex RFD-WEB1234567).',
  'checkStatusPage.alert':
    'This functionality is only available for requests made after August 25, 2022.',
  'pageHeader.description':
    'Refund and cancellation options page for Copa Airlines travelers',
  'errorAgency.title':
    'To request a refund, please contact the travel agency or company with which you made this reservation.',
  'errorAgency.subTitle':
    'This reservation was purchased through a third party (eg travel agency, travel website). To request a refund of it, you must do it through them. If the third-party company has already confirmed that it is not possible to process the refund through them, please contact our ',
  'errorAgency.link': 'Reservation Center ',
  'errorAgency.continue': 'for further attention.',
  'errorAgency.button': 'Regresar a copa.com',
  'errorAgency.WCAGbutton': 'Regresar a Copa.com',
  'errorAgency.WCAGContainer':
    'We cannot process your refund, since this reservation was purchased through a third party (Example: Travel Agency, Travel Page). You must contact the company with which you made the purchase to request a refund.',
  'additionalInfoPage.header': 'Request a refund',
  'formOfPayment.title': 'Bank information to make a refund',
  'formOfPayment.bankName': 'Bank name',
  'formOfPayment.placeholderBankName': 'Enter bank name',
  'formOfPayment.accountHolder': 'Account holder',
  'formOfPayment.placeholderAccountHolder': 'Enter beneficiary',
  'formOfPayment.accountType': 'Account type',
  'formOfPayment.placeholderAccountType': 'Select account type',
  'formOfPayment.savingsAccount': 'Savings Account',
  'formOfPayment.checkingAccount': 'Checking Account',
  'formOfPayment.accountNumber': 'Account number',
  'formOfPayment.placeholderAccountNumber': 'Enter the account number',
  'formOfPayment.cpfCpnj': 'CPF / CPNJ',
  'formOfPayment.placeholderCpfCpnj': 'Enter bank name',
  'formOfPayment.bankCode': 'Bank code',
  'formOfPayment.placeholderBankCode': 'Enter the bank code',
  'formOfPayment.agency': 'Agency',
  'formOfPayment.placeholderAgency': 'Enter the agency number',
  'formOfPayment.interbankKey': 'Interbank Key',
  'formOfPayment.placeholderInterbankKey': 'Enter Interbank Key',
  'formOfPayment.swiftcode': 'Routing number (ABA/Swiftcode)',
  'formOfPayment.placeholderSwiftcode': 'Enter routing number',
  'formOfPayment.rfc': 'RFC',
  'formOfPayment.placeholderRfc': 'Enter RFC',
  'formOfPayment.cuitCuil': 'CUIT / CUIL',
  'formOfPayment.placeholderCuitCuil': 'Enter CUIT / CUIL',
  'formOfPayment.cbu': 'CBU',
  'formOfPayment.placeholderCbu': 'Enter CBU',
  'formOfPayment.bankingCertification': 'Banking certification',
  'formOfPayment.placeholderBankingCertification': 'Enter Certification',
  'formOfPayment.IDNumber': 'ID Number ',
  'formOfPayment.placeholderIDNumber': 'Enter ID Number',
  'formOfPayment.CCI': 'Interbank account code or CCI',
  'formOfPayment.placeholderCCI': 'Enter account',
  'formOfPayment.interbankAccountNumber': 'Interbank account number',
  'formOfPayment.placeholderInterbankAccountNumber': 'Enter account Number',
  'formOfPayment.NIT': 'ID or NIT',
  'formOfPayment.placeholderNIT': 'ID Number',
  'formOfPayment.phoneNumber': 'Phone',
  'formOfPayment.placeholderPhoneCode': 'Country code',
  'formOfPayment.placeholderPhoneNumber': 'Phone number',
  'formOfPayment.address': 'Address',
  'formOfPayment.placeholderAddress': 'Enter address',
  'formOfPayment.RUC': 'ID or RUC',
  'formOfPayment.placeholderRUC': 'ID Number',
  'formOfPayment.transit': 'Transit number',
  'formOfPayment.placeholderTransit': 'Enter transit number',
  'formOfPayment.iban': 'IBAN',
  'formOfPayment.placeholderIban': 'Enter IBAN',
  'formOfPayment.branch': 'Branch',
  'formOfPayment.placeholderBranch': 'Enter Branch',
  'formOfPayment.passport': 'ID or Passportt',
  'formOfPayment.placeholderPassport': 'ID Number',
  'refundConfirmation.header': 'Refund Status',
  'refundConfirmation.heading': 'Reservation Code',
  'refundConfirmation.passengerDisclaimer':
    'We will being evaluating your application within the next 21 days. Once processed, it may take up to two statements for your request to be processed.',
  'refundConfirmation.title':
    'Refund request sent successfully (Pending Approval)',
  'refundConfirmation.caseDateHeader': 'Application date:',
  'refundConfirmation.caseDate': ' February 15, 2022',
  'refundConfirmation.caseStatus': 'Actual status:',
  'refundConfirmation.case': 'Case: ',
  'refundConfirmation.new': 'Received',
  'refundConfirmation.evaluation': 'Under review',
  'refundConfirmation.escalated': 'Pending',
  'refundConfirmation.approved': 'Approved',
  'refundConfirmation.closed': 'Closed',
  'refundConfirmation.button': 'Request a refund for another reservation',
  'refundConfirmation.title2': "What's next?",
  'refundConfirmation.subtitle2':
    'You can check the status of your request at <cta>copa.com</cta>, ' +
    'in the refund section and by selecting the “Check status” option.',
  'refundConfirmation.subtitle3':
    'Your request may have one of the following statuses:',
  'refundConfirmation.paragraph1':
    '<b>"Received"</b>: Initial status assigned when creating the request on the form.',
  'refundConfirmation.paragraph2':
    '<b>"Under review"</b>: The request is being reviewed by our refunds team.',

  'refundConfirmation.paragraph3':
    '<b>"Pending"</b>: The request needs additional information from the passenger/applicant to be validated by our refunds team.',
  'refundConfirmation.paragraph4':
    '<b>"Closed"</b>: We have responded to the request by email.',
  'refundConfirmation.buttonURL':
    'https://www.copaair.com/en-pa/customer-service/changes-and-refunds/',
  'refundStatus.title': 'Case Status',
  'refundStatus.description':
    'This reservation already has one or more open refund requests. Check the cases status on this page.',
  'generalLayoutPage.title1': "We couldn't find your reservation",
  'generalLayoutPage.bullet1':
    'Make sure you are using a Copa Airlines reservation code, which is a 6-digit alphanumeric code (example: ABC123)',
  'generalLayoutPage.bullet2':
    'We recommend locating your reservation using the ticket number of one of the passengers. (example: 2301234567890)',
  'generalLayoutPage.bullet3':
    'If you purchased your reservation through a travel agent, please contact them to request a refund.',
  'generalLayoutPage.bullet4':
    'If your reservation is more than 1 year old, you must select the option "Open a case with passenger information" at the bottom of the page.',
  'generalLayoutPage.subtitle1': 'Enter your reservation information',
  'generalLayoutPage.field1': 'Reservation code or e-ticket number',
  'generalLayoutPage.helperText1': 'Enter reservation code',
  'generalLayoutPage.field2': 'Last name',
  'generalLayoutPage.helperText2': 'Enter last name',
  'generalLayoutPage.errorAlertMessage': "We couldn't find this reservation",
  'generalLayoutPage.button': 'Find Reservation',
  'generalLayoutPage.title2': "What's Next?",
  'generalLayoutPage.subtitle2': 'Open a case with passenger information',
  'generalLayoutPage.passengerDisclaimer':
    'Your reservation information may no longer be accessible to the system. In these case, you will need to escalate a case through this option.',
  'generalLayoutPage.required': 'This is a mandatory field',
  'generalLayoutPage.Invalid': 'Invalid format',
  'additionalInfoPage.heading': 'Reservation Code',
  'additionalInfoPage.headingNonRevenue': 'Reservation Code - Staff Ticket',
  'additionalInfoPage.pageSection': 'Additional information required',
  'additionalInfoPage.titlePassengerSection':
    'The refund request will apply to all passengers below:',
  'additionalInfoPage.disclaimerPassenger':
    'If you want to request a refund for specific passengers, you will need to contact us at the Reservation Center to separate them from the reservation; you will be able to come back and make the request.',
  'additionalInfoPage.titleRequesterSection': 'Applicant information',
  'additionalInfoPage.descriptionText':
    'We need your contact information to proceed with the refund request. This information will only be used to contact you in relation to the request.',
  'additionalInfoPage.inputName': 'First Name',
  'additionalInfoPage.helperTextName': 'Enter first name',
  'additionalInfoPage.inputLastname': 'Last name',
  'additionalInfoPage.helperTextLastname': 'Enter last name',
  'additionalInfoPage.inputEmail': 'E-mail',
  'additionalInfoPage.helperTextEmail': 'Enter email',
  'additionalInfoPage.inputConfirmEmail': 'Confirm email',
  'additionalInfoPage.helperTextConfirmEmail': 'Re-enter email',
  'additionalInfoPage.confirmError': 'The emails do not match',
  'additionalInfoPage.phone1': 'Phone number',
  'additionalInfoPage.phone2': 'Phone number',
  'additionalInfoPage.samePhone': 'Enter a different phone number',
  'additionalInfoPage.inputPhoneCode': 'Country code',
  'additionalInfoPage.inputPhone': 'Phone number',
  'additionalInfoPage.helperTextPhone': 'Enter number',
  'additionalInfoPage.titleFOPSection': 'Original form of payment',
  'additionalInfoPage.FOPDescription':
    'This reservation was purchased with the following credit card. If the request is approved we will use it to make the refund.',
  'additionalInfoPage.connectMilesDescription':
    'If the request is approved, we will process the refund to the original payment method and credit the miles back to the ConnectMiles account.',
  'additionalInfoPage.FOPNumber':
    'This reservation was purchased using a credit card. If the request is approved, we will use it to make the refund.',
  'additionalInfoPage.creditCardTitle': 'Credit Card',
  'additionalInfoPage.milesTitle': 'Miles',
  'additionalInfoPage.creditCardDisclaimer':
    'If this card has expired when making the refund, we will contact you to update the information.',
  'additionalInfoPage.titleRefundReason': 'Reason for your refund',
  'additionalInfoPage.refundSubtitle':
    'Select the reason for your refund request. We may need you to provide some additional documentation. Select the reason below that best applies to your case and follow the steps.',
  'additionalInfoPage.inputRefund': 'Reason for your request',
  'additionalInfoPage.helperTextRefund':
    'Please select the reason for your  request',
  'additionalInfoPage.refundReasonVoluntaryChange':
    'I want to cancel according to the conditions of my ticket',
  'additionalInfoPage.refundReasonInvoluntaryChange':
    'Cancellation or involuntary change to my flight itinerary by Copa Airlines',
  'additionalInfoPage.refundReasonNaturalDisaster': 'Natural disaster',
  'additionalInfoPage.refundReasonDeath': 'Death',
  'additionalInfoPage.refundReasonTerminalillness':
    'Critical or terminal illness',
  'additionalInfoPage.refundReasonOther': 'Other',
  'additionalInfoPage.refundReasonImmigrationIssues': 'Immigration issues',
  'additionalInfoPage.titleSelectedReason': 'Reason: ',
  'additionalInfoPage.descriptionFareConditions':
    'Your request will be processed according to the conditions of the purchased fare family. ',
  'additionalInfoPage.descriptionFareConditionsURL':
    'Learn more about our fare families.',
  'additionalInfoPage.descriptionDeath':
    'For these cases, it is required to attach copy of the death certificate the deceased passengers. You can attach the necessary documents below.',
  'additionalInfoPage.description Natural Disaster':
    'Applies in cases where a natural disaster (eg storm, earthquake, volcano eruption) significantly affects a city or region related to your itinerary.',
  'additionalInfoPage.descriptionCopaAffectedMyTrip':
    'It only applies if any of your flights was modified by Copa due to delays, cancellations, or irregular operations. Otherwise, your request will be rejected.',
  'additionalInfoPage.descriptionMigratoryIssues':
    'Applies if you were unable to travel because you did not meet the immigration requirements for the country of destination.',
  'additionalInfoPage.descriptionTerminalDisease':
    "For these cases, it's required to attach a letter (on letterhead) from a licensed physician confirming that travel was not recommended within the validity of the ticket (one year of ticket's issued date) due to illness.",
  'additionalInfoPage.descriptionOther': 'Please add additional details.',
  'additionalInfoPage.requesterIdUploadDocumentsTitle': 'Attach documents',
  'additionalInfoPage.uploadDocumentsTitle': 'Attach documents',
  'additionalInfoPage.uploadDocumentsFormats':
    'Allowed documents: .pdf, .jpg, .png',
  'additionalInfoPage.uploadDocumentsSize': 'File size should not exceed: 4MB',
  'additionalInfoPage.uploadDocumentsHelper1':
    'Drag files here or click to upload',
  'additionalInfoPage.uploadDocumentsHelper2':
    'Drop the files here to upload them',
  'additionalInfoPage.uploadDocumentsErrorFormat':
    'This file format is not allowed.',
  'additionalInfoPage.uploadDocumentsErrorLimit': 'You cannot add more files.',
  'additionalInfoPage.uploadDocumentsErrorSize':
    'The file size exceeds the allowed limit.',
  'additionalInfoPage.uploadDocumentsLoading': 'Loading',
  'additionalInfoPage.uploadDocumentsUploadError': 'Loading Error',
  'additionalInfoPage.uploadDocumentsGeneralError':
    'Some files could not be uploaded. Try again.',
  'additionalInfoPage.uploadDocumentsErrorRequired':
    'You must attach a document.',
  'additionalInfoPage.checkbox1':
    'I declare that I have the authority to request this refund. I understand and agree that approval of my refund is subject to the fare rules regulations.',
  'additionalInfoPage.checkbox2':
    "I freely, expressly and unequivocally authorize Copa Airlines to collect, use and process the information entered here to process my refund request. I also authorize the transfer of my information to affiliated companies and authorized third parties as necessary to complete the reimbursement. I declare, acknowledge and accept the company's Privacy Policies.",
  'additionalInfoPage.button': 'Continue',
  'additionalInfoPage.passengerDisclaimer1':
    'If you want to request a refund for specific passengers, you will need to contact us at the ',
  'additionalInfoPage.passengerDisclaimerURL': 'Reservation Center',
  'additionalInfoPage.passengerDisclaimer2':
    ' to separate them from the reservation; you will be able to come back and make the request.',
  'additionalInfoPage.relationship': 'Relation with passengers',
  'additionalInfoPage.relationshipPlaceholder': 'Choose',
  'additionalInfoPage.relationshipOther': 'Describe your relation',
  'additionalInfoPage.relationshipOtherPlaceholder': 'Describe your relation',
  'additionalInfoPage.relationshipOption1':
    'Direct family (parent, child or spouse)',
  'additionalInfoPage.relationshipOption2': 'I am one of the passengers',
  'additionalInfoPage.relationshipOption3': 'Other',
  'warningModalLayout.header':
    'By continuing with the request, your itinerary will be cancelled. Do you want to continue?',
  'warningModalLayout.passengerDisclaimer':
    'Upon receipt your refund request, all flights associated with your reservation will be canceled immediately. If you change your mind and wish to travel again, we cannot guarantee the availability of your current flights.',
  'warningModalLayout.passengerDisclaimerNonRevenue':
    'You have a staff ticket. Cancelling your flights will result in the refund being issued to Copa Airlines and all your flights will be cancelled.',
  'warningModalLayout.title': 'Refund Applicant',
  'warningModalLayout.fullName': 'Full Name',
  'warningModalLayout.email': 'Email',
  'warningModalLayout.phone': 'Phone number',
  'warningModalLayout.reason': 'Reason for your request',
  'warningModalLayout.passengersTitle': 'Travelers applying for refund',
  'warningModalLayout.passengeName': 'Name',
  'warningModalLayout.dateOfBirth': 'Date of birth',
  'warningModalLayout.eTicket': 'Ticket number',
  'warningModalLayout.button1': 'Go Back',
  'warningModalLayout.button2': 'Request refund',

  // ADD PASSENGERS PAGE TRANSLATIONS
  'addPassengersPage.title': 'Request a refund',
  'addPassengersPage.subtitle': 'Add travelers',
  'addPassengersPage.descriptionText':
    'Please enter the information of all travelers in the reservation to request a refund. The request will be made for all passengers in the reservation.',
  'addPassengersPage.paxTitle': 'Traveler',
  'addPassengersPage.inputReservation': 'Reservation Code',
  'addPassengersPage.placeholderReservation': 'Enter reservation code',
  'addPassengersPage.inputName': 'First Name',
  'addPassengersPage.placeholderName': 'Enter first name',
  'addPassengersPage.inputLastname': 'Last name',
  'addPassengersPage.placeholderLastname': 'Enter last name',
  'addPassengersPage.addPaxButton': 'Add traveler',
  'addPassengersPage.inputTicketCode': 'E-ticket number',
  'addPassengersPage.placeholderTicketCode': 'Enter e-ticket number',
  'addPassengersPage.helperTicketCode': 'The e-ticket number begins with 230',
  'addPassengersPage.errorTicketCode':
    'Invalid format. The e-ticket number must have 13 digits and begin with 230',
  'addPassengersPage.cta': 'Continue',
  'addPassengersPage.remove': 'Remove',
  'addPassengersPage.alertError':
    'A refund request already exists for this e-ticket number. This is your case number {caseNumber}, you can check the status ',
  'addPassengersPage.alertErrorLink': 'here.',
  'addPassengersPage.caseAlreadyOpenLabelError':
    'A refund request already exists for this e-ticket number. ',
  'addPassengersPage.emdErrorScenario':
    'Invalid format. Use the e-ticket number of your purchase.',
  // CALL CENTER SPECIAL LINK
  'callCenterSpecialLink.title': 'Find Reservation',
  'callCenterSpecialLink.description':
    'Enter the reservation code or e-ticket number, and the last name to make the refund request.',
  'callCenterSpecialLink.subtitle': 'Enter your reservation information',
  'callCenterSpecialLink.requiredMessage': 'This is a mandatory field',
  'callCenterSpecialLink.invalidFormatMessage': 'Invalid format',
  'callCenterSpecialLink.button': 'Find Reservation',
  'callCenterSpecialLink.moreOptionTitle': "What's Next?",
  'callCenterSpecialLink.moreOptionSubtitle':
    'Open a case with passenger information',
  'callCenterSpecialLink.moreOptionBody':
    'In some cases, your reservation information may no longer be accessible to the system. In these cases, you will need to escalate a case through this option. ',

  // ERROR PAGE
  'errorPage.header': 'Request a refund',
  'errorPage.alert': "We're sorry!",
  'errorPage.title': "We're sorry! An error has occurred.",
  'errorPage.subtitle':
    'Due to technical difficulties we were unable to proceed with the creation of your refund request. Please start over.',
  'errorPage.cta': 'Retry',

  // EXCEPTIONS PAGE
  'exceptionsPage.title':
    'Sorry, your reservation is not eligible to open a refund request',
  'exceptionsPage.subtitle': 'Some reasons may be:',
  'exceptionsPage.bullet1': 'Your reservation is pending payment.',
  'exceptionsPage.bullet2': 'You have a group reservation.',
  'exceptionsPage.bullet3':
    'Your reservation was purchased with miles, is associated to a ConnectMiles member or PreferMember Silver, and is subject to a penalty charge.',
  'exceptionsPage.bullet4':
    'Your itinerary has flights operated by another airline',
  'exceptionsPage.bullet5': 'Your reservation has been canceled.',
  'exceptionsPage.bullet6': 'Your reservation has been refunded.',
  'exceptionsPage.bullet7': 'You’ve already completed your travel itinerary.',
  'exceptionsPage.bullet8':
    'Your reservation was canceled due to a claim made by the bank.',
  'exceptionsPage.cta': 'Back to copa.com',

  //  REASON SECTION PAGE
  'reasonSectionPage.title': 'Reason for your refund',
  'reasonSectionPage.subtitle':
    'Select the reason for your refund request. We may need you to provide some additional' +
    ' documentation. Select the reason below that best applies to your case and follow the steps.',
  'reasonSectionPage.field1': 'Reason for your request',
  'reasonSectionPage.placeholder':
    'Please select the reason for your refund request ',
  'reasonSectionPage.invalidFormatError': 'Invalid format',
  'reasonSectionPage.isRequiredError': 'This is a mandatory field',
  'reasonSectionPage.refundReasonOption1':
    'I want to cancel according to the conditions of my ticket',
  'reasonSectionPage.refundReasonOption2':
    'Copa Airlines made an involuntary change to my flight itinerary.',
  'reasonSectionPage.refundReasonOption3': 'Natural disaster',
  'reasonSectionPage.refundReasonOption4': 'Death',
  'reasonSectionPage.refundReasonOption5': 'Other',
  'reasonSectionPage.titleSelectedReason': 'Reason: ',
  'reasonSectionPage.deathDescription':
    'For these cases, it is necessary to attach the death certificate of one or more passengers,' +
    ' issued by the relevant autority of the country. You can attach the necessary documents below.',
  'reasonSectionPage.naturalDisasterDescription':
    'Applies in cases where a natural disaster (eg storm, earthquake,' +
    ' volcano eruption) significantly affects a city or region related to your itinerary.',
  'reasonSectionPage.affectedFlyOptionDescription':
    'It only applies if any of your flights was modified by Copa due to' +
    ' delays, cancellations, or irregular operations. Otherwise, your request will be rejected.',
  'reasonSectionPage.otherBoxDescription': 'Please add additional details.',
  'reasonSectionPage.descriptionLabel': 'Explain the situation',
  'reasonSectionPage.descriptionPlaceholder': 'Submit information',
  'reasonSectionPage.checkbox1':
    'I declare that I have the authority to request this refund. ' +
    'I understand and agree that approval of my refund is subject to the fare rules regulations.',
  'reasonSectionPage.checkbox2':
    'I authorize Copa Airlines to use the information entered here.',
  'reasonSectionPage.button': 'Continue',

  // INTERSITIAL
  'intersitial.loading': 'Loading',
  'intersitial.alertError':
    "Sorry! We couldn't find your case. Please try again.",
  'intersitialWCAG.alertError':
    'We cannot find your case with the information provided. Please try again by changing the data.',

  // PAYMENT METHOD
  'paymentMethod.countryPurchase': 'Country of purchase',
  'paymentMethod.countryPurchasePlaceholder': 'Select country',
  'paymentMethod.PaymentMethod': 'Form of payment',
  'paymentMethod.PaymentMethodPlaceholder': 'Select form of payment',
  'paymentMethod.TransferCash': 'Bank Transfer/Cash',
  'paymentMethod.CreditCard': 'Credit Card',
  'paymentMethod.Other': 'Other',

  // COUNTRIES
  'countries.BR': 'Brazil',
  'countries.MX': 'Mexico',
  'countries.AR': 'Argentina',
  'countries.CO': 'Colombia',
  'countries.CA': 'Canada',
  'countries.CL': 'Chile',
  'countries.PA': 'Panama',
  'countries.EU': 'Europe',
  'countries.CU': 'Cuba',
  'countries.PE': 'Peru',
  'countries.NI': 'Nicaragua',
  'countries.EC': 'Ecuador',
  'countries.HT': 'Haiti',
  'countries.GY': 'Guyana',
  'countries.BB': 'Barbados',
  'countries.TT': 'Trinidad and Tobago',
  'countries.OTHER': 'Other',

  // 404 PAGE NOT FOUND

  'pageNotFound.button': 'Return to copa.com',
  'pageNotFound.Title': "We couldn't find this page",
  'pageNotFound.Subtitle':
    "We're sorry, the page you tried to visit does not exist on our website.",
  'pageNotFound.CTA': 'Go Back',

  // 404 PAGE NOT FOUND WCAG

  'pageNotFoundWCAG.container':
    "We're sorry. The page you tried to access does not exist. Please return to copa.com to try again.",

  //  REASON SECTION PAGE WCAG
  'reasonSectionPageWCAG.refundSelect':
    'Dropdown, use the up and down arrow keys to select the reason for the refund request.',
  'reasonSectionPageWCAG.checkbox1':
    'Press to indicate that you declare that you have the power to request this refund. That you understand ' +
    'and accept that the approval of the refund is subject to the rules of your fare and the copa airlines refund policy.',
  'reasonSectionPageWCAG.checkbox2':
    'Press to indicate that you agree that the information entered on this ' +
    'form will only be used for purposes of requesting reimbursements.',
  'reasonSectionPageWCAG.buttonCta':
    'Button. Press ENTER to continue with the refund request.',
  'reasonSectionPageWCAG.textArea':
    'Use this text box to describe the reasons why you want to make the refund.' +
    ' You can write up to a limit of 250 characters.',

  // ADD PASSENGERS PAGE WCAG
  'addPassengersPageWCAG.generalDescription':
    'Page to add information of the passengers who are going to request the refund. The refund will be processed for all passengers on the reservation. This request is only valid for reservations with a maximum of 9 passengers.',
  'addPassengersPageWCAG.inputName':
    'Enter the name of the passenger number [X], only letters and blank spaces are allowed.',
  'addPassengersPageWCAG.reservationCode':
    'You can add the reservation code to be refunded. This reservation code has 6 characters',
  'addPassengersPageWCAG.inputLastname':
    'Enter the last name of the passenger number [X], only letters and blank spaces are allowed.',
  'addPassengersPageWCAG.inputETicket':
    'Enter the electronic ticket of the passenger number [X], only 13 numbers are allowed and it must start with 230.',
  'addPassengersPageWCAG.addPaxButton':
    'Add more passengers button. Press enter to add another passenger to the request.',
  'addPassengersPageWCAG.removePaxButton':
    'Button to delete passenger. Press enter to remove passenger from the request',
  'addPassengersPageWCAG.cta':
    'Button to continue. Press enter to continue with the request.',
  'addPassengersPageWCAG.alertError':
    'A case number already exists for this e-ticket. Your case number is {caseNumber}.' +
    ' Go to the check status page to check the status of your application.',

  //  EXCEPTIONS PAGE WCAG
  'exceptionsPageWCAG.container':
    'We cannot process your refund, since this reservation was purchased through a third party' +
    ' (Example: Travel Agency, Travel Page). You must contact the company with which you made the' +
    ' purchase to request a refund.',

  // WCAG Text
  'additionalInfoPageWCAG.inputName':
    'Type your given name, only letters and white spaces are allowed.',
  'additionalInfoPageWCAG.inputLastname':
    'Type your last name or ´surname´, only letters and white spaces are allowed.',
  'additionalInfoPageWCAG.inputEmail':
    'Write the email where you will want to receive the confirmation of the refund, and where we will contact you if necessary.',
  'additionalInfoPageWCAG.inputConfirmEmail':
    'Confirm the email where you want to receive the confirmation of the refund, and where we will contact you if necessary.',
  'additionalInfoPageWCAG.inputPhoneCode1':
    'Dropdown, select the option for your country code.',
  'additionalInfoPageWCAG.inputPhone1':
    'Type your phone number. This is a numeric field.',
  'additionalInfoPageWCAG.inputPhoneCode2':
    'Dropdown, select the option for your country code of a second number.',
  'additionalInfoPageWCAG.inputPhone2':
    'Type a second phone number. This is a numeric field.',
  'additionalInfoPageWCAG.generalDescription':
    'Page to request the refund of your reservation. To complete the application, please fill in the additional information we require. Some fields are mandatory and others are optional. Depending on the reason you selected for the refund, you must attach certain documents. Please ensure that the information is accurate, as once the application is completed, you will not be able to change it. The refund request is made for all passengers within the reservation, if you only want to request a refund for certain passengers, you must first call our reservation center to separate the passengers to another reservation.',
  'additionalInfoPageWCAG.paxContainer':
    'This is the list of all passengers who are requesting the refund: passenger number one [Name and Surname, Date of Birth, ticket #]; passenger number two [First and Last Name, Date of Birth, ticket #]; passenger number three [Name and Surname, Date of Birth, ticket #], passenger number four [Name and Surname, Date of Birth, ticket #], etc.',
  'additionalInfoPageWCAG.formOfPaymentContainer':
    'The original payment method used to purchase this reservation was a [type] card ending in [last 4 digits]. This will be the card to which the refund will be made if the request is accepted.',
  'additionalInfoPageWCAG.reasonOfTheRefund':
    'Dropdown, use the up and down arrow keys to select the reason for the refund request.',
  'additionalInfoPageWCAG.legalCheckBox1':
    'Press to indicate that you declare that you have the power to request this refund. That you understand and accept that the approval of the refund is subject to the rules of your fare and the copa airlines refund policy.',
  'additionalInfoPageWCAG.legalCheckBox2':
    'Press to indicate that you agree that the information entered on this form will only be used for purposes of requesting reimbursements.',
  'additionalInfoPageWCAG.uploadDocuments':
    'You can attach your files to support your refund here. You can add up to {numberOfDocs} files which should not weigh more than 4 MB. It only allows attaching documents in .pdf format; .jpg and .png. After attaching them you can remove any document by pressing the X next to each of them.',
  'additionalInfoPageWCAG.uploadDocumentsDelete':
    'Click here to remove this document.',
  'additionalInfoPageWCAG.uploadDocumentsReload':
    'It looks like there was an error with the file. Click here to reload it.',
  'additionalInfoPageWCAG.uploadDocumentsFormatError':
    'The format you attached is not correct. Please attach documents that are .pdf; .jpg or .png',
  'additionalInfoPageWCAG.uploadDocumentsLimitError':
    'Error message - Upload Limit You can only upload up to {numberOfDocs} files. Please delete one of the added files to attach the new one.',
  'additionalInfoPageWCAG.uploadDocumentsSizeError':
    'Error message - Size Limit The file you tried to add is too large. Only files up to 4 MB are allowed',
  'additionalInfoPageWCAG.uploadDocumentsRequiredError':
    'You must attach a file. Please attach a file to continue',
  'additionalInfoPageWCAG.uploadDocumentsDocumentError':
    'Some documents you attached do not have the correct format. Delete them using the X next to the file and add a new  .pdf, .jpg or .png file',
  'additionalInfoPageWCAG.cta':
    'Button. Press ENTER to continue with the refund request.',
  'additionalInfoPageWCAG.relationship':
    'If you are not the owner of the reservation. Please describe your relationship with the passengers. This is a multiple choice field.',
  'additionalInfoPageWCAG.relationshipOther':
    'If you chose the other option, please describe your relationship with the passenger. This is a text field.',
  'warningModalWCAG.modal':
    'Refund request confirmation section. You will find more information below.',
  'warningModalWCAG.container':
    'Summary of your Refund Request. If you proceed with the refund request, the entire itinerary will be cancelled. This refund request was made by [name] due to [Reason for request], for the following passengers: passenger number one [name], passenger number two [name], etc.',
  'warningModalWCAG.ctaBack':
    'Button to return to additional information page. Press enter to return to the previous page.',
  'warningModalWCAG.ctaConfirm':
    'Request refund button. Press the button to confirm the refund request and cancel your itinerary.',
  // ERROR PAGE WCAG
  'errorPageWCAG.container':
    'Due to technical difficulties we were unable to proceed with the creation of your refund request. Please start over.',
  'errorPageWCAG.cta': 'Retry Button',
  // MODAL NPAFOPCASH
  'modalNRAndAFOPCash.title': 'The reservation was paid in cash',
  'modalNRAndAFOPCash.text':
    'We recommend that you request your refund at one of our sales offices for immediate processing. Through this form, the refund may take up to two months to be processed.',
  'modalNRAndAFOPCash.secundaryButton': 'Continue with my request',
  'modalNRAndAFOPCash.primaryButton': 'Cancel',
  'modalNRAndAFOPCash.primaryButtonWCAG':
    'Press if you want to cancel and return to the beginning of the flow',
  'modalNRAndAFOPCash.secundaryButtonWCAG':
    'Press to continue with the refund request',
  // ROUTES
  'links.changesAndRefunds': '/en-gs/customer-service/changes-and-refunds/',
  // Modal Non-Refundable Fares
  'modalNonRefundableFares.title': 'This ticket is non-refundable',
  'modalNonRefundableFares.descriptionStart':
    'The fare family you purchased is non-refundable. However, exceptions may apply for the following circumstances:',
  'modalNonRefundableFares.firstBullet': 'Death (certificate required)',
  'modalNonRefundableFares.secondBullet':
    'Cancellations or changes made by Copa Airlines that impact your itinerary ',
  'modalNonRefundableFares.descriptionEnd':
    'If you consider that your case could apply to one of these exceptions, you can continue with the process.',
  'modalNonRefundableFares.continueButton': 'Request for exception',
  'modalNonRefundableFares.continueButtonWCAG':
    'Press to continue with exceptions',
  'modalNonRefundableFares.cancelButton': 'Go back',
  'modalNonRefundableFares.cancelButtonWCAG':
    'Press if you want to cancel and return to the beginning of the flow',
}
