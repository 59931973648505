const useStyles = {
  heading: {
    borderBottom: "1px solid #CCCCCB",
    borderTop: "1px solid #CCCCCB",
    padding: {
      xs: "39px 60px 32px 16px",
      sm: "39px 106px 32px 104px",
      md: "39px 172px 32px 169px",
      lg: "39px 212px 32px 212px",
    },
  },
}

export default useStyles
