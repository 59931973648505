/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Divider, Grid, Box } from '@mui/material'
import { Controller, UseFieldArrayRemove, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'
import { InputRegular } from '@copa/design-system-factory.input-regular'
import { Typography } from '@copa/design-system-factory.typography'
import { Button } from '@copa/design-system-factory.buttons.button'
import { theme } from '@copa/design-system-factory.theme'
import InputAdornment from '@mui/material/InputAdornment'
import CheckIcon from '@mui/icons-material/Check'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import VALIDATIONS from '../../../../utils/validations'
import {
  createErrorsMessages,
  createErrorsMessagesTicketCode,
} from '../../../../utils/errorsMessages'
import AlertMessage from '../AlertMessage'
import useStyles from './styles'
import REGEX from '../../../../utils/regex'
import { useGetRefund } from '../../../../hooks/useAPICall'
import Spinner from '../../../../components/Spinner/Spinner'
import { sendGACustomEvent } from '../../../../utils/functions'
import { INPUT_FLOATING_HELPER_TEXT } from '../../../../styles/inputs.style'

// TODO: Unify in a separate file this types with AddPassengersPage types
type Props = {
  passengerNumber: `addPassenger.${number}`
  form: Pick<
    ReturnType<typeof useForm<FromValue>>,
    'control' | 'watch' | 'clearErrors'
  > & {
    formErrors: ReturnType<typeof useForm<FromValue>>['formState']['errors']
  }
  index: number
  remove: UseFieldArrayRemove
  fields: number
}

type FromValue = {
  name: string
  reservationCode?: string
  addPassenger: {
    id?: string
    givenName: string
    lastName: string
    eTicket: string
  }[]
}

const Passenger: FC<Props> = ({
  passengerNumber,
  form,
  index,
  remove,
  fields,
}) => {
  const { formatMessage } = useIntl()
  const { classes: styles } = useStyles()
  const { control, formErrors, watch, clearErrors } = form

  const defaultTitle = `${formatMessage({
    id: 'addPassengersPage.paxTitle',
  })} ${index + 1}`

  const [passengerName, setPassengerName] = useState('')

  const [passengerLastName, setPassengerLastName] = useState('')

  const [passengerFullName, setPassengerFullName] = useState()

  const [showTicketError, setShowTicketError] = useState(false)

  const updatePassengerName = (e: KeyboardEvent) => {
    const inputElement = e.target as HTMLInputElement
    setPassengerName(inputElement.value)
  }

  const updatePassengerLastName = (e: any) => {
    if (passengerName === defaultTitle) {
      setPassengerName('')
      setPassengerLastName(e.target.value)
    } else {
      setPassengerLastName(e.target.value)
    }
  }

  useEffect(() => {
    if (!passengerName && !passengerLastName) {
      setPassengerFullName(defaultTitle as any)
    } else {
      setPassengerFullName(`${passengerName} ${passengerLastName}` as any)
    }
  }, [defaultTitle, passengerName, passengerLastName])

  const errorsMessages = createErrorsMessages(formatMessage)
  const errorsMessagesTicketCode = createErrorsMessagesTicketCode(formatMessage)

  const {
    responseData: refundData,
    request,
    error: refundError,
    isLoading,
  } = useGetRefund({
    skip: true,
  })

  const eTicketAlreadyUsed = (eTicket: string) => {
    if (eTicket.length === 13 && String(eTicket).match(REGEX.E_TICKET)) {
      request({ eTicket })
    }
  }

  const caseAlreadyOpen = () => !showTicketError

  const watchTicket = watch(`${passengerNumber}.eTicket`)

  useEffect(() => {
    eTicketAlreadyUsed(watchTicket)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTicket])

  const ticketInput = useRef(null)

  useEffect(() => {
    setShowTicketError(!!refundData)
    if (!refundData) {
      clearErrors(`${passengerNumber}.eTicket`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundData])

  useEffect(() => {
    if (refundData) {
      sendGACustomEvent('ETKT with case')
    }
  }, [refundData])

  const navigate = useNavigate()

  useEffect(() => {
    const refundNotFound = [404, 400].includes(
      (refundError as AxiosError)?.response?.status || 0
    )

    if (refundError && !refundNotFound) {
      console.error(refundError)
      navigate('/error')
    }
  }, [refundError, navigate])

  const showInputIcon = (value: string) => {
    let icon

    const isValidInput =
      value && !formErrors?.addPassenger?.[index]?.eTicket?.type

    if (isValidInput) {
      icon = (
        <CheckIcon
          style={{
            color: theme.palette.grey['400'],
            fontSize: '18px',
          }}
        />
      )
    }

    if (isValidInput && isLoading) {
      icon = <Spinner color={theme.palette.grey['500']} />
    }

    return icon
  }
  return (
    <>
      <Grid container alignItems="start" justifyContent="space-between">
        <Typography variant="h3" className={styles.title}>
          {passengerFullName}
        </Typography>
        {index !== 0 && (
          <Button
            aria-label={formatMessage({
              id: 'addPassengersPageWCAG.removePaxButton',
            })}
            style={{
              width: '105px',
              height: '24px',
              fontSize: '12px',
              borderRadius: '4px',
              marginTop: '50px',
            }}
            variant="transparentPrimaryMain"
            onClick={() => remove(index)}
          >
            <CloseIcon
              style={{
                width: '14px',
                marginRight: '8px',
              }}
            />
            {formatMessage({ id: 'addPassengersPage.remove' })}
          </Button>
        )}
        <AlertMessage
          show={showTicketError}
          message={formatMessage(
            {
              id: 'addPassengersPage.alertError',
            },
            { caseNumber: refundData?.caseNumber }
          )}
          wcagDescription={formatMessage(
            {
              id: 'addPassengersPageWCAG.alertError',
            },
            {
              caseNumber: refundData?.caseNumber,
            }
          )}
          actionlink={`/check-status?case_number=${refundData?.caseNumber}`}
          actionText={formatMessage({ id: 'addPassengersPage.alertErrorLink' })}
        />
      </Grid>
      <Box className={styles.fieldContainer}>
        <Box sx={INPUT_FLOATING_HELPER_TEXT}>
          <Controller
            control={control}
            name={`${passengerNumber}.givenName`}
            rules={VALIDATIONS.NAME_LASTNAME}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                id={`${passengerNumber}.givenName`}
                label={formatMessage({
                  id: 'addPassengersPage.inputName',
                })}
                placeholder={formatMessage({
                  id: 'addPassengersPage.placeholderName',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value &&
                        !formErrors?.addPassenger?.[index]?.givenName?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey['400'],
                              fontSize: '18px',
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 30,
                  'aria-label': formatMessage({
                    id: 'addPassengersPageWCAG.inputName',
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.givenName &&
                  errorsMessages[
                    formErrors?.addPassenger?.[index]?.givenName
                      ?.type as keyof typeof errorsMessages
                  ]
                }
                required
                value={value}
                handleChange={onChange}
                onKeyUp={updatePassengerName}
                onBlur={onBlur}
                hasErrors={!!formErrors?.addPassenger?.[index]?.givenName}
              />
            )}
          />
        </Box>
        <Box sx={INPUT_FLOATING_HELPER_TEXT}>
          <Controller
            control={control}
            name={`${passengerNumber}.lastName`}
            rules={VALIDATIONS.NAME_LASTNAME}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                id={`${passengerNumber}.lastName`}
                label={formatMessage({
                  id: 'addPassengersPage.inputLastname',
                })}
                placeholder={formatMessage({
                  id: 'addPassengersPage.placeholderLastname',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value &&
                        !formErrors?.addPassenger?.[index]?.lastName?.type && (
                          <CheckIcon
                            style={{
                              color: theme.palette.grey['400'],
                              fontSize: '18px',
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 30,
                  'aria-label': formatMessage({
                    id: 'addPassengersPageWCAG.inputLastname',
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.lastName &&
                  errorsMessages[
                    formErrors?.addPassenger?.[index]?.lastName
                      ?.type as keyof typeof errorsMessages
                  ]
                }
                required
                value={value}
                handleChange={onChange}
                onKeyUp={updatePassengerLastName}
                onBlur={onBlur}
                hasErrors={!!formErrors?.addPassenger?.[index]?.lastName}
              />
            )}
          />
        </Box>
        <Box sx={INPUT_FLOATING_HELPER_TEXT}>
          <Controller
            control={control}
            name={`${passengerNumber}.eTicket`}
            rules={{
              validate: {
                'case-already-open': caseAlreadyOpen,
                isValidETicket: eticket => REGEX.E_TICKET.test(eticket),
              },
              ...VALIDATIONS.E_TICKET,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                ref={ticketInput}
                id={`${passengerNumber}.eTicket`}
                label={formatMessage({
                  id: 'addPassengersPage.inputTicketCode',
                })}
                placeholder={formatMessage({
                  id: 'addPassengersPage.placeholderTicketCode',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showInputIcon(value)}
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 13,
                  'aria-label': formatMessage({
                    id: 'addPassengersPageWCAG.inputETicket',
                  }),
                }}
                helperText={
                  formErrors?.addPassenger?.[index]?.eTicket
                    ? errorsMessagesTicketCode[
                        formErrors?.addPassenger?.[index]?.eTicket
                          ?.type as keyof typeof errorsMessagesTicketCode
                      ]
                    : formatMessage({
                        id: 'addPassengersPage.helperTicketCode',
                      })
                }
                required
                value={value}
                handleChange={onChange}
                onBlur={onBlur}
                hasErrors={!!formErrors?.addPassenger?.[index]?.eTicket}
              />
            )}
          />
        </Box>
      </Box>
      {fields !== index + 1 && <Divider style={{ marginTop: '35px' }} />}
    </>
  )
}

export default Passenger
