import React, { useRef, useEffect, FC } from 'react'
import Hyperlink from '../Hyperlink'
import { sendGACustomEvent } from '../../utils/functions'
import { HyperlinkProps } from '../Hyperlink/Hyperlink'

type Props = HyperlinkProps & { action: string }

const HyperlinkWithGA: FC<Props> = ({ action, ...props }) => {
  const link = useRef<HTMLAnchorElement | null>(null)
  const sendGA = () => {
    sendGACustomEvent(action)
  }
  useEffect(() => {
    const currentLink = link.current
    if (currentLink) {
      currentLink.addEventListener('click', sendGA)
    }
    return () => {
      if (currentLink) {
        currentLink.removeEventListener('click', sendGA)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Hyperlink {...props} ref={link} />
}

export default HyperlinkWithGA
