import React from 'react'
import { Container, createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import './index.css'
import App from './App'
import theme from './theme/theme'
import { GeneralContextProvider } from './contexts/GeneralContextProvider'

const container = document.getElementById('root') as Container
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <GeneralContextProvider>
          <CssBaseline />
          <App />
        </GeneralContextProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
)
