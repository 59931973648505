import { makeStyles } from "tss-react/mui"

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme) => ({
  subtmitButton: {},
  submitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "auto",
    columnGap: "24px",
    "& > div": {
      color: "pink",
      gridColumn: " 2 / 3",
      margin: "82px 0 90px",
      width: "99%",
    },
    [theme.breakpoints.down("lg")]: {
      "& > div": {
        margin: "68px 0 90px",
      },
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      "& > div": {
        gridColumn: " 1 / 2",
        margin: "62px 0 90px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        margin: "54px 0 90px",
      },
    },
  },
  nextContainer: {
    borderRadius: "4px",
    paddingTop: "56px !important",
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "48px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: "40px !important",
    },
    "& > div": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
}))

export default useStyles
