const modalStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
  "& h2": {
    marginBottom: "24px",
  },
  "& p": {
    marginBottom: "7px",
  },
}

export default modalStyles
