import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { Button } from '@copa/design-system-factory.buttons.button'
import ResponsiveContainer from '../../components/ResponsiveContainer'
import HeaderBlue from '../../components/HeaderBlue'
import { getLinkToCopa, goToCOPA } from '../../utils/functions'
import { useAppSelectGeneralInfo } from '../../hooks/store-hooks'
import useStyles from './style'
import PageNotFoundErrorIcon from './PageNotFoundErrorIcon'

const PageNotFound: FC = () => {
  const { formatMessage } = useIntl()
  const { classes: styles } = useStyles()
  const generalContext = useAppSelectGeneralInfo()
  const url = getLinkToCopa(generalContext.language)

  return (
    <>
      <HeaderBlue
        title={formatMessage({ id: 'pageNotFound.button' })}
        urlCopa={url}
      />
      <ResponsiveContainer className={styles.container}>
        <PageNotFoundErrorIcon />
        <Typography variant="h2" className={styles.title} tabIndex={0}>
          {formatMessage({ id: 'pageNotFound.Title' })}
        </Typography>
        <Box className={styles.subtitle}>
          {formatMessage({ id: 'pageNotFound.Subtitle' })}
        </Box>
        <Box className={styles.ctaContainer}>
          <Button
            onClick={() => {
              goToCOPA(generalContext.language)
            }}
            size="large"
            width="10rem"
          >
            {formatMessage({ id: 'pageNotFound.CTA' })}
          </Button>
        </Box>
      </ResponsiveContainer>
    </>
  )
}

export default PageNotFound
