/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Grid } from '@mui/material'
import { Controller } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import CheckIcon from '@mui/icons-material/Check'
import { InputRegular } from '@copa/design-system-factory.input-regular'
import { theme } from '@copa/design-system-factory.theme'
import REGEX from '../../../../../utils/regex'
import { createErrorsMessages } from '../../../../../utils/errorsMessages'
import { INPUT_FLOATING_HELPER_TEXT } from '../../../../../styles/inputs.style'

function FormOfPaymentAR({ control, formErrors }) {
  const { formatMessage } = useIntl()

  const errorsMessages = createErrorsMessages(formatMessage)

  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginTop: '28px', marginBottom: '36px' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            ...INPUT_FLOATING_HELPER_TEXT,
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.name"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ALPHANUMERIC_SPACE_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.name && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey['400'],
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: 'formOfPayment.placeholderBankName',
                })}
                label={formatMessage({
                  id: 'formOfPayment.bankName',
                })}
                helperText={
                  formErrors.bank?.name &&
                  errorsMessages[formErrors.bank?.name?.type]
                }
                required
                value={value}
                hasErrors={formErrors.bank?.name}
                id="bankName"
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            ...INPUT_FLOATING_HELPER_TEXT,
          }}
        >
          <Controller
            control={control}
            name="bank.owner"
            rules={{
              required: true,
              maxLength: 60,
              pattern: REGEX.ONLY_LETTERS_ACCENTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 60,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.owner && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey['400'],
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: 'formOfPayment.placeholderAccountHolder',
                })}
                label={formatMessage({
                  id: 'formOfPayment.accountHolder',
                })}
                helperText={
                  formErrors.bank?.owner &&
                  errorsMessages[formErrors.bank?.owner?.type]
                }
                required
                value={value}
                hasErrors={formErrors.bank?.owner}
                id="bankOwner"
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: '36px' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            ...INPUT_FLOATING_HELPER_TEXT,
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.cbu"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ALPHANUMERIC,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.cbu && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey['400'],
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: 'formOfPayment.placeholderCbu',
                })}
                label={formatMessage({
                  id: 'formOfPayment.cbu',
                })}
                helperText={
                  formErrors.bank?.cbu &&
                  errorsMessages[formErrors.bank?.cbu?.type]
                }
                required
                id="bankCbu"
                value={value}
                hasErrors={formErrors.bank?.cbu}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            ...INPUT_FLOATING_HELPER_TEXT,
          }}
        >
          <Controller
            control={control}
            name="bank.accountNumber"
            rules={{
              required: true,
              maxLength: 20,
              pattern: REGEX.ONLY_NUMBERS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.accountNumber && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey['400'],
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: 'formOfPayment.placeholderAccountNumber',
                })}
                label={formatMessage({
                  id: 'formOfPayment.accountNumber',
                })}
                helperText={
                  formErrors.bank?.accountNumber &&
                  errorsMessages[formErrors.bank?.accountNumber?.type]
                }
                required
                id="bankAccountNumber"
                value={value}
                hasErrors={formErrors.bank?.accountNumber}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={3}
        style={{ marginBottom: '84px' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          md={6}
          sx={{
            ...INPUT_FLOATING_HELPER_TEXT,
            marginBottom: {
              xs: 1.5,
              md: 0,
            },
          }}
        >
          <Controller
            control={control}
            name="bank.cuitCuil"
            rules={{
              required: true,
              maxLength: 30,
              pattern: REGEX.ALPHANUMERIC,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputRegular
                inputProps={{
                  maxLength: 30,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value && !formErrors.bank?.cuitCuil && (
                        <CheckIcon
                          style={{
                            color: theme.palette.grey['400'],
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={formatMessage({
                  id: 'formOfPayment.placeholderCuitCuil',
                })}
                label={formatMessage({
                  id: 'formOfPayment.cuitCuil',
                })}
                helperText={
                  formErrors.bank?.cuitCuil &&
                  errorsMessages[formErrors.bank?.cuitCuil?.type]
                }
                required
                id="bankCuitCuil"
                value={value}
                hasErrors={formErrors.bank?.cuitCuil}
                handleChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

FormOfPaymentAR.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}

export default FormOfPaymentAR
