import React from 'react'
import { Typography } from '@copa/design-system-factory.typography'
import { useIntl } from 'react-intl'
import styles from './styles'
import Heading from '../Heading'
import { useAppSelectGeneralInfo } from '../../hooks/store-hooks'

export default function PNRHeading() {
  const { formatMessage } = useIntl()
  const generalContext = useAppSelectGeneralInfo()
  const { requestCode, isNonRevenue } = generalContext
  const headingTitle = isNonRevenue
    ? 'additionalInfoPage.headingNonRevenue'
    : 'additionalInfoPage.heading'

  return (
    <Heading
      titleComponent={
        <Typography sx={styles.headingTitle}>
          {formatMessage({ id: headingTitle })}
        </Typography>
      }
      textComponent={
        <Typography id="Heading_Pnr" sx={styles.headingText}>
          {(requestCode || '').toUpperCase()}
        </Typography>
      }
    />
  )
}
