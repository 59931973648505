import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@copa/design-system-factory.typography'

import useStyles from './styles'
import { formatDate } from '../../../../utils/date'

function PassengerCard({ givenName, lastName, eTicket, documents }) {
  const { classes: styles } = useStyles()
  const birthday = documents?.birthday
  const firstname = documents?.firstName || givenName

  const isFirsNameTooLong = firstname?.length > 20
  const isLastNameTooLong = lastName?.length > 20
  const isFullNameTooLong =
    isFirsNameTooLong || isLastNameTooLong || (firstname + lastName).length > 20

  return (
    <section id={`Passenger_Card_${eTicket}`} className={styles.passengerCard}>
      {isFullNameTooLong ? (
        <>
          <Typography variant="h4" sx={{ color: 'primary.main' }}>
            {firstname}
          </Typography>

          <Typography variant="h4" sx={{ color: 'primary.main' }}>
            {lastName}
          </Typography>
        </>
      ) : (
        <Typography variant="h4" sx={{ color: 'primary.main' }}>
          {firstname} {lastName}
        </Typography>
      )}

      <Typography sx={{ fontSize: '14px', color: '#666666' }}>
        {birthday ? formatDate(birthday) : ''}
      </Typography>

      <Typography variant="h4" sx={{ fontSize: '14px' }}>
        {eTicket ? `# ${eTicket}` : ''}
      </Typography>
    </section>
  )
}

PassengerCard.propTypes = {
  givenName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  eTicket: PropTypes.string.isRequired,
  documents: PropTypes.shape({
    birthday: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
}

export default PassengerCard
